import React, { Component, useEffect, useState } from 'react';
import { Layout, Row, Col } from 'antd';
import UsuariosApi from '../../api/usuariosApi';
import DashboardTotales from './components/DashboardTotales';
import ChoferesTable from './components/ChoferesTable';
import CamionesTable from './components/CamionesTable';
import RemolquesTable from './components/RemolquesTable';
import ViajesRealizados from './components/ViajesRealizados';
import ViajesEnCursoMap from './components/ViajesEnCursoMap';
import ApprovePhotoModal from './components/ApprovePhotoModal';
import AppNotification from '../../components/AppNotification';
import './DashboardUvertracUserPage.scss';

const { Content } = Layout;

const DashboardUvertracUserPage = () => {
  const [dashboardInfo, setDashboardInfo] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [approveEntidad, setApproveEntidad] = useState('');
  const [approveTipo, setApproveTipo] = useState('');
  const [approveId, setApproveId] = useState(-1);
  const [headerInfo, setHeaderInfo] = useState({});

  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = () => {
    setWorking(true);
    UsuariosApi.getDashboard()
      .then(response => {
        setWorking(false);
        setDashboardInfo(response);
      })
      .catch(error => setWorking(false));
  }

  const showPhotoModal = (imgUrl, entidad, tipo, id, headerInfo) => {
    setImgUrl(imgUrl);
    setApproveEntidad(entidad);
    setApproveTipo(tipo);
    setApproveId(id);
    setHeaderInfo(headerInfo);
    setShowModal(true);
  }

  const onApproveImg = () => {
    setShowModal(false);
    setImgUrl('');
    setWorking(true);
    UsuariosApi.aprobarImagen(approveEntidad, approveTipo, approveId)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess("Documentación aprobada correctamente");
        loadDashboard();
      })
      .catch(error => {
        setWorking(false); 
      });
  }

  return (
    <Content style={ { margin: '24px 16px' } } className="dashboard-uvertrac-users">
      <Row gutter={ [24,24] }>
        <DashboardTotales
          dashboardInfo={ dashboardInfo }
        />
        <Col span={ 24 }>
          <ChoferesTable
            dashboardInfo={ dashboardInfo }
            showPhotoModal={ showPhotoModal }
            working={ working }
          />
        </Col>
        <Col span={ 24 }>
          <CamionesTable
            dashboardInfo={ dashboardInfo }
            showPhotoModal={ showPhotoModal }
            working={ working }
          />
        </Col>
        <Col span={ 24 }>
          <RemolquesTable
            dashboardInfo={ dashboardInfo }
            showPhotoModal={ showPhotoModal }
            working={ working }
          />
        </Col>
        {/*<Col span={ 24 }>
          <ViajesRealizados
            dashboardInfo={ dashboardInfo }
          />
        </Col>*/}
        <Col span={ 24 }>
          <ViajesEnCursoMap
            dashboardInfo={ dashboardInfo }
            loadDashboard={ loadDashboard }
            working={ working }
          />
        </Col>
      </Row>

      <ApprovePhotoModal
        visible={ showModal }
        onOk={ onApproveImg }
        onCancel={ () => {
          setShowModal(false);
          setImgUrl('');
        } }

        headerInfo={ headerInfo }
        approveEntidad={ approveEntidad }
        approveTipo={ approveTipo }
        imgUrl={ imgUrl }
      />
    </Content>
  );
}

export default DashboardUvertracUserPage;