import { 
  GlobalOutlined, CompassOutlined, PushpinOutlined, 
  TrademarkOutlined, EnvironmentOutlined, DatabaseOutlined, 
  ToolOutlined, CalculatorOutlined, GiftOutlined
 } from '@ant-design/icons';

export const data = [
  {
    name: "Provincias",
    key: "provincias",
    url: "provincias",
    icon: <GlobalOutlined />
  },
  {
    name: "Departamentos",
    key: "departamentos_de_provincias",
    url: "departamentos_de_provincias",
    icon: <CompassOutlined />
  },
  {
    name: "Localidades",
    key: "localidades_de_departamento",
    url: "localidades_de_departamento",
    icon: <PushpinOutlined />
  },
  {
    name: "Marcas",
    key: "marcas_de_vehiculo",
    url: "marcas_de_vehiculo",
    icon: <TrademarkOutlined />
  },
  {
    name: "Regiones",
    key: "regiones",
    url: "regiones",
    icon: <EnvironmentOutlined />
  },
  {
    name: "Tipos de Acoplado",
    key: "tipos_de_acoplado",
    url: "tipos_de_acoplado",
    icon: <DatabaseOutlined />
  },
  {
    name: "Tipos de Chasis",
    key: "tipos_de_chasis",
    url: "tipos_de_chasis",
    icon: <ToolOutlined />
  },
  {
    name: "Tipos de IVA",
    key: "tipos_de_iva",
    url: "tipos_de_iva",
    icon: <CalculatorOutlined />
  },
  {
    name: "Tipos de Producto",
    key: "tipos_de_producto",
    url: "tipos_de_producto",
    icon: <GiftOutlined />
  }
];