import Fetcher from "../helpers/Fetcher";

const CamionesApi = {
  get: id => {
    let url = 'uvertraccore/camiones/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'uvertraccore/camiones/';
    if(!data.id){
      return Fetcher
        .post(url, data)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
      .put(url + data.id, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('uvertraccore/camiones/' + id)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  updateCedulaVerde: (camionId, data) => {
    return Fetcher
      .postMultipart('uvertraccore/camiones/actualizar-imagen/cedula_verde/' + camionId, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  updateRuta: (camionId, data) => {
    return Fetcher
      .postMultipart('uvertraccore/camiones/actualizar-imagen/ruta/' + camionId, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  updateSeguro: (camionId, data) => {
    return Fetcher
      .postMultipart('uvertraccore/camiones/actualizar-imagen/seguro/' + camionId, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  updateVTV: (camionId, data) => {
    return Fetcher
      .postMultipart('uvertraccore/camiones/actualizar-imagen/vtv/' + camionId, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
}

export default CamionesApi;