import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Descriptions, Modal, Row } from 'antd';
import moment from 'moment';

const DescItem = Descriptions.Item;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    boxSizing: 'border-box'
  },
  label: {
    flex: '1',
    textAlign: 'right',
    fontWeight: 'bold',
    padding: '10px'
  },
  value: {
    flex: '1',
    padding: '10px'
  },
  img: {
    width: '80px', 
    height: '50px'
  }
};

const ConfirmacionStep = props => {
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imageModalTitle, setImageModalTitle] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      props.onValidate(true, {});
    }
  }, [props.current, props.status]);

  const renderCard = data => {
    let cedula_url;
    let seguro_url;
    let vtv_url;
    if(data.cedula_verde){ cedula_url = URL.createObjectURL(data.cedula_verde); }
    if(data.seguro){ seguro_url = URL.createObjectURL(data.seguro); }
    if(data.vtv){ vtv_url = URL.createObjectURL(data.vtv); }

    return <Descriptions bordered column={ 1 } size="small">
      <DescItem label="Tipo de Acoplado">{ data?.tipo_acoplado }</DescItem>
      <DescItem label="Patente">{ data?.patente }</DescItem>
      <DescItem label="Fecha de vencimiento de seguro">{ data?.fecha_de_vencimiento_seguro.format('DD-MM-YYYY') }</DescItem>
      <DescItem label="Fecha de vencimiento de VTV">{ data?.fecha_de_vencimiento_vtv.format('DD-MM-YYYY') }</DescItem>
      { cedula_url
        ? <DescItem label="Cédula Verde">
            <img alt="Cédula Verde" style={ styles.img } src={ cedula_url } 
              onClick={ () => { setSelectedImage(cedula_url); setImageModalTitle('Cédula Verde'); setShowImageModal(true); } } />
        </DescItem>
        : '' }
      { seguro_url
        ? <DescItem label="Seguro">
            <img alt="Seguro" style={ styles.img } src={ seguro_url } 
              onClick={ () => { setSelectedImage(seguro_url); setImageModalTitle('Seguro'); setShowImageModal(true); } } />
        </DescItem>
        : '' }
      { vtv_url
        ? <DescItem label="VTV">
            <img alt="VTV" style={ styles.img } src={ vtv_url } 
              onClick={ () => { setSelectedImage(vtv_url); setImageModalTitle('VTV'); setShowImageModal(true); } } />
        </DescItem>
        : '' }
    </Descriptions>
  }

  return (
    <Fragment>
      <h3 style={ { marginBottom: 16 } }>Datos del nuevo chofer:</h3>
      <div style={ styles.container }>
        <Row gutter={[16,16]}>
          <Col sm={24} xs={24}>
            { props.data ? renderCard(props.data) : '' }
          </Col>
        </Row>
      </div>

      <Modal 
        title={ imageModalTitle }
        visible={ showImageModal } 
        footer={ null } 
        onCancel={ () => setShowImageModal(false) }
      >
        <img alt={ imageModalTitle } style={ { width: '100%' } } src={ selectedImage } />
      </Modal>
    </Fragment>
  );
}

export default ConfirmacionStep;