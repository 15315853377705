import React, { useEffect, useState } from 'react';
import { Tree, Card } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const TreeNode = Tree.TreeNode;

const PermisosTree = props => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    if (Object.keys(props.permisos).length > 0 && checkedKeys.length == 0) {
      getSelectedKeys();
    }
  }, []);

  useEffect(() => {
    setInitialSelectedKeys(props.permisos);
  }, [props.permisos]);

  const onExpand = expandedKeys => {
    // if not set autoExpandParent to false, 
    // if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  }

  const onCheck = checkedKeys => {
    updatePermissionsList(checkedKeys, false);
    setCheckedKeys(checkedKeys);
  }
 
  const updatePermissionsList = (selectedKeys, deleteSelected = false) =>{
    let permisosArr = { ...props.permisos };
    // putting all permissions in false
    for (let permissionCategory in permisosArr) {
      let category = permisosArr[permissionCategory];
      for (let permissionName in category) {
        permisosArr[permissionCategory][permissionName].value = false;
      }
    }

    // putting only selected permissions in true
    for(let permissionKey of selectedKeys){
      if (permissionKey.includes(".")){
        let permissionSplitted = permissionKey.split(".");
        if (deleteSelected){
          delete permisosArr[permissionSplitted[0]][permissionSplitted[1]];
        }else{
          permisosArr[permissionSplitted[0]][permissionSplitted[1]].value = true;
        }
      }
    }

    // if deleteSelected is true also we must remove categories that doesnt have any permission.
    if (deleteSelected){
      for (let permissionKey of selectedKeys) {
        if (!permissionKey.includes(".") && Object.keys(permisosArr[permissionKey]).length === 0) {
          delete permisosArr[permissionKey];
        }
      }
    }

    props.onPermissionsChange(permisosArr);
  }

  const getSelectedKeys = () => {
    let permissionsSelected = [];
    for( let permissionCategory in props.permisos){
      let category= props.permisos[permissionCategory];
      for (let permissionName in category){
        let permission = props.permisos[permissionCategory][permissionName];
        if(permission.value){
          permissionsSelected.push(`${permissionCategory}.${permissionName}`);
        }
      }
    }
    setCheckedKeys(permissionsSelected);
  }

  const setInitialSelectedKeys = (initialPermissions = {}) => {
    let permissionsSelected = [];
    for (let permissionCategory in initialPermissions) {
      let category = initialPermissions[permissionCategory];
      for (let permissionName in category) {
        let permission = initialPermissions[permissionCategory][permissionName];
        if (permission.value) {
          permissionsSelected.push(`${permissionCategory}.${permissionName}`);
        }
      }
    }
    setCheckedKeys(permissionsSelected);
  }

  /*const getCheckedPermissionsAmount = () => {
    let permissionsChecked = 0;
    for (let permissionKey of checkedKeys) {
      if (permissionKey.includes(".")) {
        permissionsChecked += 1;
      }
    }
    return permissionsChecked;
  }*/

  const generateTreeData = () => {
    let treeData = [];
    for( let permissionCategory in props.permisos){
      let treeNode = {};
      let treeNodeChildren = [];
      treeNode.title = permissionCategory;
      treeNode.key = permissionCategory;
      let category= props.permisos[permissionCategory];
      for (let permissionName in category){
        let treeNodeChild = {};
        treeNodeChild.title = (
          <div>{ permissionName.replace(/_/g, " ") }
            { props.showShowOption ? <EyeOutlined style={ { paddingLeft: 15 } }/> : '' }
          </div>
        );
        treeNodeChild.key = `${permissionCategory}.${permissionName}`;
        treeNodeChildren.push(treeNodeChild);
      }
      treeNode.children = treeNodeChildren;
      treeData.push(treeNode);
    }
    return treeData;
  }

  const renderTreeNodes = data => 
    data.map( item => {
      if (item.children) {
        return (
          <TreeNode
            title={ item.title }
            key={ item.key }
            dataRef={ item }
          >
            { renderTreeNodes(item.children) }
          </TreeNode>
        );
      }else{
        return <TreeNode { ...item } />;
      }
    });

  return (
    <Tree
      showLine
      checkable
      onExpand={ onExpand }
      onCheck={ onCheck }
      expandedKeys={ expandedKeys }
      autoExpandParent={ autoExpandParent }
      checkedKeys={ checkedKeys }
      selectedKeys={ selectedKeys }
    >
      { renderTreeNodes(generateTreeData()) }
    </Tree>
  );
}

export default PermisosTree;
