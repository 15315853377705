import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber } from 'antd';
import basicStyle from '../../../../../themes/basicStyle';

const FormItem = Form.Item;
const { TextArea } = Input;

const TarifasStep = props => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [props.initialValues]);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      form.validateFields()
        .then(values => props.onValidate(true, values))
        .catch(error => props.onValidate(false, {}));
    }
  }, [props.current, props.status]);
  
  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      initialValues={ { ...props.initialValues } }
      scrollToFirstError
      autoComplete="off"
    >
      <FormItem name="tarifa_viaje_completo" label="Tarifa por viaje completo">
        <InputNumber style={ { width: '100%' } } 
          formatter={ value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
          parser={ value => value.replace(/\$\s?|(,*)/g, '') }
        />
      </FormItem>
      <FormItem name="tarifa_por_tonelada" label="Tarifa por tonelada">
        <InputNumber style={ { width: '100%' } } 
          formatter={ value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
          parser={ value => value.replace(/\$\s?|(,*)/g, '') }
        />
      </FormItem>
      <FormItem name="condicion_de_pago" label="Condición de pago">
        <TextArea rows={4} />
      </FormItem>
    </Form>
  );
}

export default TarifasStep;
