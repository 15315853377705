import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Button, Form, Spin, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import UsuariosApi from '../../../api/usuariosApi';
import AppNotification from '../../../components/AppNotification';
import UIHelper from '../../../helpers/UIHelper';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import basicStyle from '../../../themes/basicStyle';
import TransportistasApi from '../../../api/transportistasApi';
import UvertracApi from '../../../api/uvertracApi';

const { Content } = Layout;
const FormItem = Form.Item;

const UsuarioFromTransportistaForm = props => {
  const history = useHistory();
  const [transportista, setTransportista] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    loadTransportista();
  }, []);

  const loadTransportista = () => {
    setWorking(true);
    TransportistasApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setTransportista(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    let data = { 
      ...values,
      role: 'TRANSPORTISTA',
      parent_entity: 'TRANSPORTISTA',
      parent_entity_id: transportista.id
    }

    setWorking(true);
    UsuariosApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Usuario guardado correctamente');
        history.goBack();
      })
      .catch(error => { setWorking(false); });
  };

  const validate = (rule, value) => {
    return UvertracApi.exists({ module: 'Authentication', model: 'Usuario', column: 'email', value: value})
      .then(response => {
        if(response.exist == true){
          return Promise.reject('Ya existe otro usuario con el mismo email');
        }
        return Promise.resolve();
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  return (
    <Content>
      <PageTitle title={ 'Nuevo Usuario para el Transportista: ' + transportista?.nombre + ' ' + transportista?.apellido }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            scrollToFirstError
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                <FormItem name="email" label="Email" hasFeedback 
                  rules={ [ ...UIHelper.defaultRules, 
                    {type:'email', message: 'Debe ingresar un email valido'},
                    { validator: validate } 
                  ] }>
                  <Input autoComplete="new-password"/>
                </FormItem>
                <FormItem name="password" label="Password">
                  <Input type="password" autoComplete="new-password"/>
                </FormItem>
              </Col>
              
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
              </FormItem>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Content>
  );
}

export default UsuarioFromTransportistaForm;
