import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Tag, Tooltip, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageTitle from '../../../../components/PageTitle';
import Parser from '../../../../helpers/Parser';
import ViajesApi from '../../../../api/viajesApi';
import AgregarViajeOferta from './components/AgregarViajeOferta';
import UnidadesTransporteApi from '../../../../api/unidadesTransporteApi';
import OfertasApi from '../../../../api/ofertasApi';
import OfertaEstadoTag from '../../../../components/Tags/OfertaEstadoTag';
import AuthHelper from '../../../../helpers/AuthHelper';

const OfertaRealizadaList = props => {
  const [ofertas, setOfertas] = useState([]);
  const [selectedOferta, setSelectedOferta] = useState(undefined);
  const [showAgregarViajeOferta, setShowAgregarViajeOferta] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadOfertas();
  }, []);

  const columns = [
    { title: 'Codigo Uvertrac', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Condición de pago', dataIndex: 'condicion_de_pago', key: 'condicion_de_pago' },
    { title: 'Tarifa', dataIndex: 'tarifa_viaje_completo', key: 'tarifa_viaje_completo',
      render: text => <span>{ Parser.currency(text) }</span>
    },
    { title: 'Cantidad camiones solicitados', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center',
      render: text => <Tag color="green">{ text == '1' ? text + ' camión' : text + ' camiones' }</Tag>
    },
    { title: 'Cantidad viajes en curso', key: 'viajes_en_curso', align: 'center',
      render: (text, record) => <Tag color="green">{ record.viajes_en_curso?.length == 1 ? record.viajes_en_curso?.length + ' viaje' : record.viajes_en_curso?.length + ' viajes' }</Tag>
    },
    { title: 'Cantidad viajes finalizados', key: 'viajes_en_curso', align: 'center',
      render: (text, record) => <Tag color="cyan">{ record.viajes_finalizados?.length == 1 ? record.viajes_finalizados?.length + ' viaje' : record.viajes_finalizados?.length + ' viajes' }</Tag>
    },
    { title: 'Estado de la Oferta', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <OfertaEstadoTag estado={ text }/>
    },
    { title: '', render: (_, record) => record.estado === 'ACEPTADA'
      ? <Fragment key={ record.id }>
        <Tooltip title={ record.cantidad_camiones > (record.viajes_en_curso ? record.viajes_en_curso.length : 0) ? '' : 'La cantidad de camiones ya fue cubierta' }>
          <Button icon={ <PlusOutlined /> } size={ 'small' } type="primary" 
            disabled={ record.cantidad_camiones <= ((record.viajes_en_curso ? record.viajes_en_curso.length : 0) + (record.viajes_finalizados ? record.viajes_finalizados.length : 0)) }
            onClick={ () => { setSelectedOferta(record.id); setShowAgregarViajeOferta(true); } } 
          >
            { 'Agregar Viaje' }
          </Button>
        </Tooltip>
        {' '}
      </Fragment>
      : '' },
  ];

  const loadOfertas = () => {
    setWorking(true);
    ViajesApi.getViajesPublicadosTransportista()
      .then(response => {
        setWorking(false);
        let ofertasArr = []
        let ofertasArrAceptada = []
        let user = AuthHelper.getDecodedToken();

        response.map(viaje => ofertasArr = ofertasArr.concat(viaje.oferta));
        ofertasArrAceptada = ofertasArr.filter(oferta => oferta.estado.includes('ACEPTADA') && oferta.transportista_id == user.parent_entity_id);
        setOfertas(ofertasArrAceptada);
      })
      .catch(error => setWorking(false));

      /*setOfertas(props.dashboardInfo?.ofertas?.map(oferta => {
      return {
        id: oferta.id,
        codigo_uvertrac: oferta.codigo_uvertrac,
        condicion_de_pago: oferta.condicion_de_pago,
        cantidad_camiones: oferta.cantidad_camiones,
        estado: oferta.estado,
        tarifa_viaje_completo: oferta.tarifa_viaje_completo,
        viajes_en_curso: oferta.viajes_en_curso
      };
    }));*/
  }

  return (
    <Fragment>
      <PageTitle title="Ofertas Realizadas"/>
      <Card
        /*title={<Col span={12}>
          <Search 
            module="uvertraccore"
            model="publicacionviaje"
            columnsSearch={ ["codigo_uvertrac", "titulo", "estado"] }
            columnsReturn={ ["*"] }
            orderBy="codigo_uvertrac"
            relationships={ "oferta,oferta.viajes_en_curso" }
            onChange={ response => setViajes(response) }
            onClear={ loadViajes }
          />
        </Col>}*/
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ ofertas }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />

        <AgregarViajeOferta
          visible={ showAgregarViajeOferta }
          onClose={ () => setShowAgregarViajeOferta(false) }
          ofertaId={ selectedOferta }
          getUnidadesTransporteNoAsignadas={ () => UnidadesTransporteApi.getNoAsignadas() }
          getOferta={ id => OfertasApi.get(id) }
          asignarUnidadesTransporteOferta={ (id, utIds) => OfertasApi.asignarUnidadesTransporte(id, utIds) }
          loadOfertas={ loadOfertas }
        />
      </Card>
    </Fragment>
  );
}

export default OfertaRealizadaList;
