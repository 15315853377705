import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber } from 'antd';
import basicStyle from '../../../../../themes/basicStyle';
import UIHelper from '../../../../../helpers/UIHelper';
import MapFormItem from '../../../../../components/FormItems/MapFormItem/MapFormItem';

const FormItem = Form.Item;

const LocalizacionStep = props => {
  const [markerOrigen, setMarkerOrigen] = useState(undefined);
  const [markerDestino, setMarkerDestino] = useState(undefined);
  const [kmsAdicOrigen, setKmsAdicOrigen] = useState(0);
  const [kmsAdicDestino, setKmsAdicDestino] = useState(0);
  //const [kmtsFinales, setKmtsFinales] = useState(0);
  //const [kmtsGoogle, setKmtsGoogle] = useState(0.0);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [props.initialValues]);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      form.validateFields()
        .then(values => {
          const data = {
            origen_latitud: String(markerOrigen.position.lat()),
            origen_longitud: String(markerOrigen.position.lng()),
            destino_latitud: String(markerDestino.position.lat()),
            destino_longitud: String(markerDestino.position.lng()),
            //kmts_google: kmtsGoogle,
            //kmts_finales: kmtsFinales,

            origen_direccion: values.origen_direccion,
            destino_direccion: values.destino_direccion,
            kmts_adicionales_origen: values.kmts_adicionales_origen,
            kmts_adicionales_destino: values.kmts_adicionales_destino,
            origen_direccion_google: values.origen_direccion_google,
            destino_direccion_google: values.destino_direccion_google,
            kmts_finales: values.kmts_finales,
            kmts_google: values.kmts_google,

            origen_localidad: getLocality(markerOrigen.address_components),
            destino_localidad: getLocality(markerDestino.address_components)
          };
          /*if (this.state.fileList[0]) {
            data['mapa'] = this.state.fileList[0];
          }*/

          props.onValidate(true, data);
        })
        .catch(error => props.onValidate(false, {}));
    }
  }, [props.current, props.status]);

  const calcDistanceInKm = (p1, p2) => {
    return parseFloat((window.google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2));
  }

  const calcKmtsFinales = (origen, destino, adicOrigen, adicDestino) => {
    let kms_finales = adicOrigen + adicDestino;

    if (!origen || !destino) {
      return;
    }

    let distance = calcDistanceInKm(origen.position, destino.position);

    kms_finales += distance;
    //setKmtsGoogle(distance);
    //setKmtsFinales(kms_finales);
    form.setFieldsValue({
      kmts_google: distance,
      kmts_finales: kms_finales
    })
  }

  const getLocality = address_components => {
    const componentForm = {
      locality: 'long_name',
    };

    let locality = '';
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        locality = address_components[i][componentForm[addressType]];
      }
    }
    return locality;
  }

  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      initialValues={ { ...props.initialValues,
        kmts_adicionales_origen: props.initialValues ? props.initialValues.kmts_adicionales_origen : 0,
        kmts_adicionales_destino: props.initialValues ? props.initialValues.kmts_adicionales_destino : 0
      } }
      scrollToFirstError
      autoComplete="off"
    >
      <FormItem name="origen_direccion" label="Dirección Origen" hasFeedback rules={ UIHelper.defaultRules }>
        <Input />
      </FormItem>

      <MapFormItem name="origen_direccion_google" label="Ubicar en el mapa" hasFeedback rules={ UIHelper.defaultRules }
        form={ form } mapStyle={ { height: 300 } } initialValue={ props.initialValues?.origen_direccion_google }
        onMarkerChanged={ marker => { setMarkerOrigen(marker); calcKmtsFinales(marker, markerDestino, kmsAdicOrigen, kmsAdicDestino); } }
      />

      <FormItem name="kmts_adicionales_origen" label="KM Adicionales origen" hasFeedback rules={ UIHelper.defaultRules }>
        <InputNumber style={ { width: '100%' } } min={0}
          onChange={ value => { setKmsAdicOrigen(value); calcKmtsFinales(markerOrigen, markerDestino, value, kmsAdicDestino) } }/>
      </FormItem>

      <FormItem name="destino_direccion" label="Dirección Destino" hasFeedback rules={ UIHelper.defaultRules }>
        <Input />
      </FormItem>

      <MapFormItem name="destino_direccion_google" label="Ubicar en el mapa" hasFeedback rules={ UIHelper.defaultRules }
        form={ form } mapStyle={ { height: 300 } } initialValue={ props.initialValues?.destino_direccion_google }
        onMarkerChanged={ marker => { setMarkerDestino(marker); calcKmtsFinales(markerOrigen, marker, kmsAdicOrigen, kmsAdicDestino); } }
      />

      <FormItem name="kmts_adicionales_destino" label="KM Adicionales destino" hasFeedback rules={ UIHelper.defaultRules }>
        <InputNumber style={ { width: '100%' } } min={0}
          onChange={ value => { setKmsAdicDestino(value); calcKmtsFinales(markerOrigen, markerDestino, kmsAdicOrigen, value) } }/>
      </FormItem>

      <FormItem name="kmts_finales" label="Distancia Total">
        <Input disabled/>
      </FormItem>

      <FormItem name="kmts_google" label="Distancia Total Google">
        <Input disabled/>
      </FormItem>
    </Form>
  );
}

export default LocalizacionStep;
