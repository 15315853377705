import React, { Fragment, useEffect, useState } from 'react';

import { Layout, Row, Col, Card, Button, Form, Spin, Input, Select, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import UsuariosApi from '../../../api/usuariosApi';
import AppNotification from '../../../components/AppNotification';
import UIHelper from '../../../helpers/UIHelper';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import basicStyle from '../../../themes/basicStyle';
import UvertracApi from '../../../api/uvertracApi';

const { Content } = Layout;
const FormItem = Form.Item;
const { Option } = Select;

const UsuarioForm = props => {
  const history = useHistory();
  const [usuario, setUsuario] = useState(undefined);
  const [roles] = useState([
    {label:'Dador de Carga',value:'DADOR_CARGA' },
    {label:'Transportista',value:'TRANSPORTISTA' },
    {label:'Usuario Administrativo',value:'UVERTRAC_USER' }
  ])
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    if(history.location.pathname.indexOf('edit') > -1){
      loadUsuario();
    }
  }, []);

  const loadUsuario = () => {
    setWorking(true);
    UsuariosApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setUsuario(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const showMessageDador = () => {
    Modal.info({
      title: 'Creacion de usuario para dador de cargas',
      content: (
        <div>
          <p>Estas tratando de crear un usuario para un dador de cargas</p>
          <p>Selecciona la opcion Crear Usuario del listado de dadores de carga.</p>
          <p>Si deseas ir al listado click
            <a onClick={ () => {
              Modal.destroyAll();
              history.push('/dadores-de-carga');
            } }> aqui </a>
          </p>
        </div>
      ),
      onOk() { },
    });
  }
   
  const showMessageTransportista = () =>{
    Modal.info({
      title: 'Creacion de usuario para transportistas',
      content: (
        <div>
          <p>Estas tratando de crear un usuario para un transportista</p>
          <p>Selecciona la opcion Crear Usuario del listado de tranportistas.</p>
          <p>Si deseas ir al listado click  
            <a onClick={ () => {
              Modal.destroyAll();
              history.push('/transportistas');
            } }> aqui </a> 
          </p>
        </div>
      ),
      onOk(){},
    });
  }

  const onFinish = values => {
    let data = { 
      id: usuario?.id,
      ...values,
    }
    if(usuario?.id){ delete data.password; }
    
    // eslint-disable-next-line default-case
    switch (values.role.toUpperCase()) {
      case 'DADOR_CARGA': return showMessageDador();
      case 'TRANSPORTISTA': return showMessageTransportista();
      case 'UVERTRAC_USER': 
        data.parent_entity = 'SISTEMA';
        break;
    }

    setWorking(true);
    UsuariosApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Usuario guardado correctamente');
        history.goBack();
      })
      .catch(error => { setWorking(false); });
  };

  const validate = (rule, value) => {
    return UvertracApi.exists({ module: 'Authentication', model: 'Usuario', column: 'email', value: value})
      .then(response => {
        if(response.exist == true){
          return Promise.reject('Ya existe otro usuario con el mismo email');
        }
        return Promise.resolve();
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  return (
    <Content>
      <PageTitle title={ `${usuario?.id ? 'Editar' : 'Nuevo'} Usuario` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            initialValues={ { ...usuario } }
            scrollToFirstError
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                <FormItem name="role" label="Rol" hasFeedback rules={ UIHelper.defaultRules } autoComplete="off">
                  <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
                    { roles.map(rol => (<Option key={ rol.value } value={ rol.value }>{ rol.label }</Option>)) }
                  </Select>
                </FormItem>
                <FormItem name="email" label="Email" hasFeedback 
                  rules={ [ ...UIHelper.defaultRules, 
                    {type:'email', message: 'Debe ingresar un email valido'},
                    usuario?.id ? {} : { validator: validate }
                  ] }>
                  <Input autoComplete="new-password" disabled={ usuario?.id }/>
                </FormItem>
                <FormItem name="password" label="Password">
                  <Input type="password" autoComplete="new-password" disabled={ usuario?.id }/>
                </FormItem>
              </Col>
              
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
              </FormItem>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Content>
  );
}

export default UsuarioForm;
