import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import TransportistaForm from './components/UsuarioForm';
import TransportistaList from './components/UsuarioList';
import UsuarioFromDadorForm from './components/UsuarioFromDadorForm';
import UsuarioFromTransportistaForm from './components/UsuarioFromTransportistaForm';

const { Content } = Layout;

const UsuariosPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ TransportistaList } />
        <Route path={ `${props.match.path}/new` } component={ TransportistaForm } />
        <Route path={ `${props.match.path}/edit/:id` } component={ TransportistaForm } />
        <Route path={ `${props.match.path}/new-from-dador/:id` } component={ UsuarioFromDadorForm } />
        <Route path={ `${props.match.path}/new-from-transportista/:id` } component={ UsuarioFromTransportistaForm } />
      </Switch>
    </Content>
  );
}

export default UsuariosPage;