import AuthHelper from "../helpers/AuthHelper";
import ConfigHelper from "../helpers/ConfigHelper";
import Fetcher from "../helpers/Fetcher";
import ParentEntityHelper from "../helpers/ParentEntityHelper";
import UserHelper from "../helpers/UserHelper";

const AuthApi = {
  login: (user, password) => {
    let data = {
      email: user,
      password: password
    }

    return Fetcher
      .post('authentication/authenticate/', data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  logout: () => {
    AuthHelper.removeJwtToken();
    UserHelper.removeUserPermissions();
    ConfigHelper.removeParameters();
    ParentEntityHelper.removeParentEntity();
    return Promise.resolve();
  },
  recoverPassword: user => {
    let data = {
      email: user
    }
    return Fetcher
      .post('authentication/user-forgotpassword/', data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getUserPermissions: userId => {
    return Fetcher
      .get('authentication/get-user-permissions/' + userId)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  updateUserPermissions: (userId, userPermissions) => {
    return Fetcher
      .post('authentication/update-user-permissions/' + userId, { permisos: userPermissions })
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getUserData: async token => {
    try {
      const response = await Fetcher.get('authentication/get-user-to-confirm/' + token); 
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    } 
  },
  confirmUser: async (token,password) => {
    try {
      const response = await Fetcher.post('authentication/user-confirmation/',{ confirmation_hash: token, password }); 
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    } 
  }
}

export default AuthApi; 