import React, { useEffect, useState } from "react";
import { Button, Card, Col, Space, Table, Tooltip } from "antd";
import { FileDoneOutlined } from '@ant-design/icons';
import ViajeEstadoTag from "../../../../../components/Tags/ViajeEstadoTag";
import Parser from "../../../../../helpers/Parser";
import ViajesList from "./ViajesList";

const ViajesPorPublicacion = props => {
  const [working, setWorking] = useState(false);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Titulo', dataIndex: 'titulo', key: 'titulo' },
    { title: 'Fecha Carga', dataIndex: 'fecha_hora_entrega', key: 'fecha_hora_entrega', align: 'center',
    render: text => <span>{ Parser.datetime(text) }</span>
    },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <ViajeEstadoTag estado={ text }/>
    },
  ];

  return (
    <Card
      /*title={<Col span={12}>
        <Search 
          module="uvertraccore"
          model="publicacionviaje"
          columnsSearch={ ["codigo_uvertrac", "titulo"] }
          columnsReturn={ ["*"] }
          orderBy="id"
          onchange={ response => props.setViajes(response) }
          onClear={ props.loadViajes }
        />
      </Col>}*/
    >
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ working }
        columns={ columns } 
        dataSource={ props.viajes }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        expandable={ {
          expandedRowRender: record => 
            <ViajesList 
              viajes={ record.oferta.map(of => of.viajes_finalizados).flat() }
              facturarViaje={ viajeId => props.facturarViaje(viajeId) }
              loadViajes={ props.loadViajes }
            />,
          //rowExpandable: record => record.oferta?.length > 0
        } }
      />
    </Card>
  );
}

export default ViajesPorPublicacion;