import React, { useState } from 'react';
import { Button ,Input,Space,Descriptions} from 'antd';
import './UserConfirmationComponent.scss';
import AppNotification from '../../../components/AppNotification';
import AuthApi from '../../../api/authApi';
import { useHistory } from 'react-router';


const UserConfirmationComponent = props => {
  const [passwordConfirUser, setPasswordConfirUser] = useState(null);
  const [paswordVerificationUser, setPasswordVerificationUser] = useState(null);
  const history = useHistory();

  const setPassConfirm=(pass)=>{
    setPasswordConfirUser(pass.target.value)
  }
  
  const passVerification =(passVerificacion)=>{
    setPasswordVerificationUser(passVerificacion.target.value)
  }

  const setPasswordUser = ()=>{
    if (passwordConfirUser != null ) {
      if (passwordConfirUser === paswordVerificationUser ) {
        AuthApi.confirmUser(props.token, passwordConfirUser)
        .then(()=>{
          AppNotification.showSuccess('Validacion Correcta'); 
          history.push('/login');
        })
        .catch(()=>{
          AppNotification.showError('Algo Salio muy mal :( '); 
        });
      }else{
        AppNotification.showError('Las contraseñas no coinciden');
      }
    }else{
      AppNotification.showError('Campo vacio de contrasena');
    } 
  } 

  const renderComponents = () => {
    return (
      <div>
        <div style={ { textAlign: "center" } }>
          <Space direction="vertical">
            <Input.Password placeholder="Introduzca Contraseña"  
            onChange={setPassConfirm} rules={[{ required: true, message: 'Este campo es requerido.' }]}/>
            <Input.Password
              placeholder="Verificacion Contraseña"
              onChange={passVerification}
              rules={[{ required: true, message: 'Este campo es requerido.' }]}
            />
          </Space>
          <div style={ { padding:'10px' } } >
            <Button type = "primary" onClick={ () => setPasswordUser() }>
                VALIDAR USUARIO
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const renderDataUser = (nombre,tipo,email,razon_social) =>{
    return  (
      <div>
        <Descriptions title="Informacion Usuario">
        <Descriptions.Item label="Nombre">{nombre}</Descriptions.Item>
        <Descriptions.Item label="Tipo">{tipo}</Descriptions.Item>
        <Descriptions.Item label="Email">{email}</Descriptions.Item>
        <Descriptions.Item label="Razon Social">{razon_social}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  return (
    <div className="validation">
      <div className="logoWrapper">
        { props.logo 
          ? <span><img alt="logo" src={ props.logo } className="logoImg" /></span> 
          : '' }
        <span>{ props.title ? props.title : 'Bienvenido!' }</span>
      </div>
       { renderDataUser(props.nombre , props.tipo, props.email, props.razon_social) }
      { renderComponents() }
    </div>
  );
}

export default UserConfirmationComponent;