export default class Consts{
  static TipoEventos = {
    EXTRACCION_DINERO: 'EXTRACCION_DINERO',
    PINCHO_RUEDA: 'PINCHO_RUEDA',
    PROBLEMA_TECNICO: 'PROBLEMA_TECNICO',
    PROBLEMA_PAPELES: 'PROBLEMA_PAPELES',
    ACCIDENTE: 'ACCIDENTE',
    OTRO: 'OTRO'
  }

  static EstadoOferta = {
    PUBLICADA: 'PUBLICADA',
    ACEPTADA: 'ACEPTADA',
    RECHAZADA: 'RECHAZADA'
  }

  static EstadoViaje = {
    PUBLICADA: 'PUBLICADA',
    ACEPTADA: 'ACEPTADA',
    RECHAZADA: 'RECHAZADA',
    INICIADO: 'INICIADO',
    VIAJANDO: 'VIAJANDO',
    FINALIZADO: 'FINALIZADO',
    TERMINADO: 'TERMINADO',
    FACTURADO: 'FACTURADO'
  }
}