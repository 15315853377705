import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Card, Tag, Col, Space, Tooltip, Button, Modal, Alert } from 'antd';
import { DislikeOutlined ,MinusCircleTwoTone} from '@ant-design/icons';
import CargaDadorDetail from './CargaDadorDetail';
import DadoresCargaApi from '../../../api/dadoresCargaApi';
import EditarButton from '../../../components/Buttons/EditarButton';
import VerButton from '../../../components/Buttons/VerButton';
import PageTitle from '../../../components/PageTitle';
import Search from 'antd/lib/transfer/search';
import ViajeEstadoTag from '../../../components/Tags/ViajeEstadoTag';
import CargaDadorList from './CargaDadorList';
import CargaDadorOfertaList from './CargaDadorOfertaList';
import SearchDinamic from '../../../components/Search/Search-Dinamic';
import SearchByDays from '../../../components/Search/Search-By-Days';

const CargaDadorViaje = props => {
  const history = useHistory();
  const [viajes, setViajes] = useState([]);
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);
  const [showViajeReject, setShowViajeReject] = useState(false);
  const [dadoresCarga, setDadoresCarga] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadViajes();
  }, []);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Origen >>>> Destino', dataIndex: 'titulo', key: 'titulo' },
    { title: 'Camiones Pedidos', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center' },
    { title: 'Condicion de Pago', dataIndex: 'condicion_de_pago', key: 'condicion_de_pago', align: 'center'},
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <ViajeEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedViaje(record); setShowViajeDetail(true); } }/>
        </Space>
      }
    }
  ];

  const loadViajes = () => {
    setWorking(true);
    DadoresCargaApi.getViajesPublicadosDador(props.match.params.id)
      .then(response => {
        setWorking(false);
        setDadoresCarga(response);
      })
      .catch(error => setWorking(false));
  }

  return (
    <Fragment>
      <PageTitle title="Viajes publicados"  onBack={ () => history.goBack() } />
      <Card
        title={<Col span={12}>
        </Col>}
        /*extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}*/
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ dadoresCarga }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          expandable={ { 
            expandedRowRender: record => 
              <CargaDadorOfertaList 
                ofertas={ record.oferta }
                viaje={ record } 
                loadViajes={ loadViajes }
              />,
            rowExpandable: record => record.oferta?.length > 0
          } }
        />

        <CargaDadorDetail
          viaje={ selectedViaje }
          visible={ showViajeDetail }
          onClose={ () => setShowViajeDetail(false) }
        />

      </Card>
    </Fragment>
  );
}

export default CargaDadorViaje;
