import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, Table, Button, Space, Col, Select, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import LocalidadesApi from '../../../../api/parametricas/localidadesApi';
import AppNotification from '../../../../components/AppNotification';
import EditarButton from '../../../../components/Buttons/EditarButton';
import EliminarButton from '../../../../components/Buttons/EliminarButton';
import PageTitle from '../../../../components/PageTitle';
import ProvinciasApi from '../../../../api/parametricas/provinciasApi';
import UIHelper from '../../../../helpers/UIHelper';
import DepartamentosApi from '../../../../api/parametricas/departamentosApi';
import SearchDinamic from '../../../../components/Search/Search-Dinamic';

const { Option } = Select;

const LocalidadList = props => {
  const history = useHistory();
  const [provincias, setProvincias] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState(undefined);
  const [selectedDepartamento, setSelectedDepartamento] = useState(undefined);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadProvincias();
    //loadLocalidades(props.match.params.provId);
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'nombre', width: '80%', },
    { title: 'Cod Postal', dataIndex: 'codigo_postal', width: '20%', },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push('/parametricas/localidades_de_departamento/edit/' + selectedProvincia + '/' + record.parametrica_departamento_id + '/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar la localidad?" onConfirm={ () => handleDelete(record.id) }/>
        </Space>
      }
    }
  ];

  const loadProvincias = () => ProvinciasApi.get().then(response => setProvincias(response));

  const loadDepartamentos = id => {
    if(id){
      setWorking(true);
      ProvinciasApi.getDepartamentos(id)
        .then(response => {
          setWorking(false);
          setDepartamentos(response.departamentos);
        })
        .catch(error => setWorking(false));
    }
  }

  const loadLocalidades = id => {
    if(id){
      setWorking(true);
      DepartamentosApi.getLocalidades(id)
        .then(response => {
          setWorking(false);
          setLocalidades(response.localidades);
        })
        .catch(error => setWorking(false));
    }
  }

  const handleDelete = id => {
    setWorking(true);
    LocalidadesApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Localidad eliminada correctamente');
        loadLocalidades(selectedDepartamento);
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Localidades"
        onBack={ () => history.goBack() }
      />
      <Card
        title={ <Col span={12}>
          <Row gutter={[16,16]}>
            <Col span={8}>
              <Select allowClear showSearch filterOption={ UIHelper.defaultSelectFilter } placeholder="Provincia" style={ { width: '100%' } }
                onChange={ value => { setSelectedProvincia(value); setSelectedDepartamento(undefined); loadDepartamentos(value); } }
                //defaultValue={ props.match.params.provId }
                value={ selectedProvincia }
              >
                { provincias.map(prov => (<Option key={ prov.id } value={ prov.id }>{ prov.nombre }</Option>)) }
              </Select>
            </Col>
            <Col span={8}>
              <Select allowClear showSearch filterOption={ UIHelper.defaultSelectFilter } placeholder="Departamento" style={ { width: '100%' } }
                onChange={ value => { setSelectedDepartamento(value); loadLocalidades(value); } }
                //defaultValue={ props.match.params.provId }
                value={ selectedDepartamento }
              >
                { departamentos.map(depto => (<Option key={ depto.id } value={ depto.id }>{ depto.nombre }</Option>)) }
              </Select>
            </Col>
            <Col span={8}>
              <SearchDinamic 
                module="uvertraccore"
                model="Localidad"
                columnsSearch={ ['nombre'] }
                columnsReturn={ columns }
                onChange={ response => setLocalidades(response) }
                onClear={ () => loadLocalidades(selectedDepartamento) }
              />
            </Col>
          </Row>
        </Col> }
        extra={[
          <Button type="primary" icon={<PlusOutlined />} disabled={ !selectedProvincia || !selectedDepartamento }
            onClick={ () => history.push('/parametricas/localidades_de_departamento/new/' + selectedProvincia + '/' + selectedDepartamento) }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ localidades }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>
    </Fragment>
  );
}

export default LocalidadList;