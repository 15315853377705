import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Form, Input, Select } from 'antd';
import basicStyle from '../../../../../themes/basicStyle';
import moment from 'moment';
import UIHelper from '../../../../../helpers/UIHelper';
import UvertracApi from '../../../../../api/uvertracApi';

const FormItem = Form.Item;
const { Option } = Select;

const DatosCamionStep = props => {
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [tiposChasi, setTiposChasi] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    loadMarcas();
    loadModelos();
    loadTiposChasi();
  }, []);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      form.validateFields()
        .then(values => {
          const data = {
            ...values,
            marca: marcas.find(x => x.id === values.marca_id).nombre,
            modelo: modelos.find(x => x.id === values.modelo_id).nombre,
            tipo_chasi: tiposChasi.find(x => x.id === values.tipo_chasi_id).nombre
          }
          props.onValidate(true, data);
        })
        .catch(error => props.onValidate(false, {}));
    }
  }, [props.current, props.status]);

  const loadMarcas = () => UvertracApi.get({ model: 'MarcaVehiculo', colReturn: ['id','nombre'], colOrderBy: 'nombre' }).then(response => setMarcas(response.data));
  const loadModelos = () => UvertracApi.get({ model: 'ModeloVehiculo', colReturn: ['id','nombre'], colOrderBy: 'nombre' }).then(response => setModelos(response.data));
  const loadTiposChasi = () => UvertracApi.get({ model: 'TipoChasi', colReturn: ['id','nombre'], colOrderBy: 'nombre' }).then(response => setTiposChasi(response.data));

  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      initialValues={ {
        rastreo_satelital: false
      } }
      scrollToFirstError
      autoComplete="off"
    >
      <FormItem name="marca_id" label="Marca" hasFeedback rules={ UIHelper.defaultRules }>
        <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
          { marcas.map(marca => (<Option key={ marca.id } value={ marca.id }>{ marca.nombre }</Option>)) }
        </Select>
      </FormItem>
      <FormItem name="modelo_id" label="Modelo" hasFeedback rules={ UIHelper.defaultRules }>
        <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
          { modelos.map(modelo => (<Option key={ modelo.id } value={ modelo.id }>{ modelo.nombre }</Option>)) }
        </Select>
      </FormItem>
      <FormItem name="tipo_chasi_id" label="Tipo de chasis" hasFeedback rules={ UIHelper.defaultRules }>
        <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
          { tiposChasi.map(tipo => (<Option key={ tipo.id } value={ tipo.id }>{ tipo.nombre }</Option>)) }
        </Select>
      </FormItem>
      <FormItem name="patente" label="Patente" rules={ UIHelper.defaultRules }
        normalize={ (value, prevValue) => value !== '' ? (/^[\w]+$/.test(value.toUpperCase()) ? value.toUpperCase() : prevValue) : value }
      >
        <Input maxLength={7}/>
      </FormItem>
      <FormItem name="rastreo_satelital" label="Rastreo Satelital" valuePropName="checked">
        <Checkbox />
      </FormItem>
      <FormItem name="fecha_de_vencimiento_ruta" label="Fecha de vencimiento de ruta" rules={ UIHelper.defaultRules }>
        <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
          disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
        />
      </FormItem>
      <FormItem name="fecha_de_vencimiento_seguro" label="Fecha de vencimiento del seguro" rules={ UIHelper.defaultRules }>
        <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
          disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
        />
      </FormItem>
      <FormItem name="fecha_de_vencimiento_vtv" label="Fecha de vencimiento de VTV" rules={ UIHelper.defaultRules }>
        <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
          disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
        />
      </FormItem>
    </Form>
  );
}

export default DatosCamionStep;