import React, { Fragment, useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import PageTitle from '../../../../components/PageTitle';
import DadoresCargaApi from '../../../../api/dadoresCargaApi';
import ViajesPorPublicacion from './components/ViajesPorPublicacion';
import ViajesPorTransportista from './components/ViajesPorTransportista';
import ViajesApi from '../../../../api/viajesApi';

const { TabPane } = Tabs;

const ViajeRealizadoList = props => {
  const [viajes, setViajes] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadViajes();
  }, []);

  const loadViajes = () => {
    setWorking(true);
    DadoresCargaApi.getViajesTerminados()
      .then(response => {
        setWorking(false);
        setViajes(response);
      })
      .catch(error => setWorking(false));
  }

  return (
    <Fragment>
      <PageTitle title="Viajes realizados"/>

      <Spin spinning={working}>
        <Tabs style={ { backgroundColor: '#fff', padding: 16 } } defaultActiveKey="1">
          <TabPane tab="Por Publicación" key="1">
            <ViajesPorPublicacion
              viajes={ viajes }
              loadViajes={ loadViajes }
              facturarViaje={ viajeId => ViajesApi.facturarViaje(viajeId) }
            />
          </TabPane>

          <TabPane tab="Por Transportista" key="2">
            <ViajesPorTransportista
              viajes={ viajes }
              loadViajes={ loadViajes }
              facturarViaje={ viajeId => ViajesApi.facturarViaje(viajeId) }
            />
          </TabPane>
        </Tabs>
      </Spin>
    </Fragment>
  );
}

export default ViajeRealizadoList;
