import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import ChoferForm from './components/form/ChoferForm';
import ChoferList from './components/ChoferList';

const { Content } = Layout;

const ChoferesPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ ChoferList } />
        <Route path={ `${props.match.path}/new` } component={ ChoferForm } />
        <Route path={ `${props.match.path}/edit/:id` } component={ ChoferForm } />
      </Switch>
    </Content>
  );
}

export default ChoferesPage;