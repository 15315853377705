import React, { useEffect } from 'react';
import { DatePicker, Form, Input } from 'antd';
import basicStyle from '../../../../../themes/basicStyle';
import CuitFormItem from '../../../../../components/FormItems/CuitFormItem';
import AppNotification from '../../../../../components/AppNotification';
import moment from 'moment';
import UIHelper from '../../../../../helpers/UIHelper';

const FormItem = Form.Item;

const DatosPersonalesStep = props => {
  const [form] = Form.useForm();

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      form.validateFields()
        .then(values => props.onValidate(true, values))
        .catch(error => props.onValidate(false, {}));
    }
  }, [props.current, props.status]);

  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      scrollToFirstError
    >
      <CuitFormItem
        //id={ chofer?.id }
        form={ form }
        onCuitExistente={ response => {
          if (response.choferExistente){
            AppNotification.showInfo(`Un Chofer posee el mismo CUIT. `);
          }
          return response.choferExistente;
        } }
      />
      <FormItem name="telefono_celular" label="Teléfono" rules={ UIHelper.defaultRules }>
        <Input maxLength={10} />
      </FormItem>
      <FormItem name="fecha_de_vencimiento_licencia" label="Fecha de vencimiento de licencia" rules={ UIHelper.defaultRules }>
        <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
          disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
        />
      </FormItem>
    </Form>
  );
}

export default DatosPersonalesStep;