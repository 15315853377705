import React from 'react';
import { Switch } from 'react-router-dom';
import { Layout, Tooltip } from 'antd';
import { SettingOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
//import screenfull from 'screenfull';
import AppRoutes from './AppRoutes';
import './appContent.scss';
import { siteConfig } from '../../../../settings';

const { Header, Footer } = Layout;

const AppContent = props => {
  /*const fullScreen = () => {
    if(screenfull.isFullscreen){
      screenfull.exit();
    }else{
      screenfull.request();
    }
  };*/
  
  return(
    <Layout style={ { height: '100vh' } }>
      { props.user
        ? <Header className="app-header">
          { props.collapsedMainSideBar 
            ? <MenuUnfoldOutlined className="trigger header__position" onClick={ props.toggleMainSideBar }/>
            : <MenuFoldOutlined className="trigger header__position" onClick={ props.toggleMainSideBar }/> 
          }
          <div className="header__badge-box">
            <p style={ { marginRight: 10 } }>{ props.user?.email }</p>
            <div className="header__badge" onClick={ props.toggleRightSider }>
              <Tooltip placement="bottom" title={ props.user?.email }>
                <SettingOutlined
                  className="trigger size header__position"
                />
              </Tooltip>
            </div>
          </div>
        </Header> 
        : '' }

      <div style={ { height: '100%', overflow: 'auto' } }>
        <Switch>
          { AppRoutes }
        </Switch>
      </div>

      { props.user
        ? <Footer className="app-footer">
          <small>{ siteConfig.footerText }</small>
        </Footer> 
        : '' }
    </Layout>
  );
};

export default AppContent;
