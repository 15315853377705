import { Fragment } from "react";
import { Input } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import PlacesAutocomplete from "react-places-autocomplete";

const AutocompleteItem = props => {
  return (
    <PlacesAutocomplete
      value={ props.value }
      onChange={ props.onChange }
      onSelect={ props.onSelect }
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Fragment>
          <Input { ...getInputProps() }/>
          <div className="autocomplete-dropdown-container ant-select-dropdown">
            { loading && <LoadingOutlined /> }
            { suggestions.map(suggestion => {
              const className = 'ant-select-item';
              const style = suggestion.active ? { backgroundColor: '#f5f5f5', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })} key={suggestion.placeId}>
                  <span>{suggestion.description}</span>
                </div>
              );
            }) }
          </div>
        </Fragment>
      )}
    </PlacesAutocomplete>
  )
}

export default AutocompleteItem;