import React from 'react';
import { Form, Cascader } from 'antd';
import data from './LocalizacionFormItemData.json';

const FormItem = Form.Item;

const LocalizacionFormItem = props => {
  return (
    <FormItem
      { ...props }
      extra={ "Elegir los 3 elementos: Provincia/Departamento/Localidad" }
    >
      <Cascader
        options={ data }
        placeholder="Seleccione Provincia/Departamento/Localidad"
        showSearch={ {
          filter: (inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) 
        } }
      />
    </FormItem>
  );
}

export default LocalizacionFormItem;