import React, { Fragment, useState } from 'react';
import { Layout, Divider, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import SideBar from './components/AppSideBar/AppSideBar';
import AppContent from './components/AppContent/AppContent';
import RightSideBar from './components/AppRightSideBar/AppRightSideBar';
import AuthHelper from '../../helpers/AuthHelper';
import AuthApi from '../../api/authApi';

const App = props => {
  const [collapsedMainSideBar, setCollapsedMainSideBar] = useState(false);
  const [collapsedRightSiderBar, setCollapsedRightSiderBar] = useState(false);

  const handleToggleMainSideBar = () => setCollapsedMainSideBar(!collapsedMainSideBar);

  const handleToggleRightSider = () => setCollapsedRightSiderBar(!collapsedRightSiderBar);

  return(
    <Layout>
      <SideBar
        collapsed={ collapsedMainSideBar }
        logout={ () => AuthApi.logout() }
        authenticated={ AuthHelper.isJwtTokenStored() }
      />
      <AppContent
        toggleMainSideBar={ () => handleToggleMainSideBar() }
        toggleRightSider={ () => handleToggleRightSider() }
        collapsedMainSideBar={ collapsedMainSideBar }
        user={ AuthHelper.getDecodedToken() }
      />
      <RightSideBar
        collapsed={ collapsedRightSiderBar }
        handleOnClose={ () => handleToggleRightSider() }
        width={ 350 }
        user={ AuthHelper.getDecodedToken() }
      />
    </Layout>
  );
}

export default withRouter(App);
