export default class ParentEntityHelper {
  static isParentEntityStored = () => {
    // if ParentEntity is a falsy value, then return false
    return sessionStorage.parentEntity ? true : false;
  }

  static getParentEntity = () => {
    return (this.isParentEntityStored()) ? sessionStorage.parentEntity : null;
  }

  static storeParentEntity = parentEntity => {
    sessionStorage.setItem('parentEntity', JSON.stringify(parentEntity));
    return null;
  }

  static removeParentEntity = () => {
    sessionStorage.removeItem('parentEntity');
    return null;
  }

  static hasJwtPayloadKey = key => {
    if (this.isParentEntityStored()) {
      const parentEntity = JSON.parse(sessionStorage.parentEntity);
      if (!key) {
        return null;
      }
      return parentEntity !== undefined;
    }
    return null;
  }

  static getValueFromParentEntity = key => {
    if (this.isParentEntityStored()) {
      const parentEntity = JSON.parse(sessionStorage.parentEntity);
      if (!key) {
        return null;
      }
      return parentEntity[key];
    }
    return null;
  }
}
