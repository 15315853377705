import Fetcher from "../helpers/Fetcher";

const UvertracApi = {
  get: searchObj => {
    let data = {
      module: searchObj.module ? searchObj.module : 'UvertracCore',
      model: searchObj.model,
      columnsToBeReturned: Array.isArray(searchObj.colReturn) ? searchObj.colReturn?.join() : searchObj.colReturn,
      columnsWhereSearch: Array.isArray(searchObj.colSearch) ? searchObj.colSearch?.join() : searchObj.colSearch,
      columnsToBeOrderBy: searchObj.colOrderBy,
      relationships: searchObj.relationships, 
      textToSearch: searchObj.textToSearch,
      JWTFilter: searchObj.JWTFilter
    }
    return Fetcher
      .getWithParams('application/generic-search/', data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  exists: searchObj => {
    let data = {
      module: searchObj.module ? searchObj.module : 'UvertracCore',
      model: searchObj.model,
      column: searchObj.column,
      value: searchObj.value,
    }

    return Fetcher
      .getWithParams('application/exist', data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  checkCuit: cuit => {
    let url = 'uvertraccore/afip/check-cuit/' + cuit;
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
}

export default UvertracApi;