import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Layout, Button, Card, Form, Input, Row, Col, Spin ,Select} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PageTitle from '../../../../components/PageTitle';
import basicStyle from '../../../../themes/basicStyle';
import UIHelper from '../../../../helpers/UIHelper';
import AppNotification from '../../../../components/AppNotification';
import TipoProductoApi from '../../../../api/parametricas/tipoProductoApi';
const { Content } = Layout;
const { Option } = Select;

const FormItem = Form.Item;

const TipoDeProductoForm = props => {
  const history = useHistory();
  const [tipoDeProducto, setTipoDeProducto] = useState(undefined);
  const [tiposCarga] = useState([
    {label: 'GRANEL', value: 'GRANEL' },
    {label: 'ANIMALES', value: 'ANIMALES' },
    {label: 'CARGAS GENERALES', value: 'CARGAS_GENERALES' },
    {label: 'CONTENEDORES', value: 'CONTENEDORES' },
    {label: 'PALLETIZADOS', value: 'PALLETIZADOS' },
    {label: 'MAQUINARIAS', value: 'MAQUINARIAS' },
  ]);

  const [working, setWorking] = useState(false);
  const [form] = Form.useForm(); 

  useEffect(()=>{
    if(history.location.pathname.indexOf('edit') > -1){
      loadTipoDeProducto();
    }
  }, []);

  const loadTipoDeProducto = () => {
    setWorking(true);
    TipoProductoApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setTipoDeProducto(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }
  
  const onFinish = values => {
    setWorking(true);
    let data = { 
      id: tipoDeProducto?.id,
      ...values,
    }
    TipoProductoApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Tipo de producto guardado correctamente');
        history.goBack();
      })
      .catch(error => setWorking(false));
  };
    
  return (
    <Content>
      <PageTitle title={ `${tipoDeProducto?.id ? 'Editar' : 'Nuevo'} Tipo de Producto` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            initialValues={ { ...tipoDeProducto } }
            scrollToFirstError
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                <FormItem name="nombre" label="Nombre" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input maxLength={255}/>
                </FormItem>
               
                <FormItem name="metodo_carga" label="Tipo de carga" hasFeedback rules={ UIHelper.defaultRules }>
                  <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
                    { tiposCarga.map(tipo => <Option key={tipo.value} value={tipo.value}>{ tipo.label }</Option>)}
                  </Select>
                </FormItem>
              </Col>
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
              </FormItem>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Content>
  );
}

export default TipoDeProductoForm;