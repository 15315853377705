import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import Transportista from './components/Transportista';
import TransportistaTableOfertas from './components/TransportistaTableOfertas';

const { Content } = Layout;

const VieajesTransportistasPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ Transportista } />
        <Route path={ `${props.match.path}/view/:id` } component={ TransportistaTableOfertas } />
      </Switch>
    </Content>
  );
}

export default VieajesTransportistasPage;