import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const EditarButton = props => {
  return <Tooltip 
      title={ props.title ? props.title : 'Editar' }
    >
      <Button 
        shape="circle" 
        icon={ <EditOutlined /> } 
        size="small" 
        onClick={ props.onClick }
      />
  </Tooltip>
}

export default EditarButton;