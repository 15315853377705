import React, { Fragment, useEffect, useState } from 'react';
import { Drawer, Spin } from 'antd';
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import PermisosTree from './PermisosTree';
import AppNotification from '../../../components/AppNotification';

const PermisosDrawer = props => {
  const history = useHistory();
  const [permisos, setPermisos] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if(props.visible == true){
      setWorking(true);
      props.getUserPermissions(props.usuario.id)
        .then(response => {
          setWorking(false);
          setPermisos(response.computedUserPermissions);
        })
        .catch(error => setWorking(false))
    }
  }, [props.visible]);

  const handlePermissionsChange = permissionsList => {
    setWorking(true);
    props.updateUserPermissions(props.usuario.id, permissionsList)
      .then(() => { 
        setWorking(false);
        AppNotification.showSuccess('Permisos Guardados Correctamente. Debe salir de la Aplicacion.'); 
      })
      .catch(error => { 
        setWorking(false);
        AppNotification.showError('Ocurrio un error tratando de guardar los permisos.'); 
        console.error(error); 
      });
  }

  return (
    <Drawer
      title="Permisos"
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <Spin spinning={ working }>
        <PermisosTree
          showShowOption={ false }
          permisos={ permisos }
          onPermissionsChange={ handlePermissionsChange }
        />
      </Spin>
    </Drawer>
  );
}

export default PermisosDrawer;
