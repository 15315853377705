import { PageHeader } from 'antd';
import React from 'react';
import './PageTitle.scss';

const PageTitle = props => {
  return <PageHeader { ...props} 
    title={ props.title }
    className={ `page-title ${props.className}` }
    onBack={ props.onBack }
    style={ { padding: 0 } }
  />
}

export default PageTitle;