import { useEffect, useState } from "react";
import { Button, Card, Table } from "antd";
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import Parser from "../../../helpers/Parser";
import Patente from "../../../components/Patente";

const RemolquesTable = props => {
  const [remolques, setRemolques] = useState([]);
    
  useEffect(() => {
    setRemolques(props.dashboardInfo?.remolquesConDocumentacionSinAprobar?.map(remolque => {
      return {
        id: remolque.id,
        patente: <Patente patente={ remolque.patente } remolque={ true }/>,
        fecha_seguro_comprobar: Parser.date(remolque.fecha_seguro_comprobar),
        fecha_vtv_comprobar: Parser.date(remolque.fecha_vtv_comprobar),
        falta_cedula_verde: remolque.falta_cedula_verde == true 
          ? <Button onClick={ () => handleApprove(remolque, 'cedula_verde') }><CloseSquareOutlined /> Ver</Button> 
          : <CheckSquareOutlined />,
        falta_seguro: remolque.falta_seguro == true 
          ? <Button onClick={ () => handleApprove(remolque, 'seguro') }><CloseSquareOutlined /> Ver</Button> 
          : <CheckSquareOutlined />,
        falta_vtv: remolque.falta_vtv == true 
          ? <Button onClick={ () => handleApprove(remolque, 'vtv') }><CloseSquareOutlined /> Ver</Button> 
          : <CheckSquareOutlined />
      }
    }));

  }, [props.dashboardInfo]);

  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id', align: 'center' },
    { title: 'Patente', dataIndex: 'patente', key: 'patente', width: 120, align: 'center' },
    { title: 'Fecha seguro a comprobar', dataIndex: 'fecha_seguro_comprobar', key: 'fecha_seguro_comprobar', align: 'center' },
    { title: 'Fecha VTV a comprobar', dataIndex: 'fecha_vtv_comprobar', key: 'fecha_vtv_comprobar', align: 'center' },
    { title: 'Falta cedula verde', dataIndex: 'falta_cedula_verde', key: 'falta_cedula_verde', align: 'center' },
    { title: 'Falta seguro', dataIndex: 'falta_seguro', key: 'falta_seguro', align: 'center' },
    { title: 'Falta VTV', dataIndex: 'falta_vtv', key: 'falta_vtv', align: 'center' }
  ];

  const handleApprove = (remolque, tipo) => {
    let imgUrl;
    // eslint-disable-next-line default-case
    switch(tipo){
      case "cedula_verde": imgUrl = remolque.url_imagen_cedula_verde; break;
      case "seguro": imgUrl = remolque.url_imagen_seguro; break;
      case "vtv": imgUrl = remolque.url_imagen_vtv; break;
    }
    props.showPhotoModal(imgUrl, 'remolques', tipo, remolque.id, remolque);
  }

  return (
    <Card title="Remolques con documentacion sin aprobar">
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ props.working }
        columns={ columns } 
        dataSource={ remolques }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
      />
    </Card>
  );
}

export default RemolquesTable;