import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Card, Tag, Button, Input, Col, Space, Tooltip } from 'antd';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import RemolquesApi from '../../../api/remolquesApi';
import PageTitle from '../../../components/PageTitle';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import AppNotification from '../../../components/AppNotification';
import Parser from '../../../helpers/Parser';
import moment from 'moment';
import Patente from '../../../components/Patente';
import SearchDinamic from '../../../components/Search/Search-Dinamic';

const RemolqueList = props => {
  const history = useHistory();
  const [remolques, setRemolques] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadRemolques();
  }, []);

  const columns = [
    { title: 'Patente', dataIndex: 'patente', width: 120, align: 'center',
      render: text => <Patente patente={ text } remolque={ true } />
    },
    { title: 'Vencimiento de seguro', dataIndex: 'fecha_de_vencimiento_seguro', align: 'center',
      render: text => moment().diff(moment(text, 'YYYY-MM-DD')) > 0 ? <Tag color="red">{ Parser.date(text) }</Tag> : Parser.date(text)
    },
    { title: 'Vencimiento de vtv', dataIndex: 'fecha_de_vencimiento_vtv', align: 'center',
      render: text => moment().diff(moment(text, 'YYYY-MM-DD')) > 0 ? <Tag color="red">{ Parser.date(text) }</Tag> : Parser.date(text)
    },
    { title: 'Estado documentación', dataIndex: 'fecha_de_vencimiento_licencia', width: '20%', align: 'center',
      render: (text, record) => {
        let docSinAprobar = record.media.filter(x => !x.custom_properties?.aprobada_at);
        return docSinAprobar.length > 0 
          ? <Tag color="red"><div style={{display: 'flex', flexDirection: 'column'}}><span>Falta aprobar:</span>{ docSinAprobar.map(doc => <span>{ doc.custom_properties?.imagen }</span>) }</div></Tag> 
          : <Tag color="green">OK</Tag>
      }
    }, 
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push(history.location.pathname + '/edit/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar el remolque?" onConfirm={ () => handleDelete(record.id) }/>
        </Space>
      }
    }
  ];

  const loadRemolques = () => {
    setWorking(true);
    RemolquesApi.get()
      .then(response => {
        setWorking(false);
        setRemolques(response);
      })
      .catch(error => setWorking(false));
  }
  
  const handleDelete = id => {
    setWorking(true);
    RemolquesApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Remolque eliminado correctamente');
        loadRemolques();
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Remolques"/>
      <Card
        title={<Col span={12}>
          <SearchDinamic 
            module="uvertraccore"
            model="remolque"
            columnsSearch={ ['patente'] }
            columnsReturn={ columns }
            orderBy="id"
            onchange={ response => setRemolques(response) }
            onClear={ loadRemolques }
          />
        </Col>}
        extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ remolques }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>
    </Fragment>
  );
}

export default RemolqueList;
