import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, Table, Button, Space, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ProvinciasApi from '../../../../api/parametricas/provinciasApi';
import AppNotification from '../../../../components/AppNotification';
import EditarButton from '../../../../components/Buttons/EditarButton';
import EliminarButton from '../../../../components/Buttons/EliminarButton';
import PageTitle from '../../../../components/PageTitle';
import MarcasApi from '../../../../api/parametricas/marcasApi';
import SearchDinamic from '../../../../components/Search/Search-Dinamic';

const MarcaList = props => {
  const history = useHistory();
  const [marcas, setMarcas] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadMarcas();
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'nombre', width: '100%', },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push(history.location.pathname + '/edit/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar la marca de vehículo?" onConfirm={ () => handleDelete(record.id) }/>
        </Space>
      }
    }
  ];

  const loadMarcas = () => {
    setWorking(true);
    MarcasApi.get()
      .then(response => {
        setWorking(false);
        setMarcas(response);
      })
      .catch(error => setWorking(false));
  }

  const handleDelete = id => {
    setWorking(true);
    MarcasApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Marca eliminada correctamente');
        loadMarcas();
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Marcas de Vehículo"
        onBack={ () => history.goBack() }
      />
      <Card
        title={<Col span={12}>
          <SearchDinamic
            module="uvertraccore"
            model="MarcaVehiculo"
            columnsSearch={ ["nombre"] }
            columnsReturn={ ["*"] }
            orderBy="nombre"
            onChange={ response => setMarcas(response) }
            onClear={ loadMarcas }
          />
        </Col>}
        extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ marcas }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>
    </Fragment>
  );
}

export default MarcaList;