import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';

import logoImage from './assets/uvertracLogo2.gif';
import { Layout } from 'antd';
import bgImage from './assets/lannd-bg.png';
import AuthHelper from '../../helpers/AuthHelper';
import LoginComponent from './components/LoginComponent';
import { useHistory, useLocation } from 'react-router';
import ConfigHelper from '../../helpers/ConfigHelper';
import ParentEntityHelper from '../../helpers/ParentEntityHelper';
import UserHelper from '../../helpers/UserHelper';
import AppNotification from '../../components/AppNotification';
import AuthApi from '../../api/authApi';

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [working, setWorking] = useState(false);

  useEffect(() => {
    checkToRedirect();
  }, []);

  const checkToRedirect = () => {
    if (AuthHelper.isJwtTokenStored()) {
      const redirectPath = determineRedirectPath();
      history.push(redirectPath);
    }
  }

  const determineRedirectPath = () => {
    const urlParams = QueryString.parse(location.search);
    let redirectPath = null;
    
    if (urlParams.redirect) {
      redirectPath = urlParams.redirect;
    }
    else {
      if (UserHelper.userHasPermission('tr_dashboard_page.visualizar_left_menu')){
        redirectPath = '/inicio-transportista';
      }
      if (UserHelper.userHasPermission('uver_dashboard_page.visualizar_left_menu')){
        redirectPath = '/inicio-usuario-administrativo';
      }
      if (UserHelper.userHasPermission('dc_dashboard_page.visualizar_left_menu')){
        redirectPath = '/inicio-dador-carga';
      }
    }

    return redirectPath;
  }

  const handleLogin = (user, password) => {
    setWorking(true);
    AuthApi.login(user, password)
      .then(response => {
        setWorking(false);
        AuthHelper.storeJwtToken(response.loginReturnObject.token);

        if (response.loginReturnObject.parent_entity){
          ParentEntityHelper.storeParentEntity(response.loginReturnObject.parent_entity);
        }
        if(response.loginReturnObject.permisos?.length > 0){
          UserHelper.storeUserPermissions(JSON.parse(response.loginReturnObject.permisos[0].permisos));
        }
        ConfigHelper.storeParameters(response.loginReturnObject.configuracion);
        checkToRedirect();
      })
      .catch(error => {
        setWorking(false);
        let message;
        try {
          if(error.response.data.error === 'invalid_credentials'){
            message = 'Usuario o contraseña incorrectos';
          }
          if (error.response.data.error === 'user_not_confirmed_yet') {
            message = 'El acceso para este Usuario se encuentra inhabilitado';
          }
          else{
            message = error.response.data.error ? error.response.data.error : 'Ocurrio un error...';
          }
        } catch (e) {
          message = 'Ocurrio un error...';
        }
      });
  }

  const handleRecoverPassword = user => {
    setWorking(true);
    AuthApi.recoverPassword(user)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess(response.message);
      })
      .catch(error => setWorking(false));
  }

  return (
    <Layout style={ { height: '100vh', background: 'url(' + bgImage + ') center no-repeat', backgroundSize: 'cover'} }>
      <LoginComponent
        title={ 'UverTrac' }
        logo={ logoImage }
        handleLogin={ handleLogin }
        handleRecoverPassword={ handleRecoverPassword }
        working={ working }
        /*loginErrorMessage={ props.errorMessage }
        recoverErrorMessage={ props.errorMessage }
        isRecoveringPasswordSuccessful={ props.isRecoverSuccess }*/
      />
    </Layout>
  );
}

export default LoginPage;
