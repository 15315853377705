import React from 'react';
import { Col, Row } from 'antd';
import AppLoader from '../../assets/uvertrac_loader3.gif';

const Spinner = props => {
  return (
    <Row type="flex" align="middle" style={ { height: '100%', } }>
      <Col span={ 24 }>
        <img alt="loader" src={ AppLoader } 
          style={ { 
            height: 40, 
            width: 40, 
            verticalAlign: 'middle',
            boxShadow: '3px 3px 3px 1px rgba(12, 0, 0, 0.2)',
            borderRadius: 10,
            //border: '1px solid #4DD0E1'
          } }
        />
      </Col>
    </Row>
  )
}

export default Spinner;