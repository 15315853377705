import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Tag, Button, Input, Col, Space, Tooltip } from 'antd';
import { PlusOutlined, UnorderedListOutlined, PhoneOutlined } from '@ant-design/icons';
import UsuariosApi from '../../../api/usuariosApi';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import AppNotification from '../../../components/AppNotification';
import EnableDisableButton from '../../../components/Buttons/EnableDisableButton';
import PermisosDrawer from './PermisosDrawer';
import AuthApi from '../../../api/authApi';
import SearchDinamic from '../../../components/Search/Search-Dinamic';

const UsuarioList = props => {
  const history = useHistory();
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUsuario, setSelectedUsuario] = useState(undefined);
  const [showPermisos, setShowPermisos] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadUsuarios();
  }, []);

  const columns = [
    { title: 'Id', dataIndex: 'id', width: '10%', },
    { title: 'Email', dataIndex: 'email', width: '80%', },
    { title: 'Estado', dataIndex: 'habilitado', width: '10%', align: 'center',
      render: text => <Tag color={ text ? 'geekblue' : 'red' }>{ text ? 'Habilitado' : 'Deshabilitado' }</Tag>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push(history.location.pathname + '/edit/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar el usuario?" onConfirm={ () => handleDelete(record.id) }/>
          <EnableDisableButton habilitado={ record.habilitado } onClick={ () => handleEnableDisable(record.id, !record.habilitado) } />
          <Tooltip title="Limpiar Mac Address" placement="topRight">
            <Button shape="circle" icon={ <PhoneOutlined /> } size="small"
              onClick={ () => handleClearMacAddress(record.id) }
            />
          </Tooltip>
          <Tooltip title={ 'Permisos' } placement="topRight">
            <Button shape="circle" icon={ <UnorderedListOutlined /> } size="small"
              onClick={ () => { setSelectedUsuario(record); setShowPermisos(true); } }
            />
          </Tooltip>
        </Space>
      }
    }
  ];

  const loadUsuarios = () => {
    setWorking(true);
    UsuariosApi.get()
      .then(response => {
        setWorking(false);
        setUsuarios(response);
      })
      .catch(error => setWorking(false));
  }
  
  const handleDelete = id => {
    setWorking(true);
    UsuariosApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Usuario eliminado correctamente');
        loadUsuarios();
      })
      .catch(error => setWorking(false));
  };
  
  const handleEnableDisable = (id, status) => {
    setWorking(true);
    UsuariosApi.enable_disable(id, status)
      .then(response => {
        setWorking(false);
        loadUsuarios();
      })
      .catch(error => setWorking(false));
  };

  const handleClearMacAddress = id => {
    setWorking(true);
    UsuariosApi.clearMacAddress(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Mac Address limpiada correctamente');
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Usuarios"/>
      <Card
        title={<Col span={12}>
          <SearchDinamic 
            module="uvertraccore"
            model="Usuario"
            columnsSearch={ ['email'] }
            columnsReturn={ columns }
            onChange={ response => setUsuarios(response) }
            onClear={ loadUsuarios }
          />
        </Col>}
        extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ usuarios }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          //onRow={ record => ({ onClick: () => { setSelectedRow(record.id); }}) }
        />
        <PermisosDrawer
          visible={ showPermisos }
          onClose={ () => setShowPermisos(false) }
          usuario={ selectedUsuario }
          getUserPermissions={ userId => AuthApi.getUserPermissions(userId) }
          updateUserPermissions={ (userId, userPermissions) => AuthApi.updateUserPermissions(userId, userPermissions) }
        />
      </Card>
    </Fragment>
  );
}

export default UsuarioList;
