import { useEffect, useState } from "react";
import { Button, Card, Spin } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import Map from "../../../components/Map";

const ViajesEnCursoMap = props => {
  const [markers, setMarkers] = useState([]);
    
  useEffect(() => {
    setMarkers(
      props.dashboardInfo?.viajes_en_curso?.filter(x => x.ultima_geoposicion_insertada && x.ultima_geoposicion_insertada !== null).map(viaje => {
        return {
          id: viaje.id,
          text: viaje.codigo_uvertrac,
          lat: parseFloat(viaje.ultima_geoposicion_insertada.latitud),
          lng: parseFloat(viaje.ultima_geoposicion_insertada.longitud),
          info: [
            { key: 'Estado', value: viaje.estado },
            { key: 'Fecha', value: viaje.fecha_carga },
            { key: 'Origen', value: viaje.origen_direccion },
            { key: 'Destino', value: viaje.destino_direccion },
            { key: 'Patente', value: viaje.unidad_transporte.camion.patente },
            { key: 'Chofer', value: viaje.unidad_transporte.chofer.razon_social },
            { key: 'Cuit', value: viaje.unidad_transporte.chofer.cuit  }
          ]
        };
      })
    );
  }, [props.dashboardInfo]);

  return (
    <Card title="Viajes en curso"
      extra={ <Button type="primary" icon={ <ReloadOutlined />} onClick={ () => props.loadDashboard() }/>}
    >
      <Spin spinning={ props.working }>
        <div style={ { height: '600px', width: '100%' } }>
          <Map
            defaultLat={ -31.3992876 }
            defaultLng={ -64.2643838 }
            defaultZoom={ 6 }
            markers={ markers }
          />
        </div>
      </Spin>
    </Card>
  );
}

export default ViajesEnCursoMap;