export default class UIHelper {
  static defaultRules = [{required: true, message: 'Por favor ingrese un valor' }];
  
  static defaultSelectFilter = (input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  static clearFieldErrors = (changedValues, allValues, form, fields) => {
    let clean = false;
    fields.map(field => {
      if(Object.keys(changedValues)[0] == field){
        clean = true;
      }
    })

    if(clean){
      const updatedFields = Object.keys(allValues).filter(name => form.getFieldError(name).length).map(name => ({ name, errors: [] }));
      form.setFields(updatedFields);
    }
  };
}