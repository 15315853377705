import React, { Component, Fragment, useState } from 'react';
import { Tag, Button, Tooltip, Alert, Table, Card, Space } from 'antd';
import { IdcardOutlined, CarOutlined } from '@ant-design/icons';
import ViajeEstadoTag from '../../../../../components/Tags/ViajeEstadoTag';

const ViajeRealizadosList = props => {
  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac' },
    { title: 'Chofer', dataIndex: ['unidad_transporte', 'chofer', 'razon_social'], key: 'chofer' },
    { title: 'Transportista', dataIndex:['unidad_transporte', 'transportista', 'razon_social'], key: 'chofer'},
    { title: 'Estado', key: 'estado', dataIndex: 'estado',
      render: text => <ViajeEstadoTag estado={ text }/>
      //<Tag color={ text === 'cancelado' ? "#f50" : '#87d068' }><span style={ { textTransform: 'capitalize' } }>{ text }</span></Tag>
    },
  ];

  return(
    <Fragment>
      <Card title="Viajes realizados" size="small">
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ props.working }
          columns={ columns } 
          dataSource={ props.viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>
    </Fragment>
  );
}

export default ViajeRealizadosList;
