const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
};

const colStyle = {
  marginBottom: '16px',
};

const gutter = 16;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 14,
      offset: 5,
    },
  },
};

const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
  formItemLayout,
  tailFormItemLayout
};

export default basicStyle;
