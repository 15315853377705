import Fetcher from "../../helpers/Fetcher";

const MarcasApi = {
  get: id => {
    let url = 'uvertraccore/marcas/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'uvertraccore/marcas/';
    if(!data.id){
      return Fetcher
        .post(url, data)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
      .put(url + data.id, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('uvertraccore/marcas/' + id)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  }
}

export default MarcasApi;