import { useEffect, useState } from "react";
import { Button, Card, Table } from "antd";
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import Parser from "../../../helpers/Parser";

const ChoferesTable = props => {
  const [choferes, setChoferes] = useState([]);
    
  useEffect(() => {
    setChoferes(props.dashboardInfo?.choferesConDocumentacionSinAprobar?.map(chofer => {
      return {
        id: chofer.id,
        nombre: `${chofer.razon_social}`,
        cuit: chofer.cuit,
        telefono: chofer.telefono_celular,
        fecha_de_vencimiento_licencia_comprobar: <span>{ Parser.date(chofer.fecha_de_vencimiento_licencia_comprobar) }</span>,
        falta_licencia_de_conducir: 
          chofer.falta_licencia_de_conducir == true 
            ? <Button onClick={ () => handleApprove(chofer, 'licencia_de_conducir') }><CloseSquareOutlined /> Ver</Button> 
            : <CheckSquareOutlined />
      }
    }));
  }, [props.dashboardInfo]);

  const columns = [
    { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
    { title: 'Cuit', dataIndex: 'cuit', key: 'cuit', align: 'center' },
    { title: 'Telefono celular', dataIndex: 'telefono', key: 'telefono' },
    { title: 'Fecha Vencimiento a comprobar', dataIndex: 'fecha_de_vencimiento_licencia_comprobar', key: 'fecha_de_vencimiento_licencia_comprobar', align: 'center' },
    { title: 'Falta licencia de conducir', dataIndex: 'falta_licencia_de_conducir', key: 'falta_licencia_de_conducir', align: 'center' }
  ];

  const handleApprove = (chofer, tipo) => {
    props.showPhotoModal(chofer.url_imagen_licencia_de_conducir, 'choferes', tipo, chofer.id, chofer);
  }

  return (
    <Card title="Choferes con documentacion sin aprobar">
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ props.working }
        columns={ columns } 
        dataSource={ choferes }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
      />
    </Card>
  );
}

export default ChoferesTable;