import React, { useEffect, useState } from 'react';

import { Layout, Card, Form, Spin } from 'antd';
import AppNotification from '../../../../components/AppNotification';
import { useHistory } from 'react-router';
import PageTitle from '../../../../components/PageTitle';
import ChoferesApi from '../../../../api/choferesApi';
import StepsForm from '../../../../components/StepsForm';
import moment from 'moment';
import DatosPersonalesStep from './components/DatosPersonalesStep';
import LicenciaStep from './components/LicenciaStep';
import ConfirmacionStep from './components/ConfirmacionStep';
import ChoferEditForm from './components/ChoferEditForm';

const { Content } = Layout;

const ChoferForm = props => {
  const history = useHistory();
  const [chofer, setChofer] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    if(history.location.pathname.indexOf('edit') > -1){
      loadChofer();
    }
  }, []);

  const steps = [
    { title: 'Datos Personales', content: DatosPersonalesStep },
    { title: 'Licencia de Conducir', content: LicenciaStep },
    { title: 'Confirmación', content: ConfirmacionStep }
  ];

  const loadChofer = () => {
    setWorking(true);
    ChoferesApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setChofer(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);

    if(!chofer?.id){
      let formData = new FormData();
      for (let key in values) {
        if(values[key]){
          const dashedKey = key.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
          if (values[key] instanceof moment) { formData.set(dashedKey, values[key].format("YYYY-MM-DD")); }
          else if (values[key] instanceof File) { formData.append(dashedKey, values[key]); }
          else { formData.set(dashedKey, values[key]); }
        }
      }
      saveChofer(formData);
    }
    else{
      let data = {
        id: chofer.id,
        cuit: values.cuit,
        telefono_celular: values.telefono_celular,
        fecha_de_vencimiento_licencia: values.fecha_de_vencimiento_licencia?.format("YYYY-MM-DD")
      };

      let licenciaFormData = null;
      if (Array.isArray(values.licencia_de_conducir) && values.licencia_de_conducir.length === 1) {
        if(values.licencia_de_conducir[0] instanceof File){
          licenciaFormData = new FormData();
          licenciaFormData.set('id', chofer.id);
          licenciaFormData.append('licencia_de_conducir', values.licencia_de_conducir[0]);
        }
      }

      (licenciaFormData ? ChoferesApi.updateLicencia(chofer.id, licenciaFormData) : Promise.resolve())
      .then(() => (saveChofer(data)))
      .catch(error => { setResult('error'); setWorking(false); });
    }
  };

  const saveChofer = data => {
    ChoferesApi.save(data)
      .then(response => {
        console.log('response',response.data)

        setWorking(false);
        setResult('success');
        AppNotification.showSuccess('Chofer guardado correctamentes');
        history.goBack();
      })
      .catch(error => { 
        setResult('error');
        setWorking(false); 
      });
  }

  return (
    <Content>
      <PageTitle title={ `${chofer?.id ? 'Editar' : 'Nuevo'} Chofer` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          { !chofer
            ? <StepsForm
              steps={ steps }
              onFinish={ onFinish }
              enablePrevious={ true }
              result={ result }
            />
            : <ChoferEditForm
              chofer={ chofer }
              onFinish={ onFinish }
            />
          }
        </Card>
      </Spin>
    </Content>
  );
}

export default ChoferForm;
