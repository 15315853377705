import React from 'react';
import { Button, Space } from 'antd';

const StepsFormButtons = props => {
  return (
    <div className="steps-action" style={ { float: 'right' } }>
      <Space>
        { props.enablePrevious && props.currentStep > 0
          ? <Button style={ { marginLeft: 8 } } disabled={ props.disable } onClick={ () => props.handlePrevious() }>
            { props.buttonPreviousLabel ? props.buttonPreviousLabel : 'Anterior' }
          </Button>
          : '' }
        { props.currentStep < props.numberOfSteps - 1
          ? <Button type="primary" disabled={ props.disable } onClick={ () => props.handleNext() }>
            { props.buttonNextLabel ? props.buttonNextLabel : 'Siguiente' }
          </Button>
          : '' }
        { props.currentStep === props.numberOfSteps - 1
          ? <Button type="primary" disabled={ props.disable } onClick={ () => props.handleDone() }>
            { props.buttonDoneLabel ? props.buttonDoneLabel : 'Finalizar' }
          </Button>
          : ''}
      </Space>
    </div>
  );
}

export default StepsFormButtons;
