import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Card, Tag, Col, Space, Tooltip, Button, Row, Popconfirm } from 'antd';
import { CloseSquareOutlined, CheckSquareOutlined } from '@ant-design/icons';
import PageTitle from '../../../../components/PageTitle';
import Search from '../../../../components/Search';
import ViajeDetail from './components/ViajeDetail';
import VerButton from '../../../../components/Buttons/VerButton';
import Parser from '../../../../helpers/Parser';
import Patente from '../../../../components/Patente';
import TransportistasApi from '../../../../api/transportistasApi';
import ViajesApi from '../../../../api/viajesApi';
import AppNotification from '../../../../components/AppNotification';
import ViajeEstadoTag from '../../../../components/Tags/ViajeEstadoTag';
import Consts from '../../../../helpers/Consts';

const ViajeEnCursoList = props => {
  const history = useHistory();
  const [viajes, setViajes] = useState([]);
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadViajes();
  }, []);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Titulo', dataIndex: ['publicacion_viaje','titulo'], key: 'titulo' },
    { title: 'Fecha', dataIndex: ['publicacion_viaje', 'fecha_hora_retiro'], key: 'fecha_carga', align: 'center',
      render: text => <span>{ Parser.datetime(text)}</span>
    },
    { title: 'Unidad de Transporte', dataIndex: 'unidad_transporte', key: 'unidad_transporte',
      render: (text, record) => {
        let unidad = record.unidad_transporte;
        return <Row>
          <Col span={24}>{ unidad?.chofer?.razon_social }</Col>
          <Col span={12}>{ unidad?.camion?.patente ? <Patente patente={ unidad.camion.patente } style={ { width: 120 } }/> : '' }</Col>
          <Col span={12}>{ unidad?.remolque?.patente ? <Patente patente={ unidad.remolque.patente } remolque={ true } style={ { width: 120 } } /> : '' }</Col>
        </Row>
      }
    },
    { title: 'Ultimo evento', dataIndex: 'evento', key: 'evento',
      render: (text, record) => {
        let lastEvent = record.historial_eventos && record.historial_eventos.length > 0 ? record.historial_eventos[record.historial_eventos.length -1] : null;
        return <div><p style={ { margin: 0 } }><b>{ lastEvent?.mensaje }</b></p>
          <p style={ { margin: 0, fontSize: 10 } }>{ Parser.datetime(lastEvent?.fecha_hora_enviado) }</p>
        </div>
      }
    },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <ViajeEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedViaje(record); setShowViajeDetail(true); } }/>
          { record.estado === Consts.EstadoViaje.INICIADO
              ? <Popconfirm title="¿Está seguro?" okText="Si" cancelText="No"
                onConfirm={ () => handleCancelar(record.id) }
              >
                <Button icon={ <CloseSquareOutlined /> } size={ 'small' } type="danger">Cancelar</Button>
              </Popconfirm>
              : '' }
          { record.estado === Consts.EstadoViaje.VIAJANDO
              ? <Popconfirm title="¿Está seguro?" okText="Si" cancelText="No"
                onConfirm={ () => handleTerminar(record.id) }
              >
                <Button icon={ <CheckSquareOutlined /> } size={ 'small' } type="primary">Finalizar</Button>
              </Popconfirm>
              : '' }
        </Space>
      }
    }
  ];

  const loadViajes = () => {
    setWorking(true);
    TransportistasApi.getViajesEnCurso()
      .then(response => {
        setWorking(false);
        setViajes(response);
      })
      .catch(error => setWorking(false));
  }

  const handleCancelar = id => {
    setWorking(true);
    ViajesApi.cancelarViajeEnCurso(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Viaje cancelado correctamente');
        loadViajes();
      })
      .catch(error => setWorking(false));
  }

  const handleTerminar = id => {
    setWorking(true);
    ViajesApi.terminarViajeEnCurso(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Viaje terminado correctamente');
        loadViajes();
      })
      .catch(error => setWorking(false));
  }

  return (
    <Fragment>
      <PageTitle title="Viajes en Curso"/>
      <Card
        /*title={<Col span={12}>
          <Search 
            module="uvertraccore"
            model="publicacionviaje"
            columnsSearch={ ["codigo_uvertrac", "titulo", "estado"] }
            columnsReturn={ ["*"] }
            orderBy="codigo_uvertrac"
            relationships={ "oferta,oferta.viajes_en_curso" }
            onchange={ response => setViajes(response) }
            onClear={ loadViajes }
          />
        </Col>}*/
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />

        <ViajeDetail
          viaje={ selectedViaje }
          visible={ showViajeDetail }
          onClose={ () => setShowViajeDetail(false) }
        />
      </Card>
    </Fragment>
  );
}

export default ViajeEnCursoList;
