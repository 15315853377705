import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import routes from '../routes';
import AuthHelper from '../../../../helpers/AuthHelper';
import UserHelper from '../../../../helpers/UserHelper';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const AppMenu = props => {

  const menuItem = (key, path, icon, permission = null) => {
    let menuItem = (
      <Menu.Item key={ key }>
        <Link to={ path }>
          { icon.type }
          <span> { icon.title }</span>
        </Link>
      </Menu.Item>
    );
    
    if(permission == null){
      return menuItem;
    }else if (UserHelper.userHasPermission(permission)){
      return menuItem;
    }else{
      return null;
    }
  };

  const subMenu = (key, icon, menuItemGroups) => (
    <SubMenu key={ key } title={ <span>{ icon.type }<span> { icon.title } </span></span> }>
      { menuItemGroups.map(menuItemGroup =>
        renderMenu(menuItemGroup)
      ) }
    </SubMenu>
  );

  const menuItemGroup = menuItemGroup => (
    <MenuItemGroup key={ menuItemGroup.key } title={ menuItemGroup.title }>
      { menuItemGroup.menues.map(menuItem => this.menuItem(menuItem.key, menuItem.path, menuItem.icon, menuItem.permission)) }
    </MenuItemGroup>
  );

  const renderMenu = route => {
    if (route.withItemGroup) {
      return subMenu(route.key, route.icon, route.withItemGroup, route.permission);
    } else if (route.menues) {
      return menuItemGroup(route);
    } else {
      return menuItem(route.key, route.path, route.icon, route.permission);
    }
  };
    
  return(
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={ ['inicio'] }
    >
      { routes.filter(route => route.menu).map(route =>
        renderMenu(route)
      ) }
    </Menu>
  );
}

export default AppMenu;
