//import React from 'react';
import { notification } from 'antd';

import './AppNotification.scss';

notification.config({
  placement: 'bottomRight',
  bottom: 0,
  duration: 5
});

export default class AppNotification {
  static showError(text){
    notification['error']({
      className: 'ant-notification-error',
      message: 'Error',
      description: text
    });
  }

  static showSuccess(text){
    notification['success']({
      className: 'ant-notification-success',
      message: 'Exito',
      description: text
    });
  }

  static showWarning(text){
    notification['warning']({
      className: 'ant-notification-warning',
      message: 'Alerta',
      description: text
    });
  }

  static showInfo(text){
    notification['info']({
      className: 'ant-notification-info',
      message: 'Info',
      description: text
    });
  }
}