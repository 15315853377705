import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import OfertaRealizadaList from './components/list/OfertaRealizadaList';

const { Content } = Layout;

const OfertasRealizadasTransportistasPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ OfertaRealizadaList } />
      </Switch>
    </Content>
  );
}

export default OfertasRealizadasTransportistasPage;