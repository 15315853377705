import React from 'react';
import { useHistory } from 'react-router';
import { Layout, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import AppMenu from './AppMenu';
import './appSideBar.scss';
import { siteConfig } from '../../../../settings';

const { Sider } = Layout;

const AppSideBar = props => {
  const history = useHistory();

  const handleLogoutClick = () => {
    props.logout();
    history.push('/login');
  }

  return (
    props.authenticated
    ? <Sider
        className="app-sidebar"
        style={ { height: '100vh' } }
        collapsible
        collapsed={ props.collapsed }
        trigger={ null }
      >
        <div className={ props.collapsed ? 'logo-min' : 'logo' }>
          <img alt="logo" src={ siteConfig.siteLogo }/>
        </div>
        <AppMenu />
        <div className="bottom-options">
          { props.collapsed ?
            <Button type="primary" icon={<LogoutOutlined />} onClick={ handleLogoutClick } /> :
            <Button type="primary" icon={<LogoutOutlined />} onClick={ handleLogoutClick }>
              Salir
            </Button> }
        </div>
      </Sider>
    : null
  );
}

export default AppSideBar;
