import React, { Fragment, useEffect, useState } from 'react';

import { Divider, Drawer, Tag, Alert, Descriptions ,Modal, Empty} from 'antd';
import Parser from '../../../helpers/Parser';
import Map from '../../../components/Map';

const DescItem = Descriptions.Item;
const TransportistaViajeDetail = props => {
  
  const [dadorCarga,setDadorCarga]=useState({})

  const showCartaPorteModal = image => {
    Modal.info({
      width: '60%',
      title: 'Codigo de trazabilidad de granos (CTG)',
      content: (
        <div>
          <img alt="carta" style={ { width: '100%', maxHeight: 500 } } src={ image } />
        </div>
      ),
      onOk() { },
    });
  }

  const validateImageCtg =()=>{
    if(props.viajeFinalizado.viajes_finalizados.map(obj => obj.entityMedia)[0] !== null &&
        props.viajeFinalizado.viajes_finalizados.map(obj => obj.entityMedia).length>0){
          return true
    }
    return false
  }
  const validateNumberCtg =()=>{
    if(props.viajeFinalizado.viajes_finalizados.length>0){
      if(props.viajeFinalizado.viajes_finalizados.map(obj => obj.numero_carta_porte )[0]!== null){
        return true
      }
    }
    return false
  }
  useEffect(() => {
    filterDador(props.oferta?.publicacion_viaje.dador_carga)

  }, [props.oferta?.publicacion_viaje]);
  
  const filterDador =(data)=>{
    if(data !== undefined){
      let dador = data[0]
      setDadorCarga(dador)
    }
  }
  
  return (
    <Drawer 
      title={ <span>Unidad de transporte</span> }
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <div style={ { height: 200 } }>
        { props.visible
          ? <Map routes={ {
            origin: {
              lat: props.oferta?.origen_latitud,
              lng: props.oferta?.origen_longitud
            },
            destination: {
              lat: props.oferta?.destino_latitud,
              lng: props.oferta?.destino_longitud
            }
          } }/>
          : '' }
      </div>

       <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Dador de carga"><Tag color="cyan">{ dadorCarga?.razon_social }</Tag></DescItem>
        <DescItem label="Telefono">{dadorCarga?.telefono }</DescItem>
        <DescItem label="Cuit">{ dadorCarga?.cuit }</DescItem>
        <DescItem label="Direccion">{ dadorCarga?.direccion}</DescItem>
        <DescItem label="Email">{ dadorCarga?.email}</DescItem>
      </Descriptions>

       <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Chofer"><Tag color="cyan">{ props.unidad?.chofer.razon_social }</Tag></DescItem>
        <DescItem label="Fecha de vencimiento licencia">{ props.unidad?.chofer.fecha_de_vencimiento_licencia }</DescItem>
        <DescItem label="Cuit">{ props.unidad?.chofer.cuit }</DescItem>
        <DescItem label="Telefono">{ props.unidad?.chofer.telefono_celular}</DescItem>
      </Descriptions>

       <Divider />
      
      { props.unidad?.camion 
      ?<div>
      <Descriptions column={1} bordered size="small">
        <DescItem label="Patente camion"><Tag color="cyan">{ props.unidad?.camion.patente }</Tag></DescItem>
        <DescItem label="Fecha de vencimiento ruta">{ props.unidad?.camion.fecha_de_vencimiento_ruta }</DescItem>
        <DescItem label="Fecha de vencimiento seguro">{ props.unidad?.camion.fecha_de_vencimiento_seguro }</DescItem>
        <DescItem label="Fecha de vencimiento vtv">{ props.unidad?.camion.fecha_de_vencimiento_vtv}</DescItem>
      </Descriptions>
      
      <Divider />
      </div>
      :""
     }

     { props.unidad?.remolque 
      ?<div>
        <Descriptions column={1} bordered size="small">
          <DescItem label="Patente acoplado"><Tag color="cyan">{ props.unidad.remolque.patente }</Tag></DescItem>
          <DescItem label="Fecha de vencimiento seguro">{ props.unidad.remolque.fecha_de_vencimiento_seguro }</DescItem>
          <DescItem label="Fecha vencimiento vtv">{ props.unidad.remolque.fecha_de_vencimiento_vtv }</DescItem>
          <DescItem label="Tipo de acoplado">{ props.unidad.remolque.tipo_acoplado.nombre}</DescItem>
        </Descriptions>
      
      <Divider />
      </div>
      :""
     }
     { validateNumberCtg()
        ?<Fragment>
          <Descriptions column={1} bordered size="small">
            <DescItem label="Codigo (CTG):"><Tag color="cyan">{ props.viajeFinalizado.viajes_finalizados.map(obj => obj.numero_carta_porte ) }</Tag></DescItem>
          </Descriptions>

          
        </Fragment>
        : <Alert style={ { fontSize: 12} } type="warning" message="No posee Codigo de Trazabilidad de Granos (CTG)" /> }

      { validateImageCtg()
          
            ?<Fragment> 
            <div style={ { textAlign: 'center' } }>
              <img alt="carta"
                style={ { width: 125, height: 75, cursor: 'pointer' } }
                src={  props.viajeFinalizado.viajes_finalizados.map(obj => obj.entityMedia?.carta_porte?.url )[0] } 
                onClick={ () => showCartaPorteModal(props.viajeFinalizado.viajes_finalizados.map(obj => obj.entityMedia.carta_porte.url))} 
              />
            </div> 
            </Fragment>
            : <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE  } /> }

    </Drawer>
  );
}

export default TransportistaViajeDetail;