import React, { useEffect, useState } from 'react';
import { Modal, Button, Card, Alert, Descriptions } from 'antd';
import Parser from '../../../helpers/Parser';

const DescItem = Descriptions.Item;

const ApprovePhotoModal = props => {
  const [img, setImg] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setImg(props.imgUrl);
    setLoaded(false);
  }, [props.imgUrl]);
  
  const generateHeader = () => {
    let data = [];
    // eslint-disable-next-line default-case
    switch(props.approveEntidad){
      case 'choferes': 
        data.push({label: 'Nombre', value: props.headerInfo.razon_social});
        data.push({label: 'Cuit', value: props.headerInfo.cuit});
        data.push({label: 'Vencimiento licencia', value: Parser.date(props.headerInfo.fecha_de_vencimiento_licencia_comprobar)});
        break;
      case 'camiones':
        data.push({label: 'Patente', value: props.headerInfo.patente});
        if(props.approveTipo === 'ruta'){ data.push({label: 'Vencimiento ruta', value: Parser.date(props.headerInfo.fecha_ruta_comprobar)}); }
        if(props.approveTipo === 'seguro'){ data.push({label: 'Vencimiento seguro', value: Parser.date(props.headerInfo.fecha_seguro_comprobar)}); }
        if(props.approveTipo === 'vtv'){ data.push({label: 'Vencimiento vtv', value: Parser.date(props.headerInfo.fecha_vtv_comprobar)}); }
        break;
      case 'remolques':
        data.push({label: 'Patente', value: props.headerInfo.patente});
        if(props.approveTipo === 'seguro'){ data.push({label: 'Vencimiento seguro', value: Parser.date(props.headerInfo.fecha_seguro_comprobar)}); }
        if(props.approveTipo === 'vtv'){ data.push({label: 'Vencimiento vtv', value: Parser.date(props.headerInfo.fecha_vtv_comprobar)}); }
        break;
    };

    if(data.length === 0){
      return <Alert type="warning" message={ <div>Sin informacion para mostrar</div> }/>
    }

    return <Descriptions bordered column={1} size="small">
      { data.map(item => <DescItem label={ item.label }>{ item.value }</DescItem>) }
    </Descriptions>
  }

  return (  
    <Modal width={ '60%' }
      visible={ props.visible }
      title={ 'Aprobar' }
      onOk={ props.onOk }
      onCancel={ props.onCancel }
      footer={ [
        <Button key="submit" type="primary" disabled={ !loaded } onClick={ props.onOk }>Autorizar</Button>,
        <Button key="back" onClick={ props.onCancel }>Cancelar</Button>,
      ] }
    >
      <Card>
        { generateHeader() }
        <br/>
        <img alt="img" src={ img } width={ '100%' } style={ loaded ? {} : { display: 'none' } } onLoad={ () => setLoaded(true) } />
      </Card>
    </Modal>
  );
}

export default ApprovePhotoModal;
