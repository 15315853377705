import React, { useEffect, useState } from 'react';
import { Steps, Layout, Row, Col, Card, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import StepsFormButtons from './StepsFormButtons';
import './StepsForm.scss';

const Step = Steps.Step;
const { Content } = Layout;

const StepsForm = props => {
  const [current, setCurrent] = useState(props.initialStep ? props.initialStep : 0);
  const [status, setStatus] = useState('process');
  const [nextAction, setNextAction] = useState(undefined);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setData(props.initialValues);
  }, [props.initialValues]);

  useEffect(() => {
    if(props.result){
      setStatus(props.result === 'success' ? 'finish' : 'error');
      setNextAction(undefined);
    }
    
  }, [props.result]);

  const onValidate = (valid, formData) => {
    const nextStep = current + 1;
    const updatedData = { ...data, ...formData };

    if(!valid){
      setStatus('error');
      setNextAction(undefined);
      return;
    }
    
    // eslint-disable-next-line default-case
    switch (nextAction) {
      case 'continue':
        setStatus('process');
        setCurrent(nextStep);
        setData(updatedData);
        setNextAction(undefined);
        if (props.notifyDataToParent){ props.notifyDataToParent(updatedData); }
        break;
      case 'sendDone':
        props.onFinish(updatedData);
        setStatus('process');
        break;
    }
  }

  const handleNext = () => {
    setStatus('wait');
    setNextAction('continue');
  }

  const handlePrevious = () => {
    const previousStep = current - 1;
    setCurrent(previousStep)
    setStatus('process');
  }

  const handleDone = () => {
    setStatus('wait');
    setNextAction('sendDone');
  }

  return (
    <div className={ "steps-form-container " + (props.vertical ? "steps-vertical-layout" : "steps-horizontal-layout") }>
      <div className="steps">
        <Steps direction={ props.vertical ? "vertical" : "horizontal" } current={ current } status={ status }>
          { props.steps.map((item, index) =>
            <Step
              key={ item.title }
              title={ item.title }
              description={ item.description }
              icon={ index === current && status === 'wait' ? <LoadingOutlined /> : null }
            />
          ) }
        </Steps>
      </div>
      <div className="steps-form">
        <div className="steps-form-fields">
          <Content>
            <Row type="flex" justify="center">
              <Col xs={ 24 } sm={ 24 } md={ (props.vertical ? 24 : 16) } lg={ (props.vertical ? 24 : 16) }>
                <Card className="steps-form-box">
                  { props.steps.map((item, index) => 
                    <div key={ index } style={ { display: index === current ? 'block' : 'none' } }>
                      <item.content
                        index={ index }
                        current={ current }
                        status={ status }
                        data={ data }
                        initialValues={ props.initialValues }
                        onValidate={ onValidate }
                      />
                    </div>
                  ) }
                </Card>
                { status !== 'finish'
                  ? <div className="steps-form-actions">
                    <StepsFormButtons
                      numberOfSteps={ props.steps.length }
                      handleNext={ handleNext }
                      handlePrevious={ handlePrevious }
                      handleDone={ handleDone }
                      currentStep={ current }
                      disable={ status === 'wait' || status === 'finish' }
                      buttonNextLabel={ props.buttonNextLabel }
                      buttonPreviousLabel={ props.buttonPreviousLabel }
                      buttonDoneLabel={ props.buttonDoneLabel }
                      enablePrevious={ props.enablePrevious }
                    />
                  </div>
                  : '' }
              </Col>
            </Row>
          </Content>
        </div>
      </div>
    </div>
  );
}

export default StepsForm;
