import React from 'react';
import { Divider, Drawer, Tag, Alert, Descriptions } from 'antd';
import Parser from '../../../helpers/Parser';

const DescItem = Descriptions.Item;
 
const CargaDadorOfertaDetail = props => {
  return (
    <Drawer 
      title={ <span>Oferta <Tag color="cyan">{ props.oferta?.codigo_uvertrac }</Tag></span> }
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
        <DescItem label="Desde">{ props.viaje?.origen_direccion }</DescItem>
        <DescItem label="Hasta">{ props.viaje?.destino_direccion }</DescItem>
        <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
        <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
      </Descriptions>

      <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Oferta"><Tag color="cyan">{ props.oferta?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Cantidad de camiones ofertados">{ props.oferta?.cantidad_camiones }</DescItem>
        <DescItem label="Monto ofertado por camión">{ Parser.currency(props.oferta?.tarifa_viaje_completo) }</DescItem>
      </Descriptions>

      <Divider />

      <Descriptions column={1} bordered size="small" title="Información ingresada por Transportista">
        <DescItem label="Transportista">{ props.oferta?.transportista.razon_social}</DescItem>
        <DescItem label="Condición de pago">{ props.oferta?.condicion_de_pago }</DescItem>
        <DescItem label="Info pública">{ props.oferta?.informacion_adicional_publica }</DescItem>
        <DescItem label="Info privada">{ props.oferta?.informacion_adicional_privada }</DescItem>
      </Descriptions>
      
      <Divider />

      <Alert style={ { fontSize: 16} } type="success" message={ 
        <Descriptions column={1} size="small">
          <DescItem label="Total a Pagar"><b>{ Parser.currency(props.oferta?.tarifa_viaje_completo * props.oferta?.cantidad_camiones) }</b></DescItem>
        </Descriptions> } 
      />
    </Drawer>
  );
}

export default CargaDadorOfertaDetail;