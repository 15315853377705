import { HomeOutlined, ProfileOutlined, ScheduleOutlined, AliwangwangOutlined } from '@ant-design/icons';
import AuthHelper from '../../../helpers/AuthHelper';
import DashboardDadoresCargaPage from '../../dashboard-dadores-carga/DashboardDadoresCargaPage';
import PublicarViajePage from '../../publicar-viaje/PublicarViajePage';
import ViajesPublicadosDadoresCargaPage from '../../viajes-publicados-dadores-carga/ViajesPublicadosDadoresCargaPage';
import ViajesRealizadosDadoresCargaPage from '../../viajes-realizados-dadores-carga/ViajesRealizadosDadoresCargaPage';

const routesDadoresCarga = [
  {
    permission: "dc_dashboard_page.visualizar_left_menu",
    key: "inicio-dador-carga",
    breadcrumbName: "Inicio",
    path: "/inicio-dador-carga",
    menu: true,
    icon: {
      type: <HomeOutlined />,
      title: "Inicio"
    },
    component: AuthHelper.requireAuth(DashboardDadoresCargaPage)
  },
  {
    permission: "dc_publicar_viaje_page.visualizar_left_menu",
    key: "publicar_viaje",
    breadcrumbName: "Publicar Viaje",
    path: "/publicar_viaje",
    menu: true,
    icon: {
      type: <AliwangwangOutlined />,
      title: "Publicar Viaje"
    },
    component: AuthHelper.requireAuth(PublicarViajePage)
  },
  {
    permission: "dc_viajes_en_curso_page.visualizar_left_menu",
    key: "viajes_en_curso_dador",
    breadcrumbName: "Buscar Viajes",
    path: "/viajes-en-curso-dador",
    menu: true,
    icon: {
      type: <ProfileOutlined />,
      title: "Viajes Publicados"
    },
    component: AuthHelper.requireAuth(ViajesPublicadosDadoresCargaPage)
  },
  {
    permission: "dc_historial_page.visualizar_left_menu",
    key: "historial_viajes_dadores",
    breadcrumbName: "Viajes Realizados",
    path: "/historial-viajes-dadores",
    menu: true,
    icon: {
      type: <ScheduleOutlined />,
      title: "Viajes Realizados"
    },
    component: AuthHelper.requireAuth(ViajesRealizadosDadoresCargaPage)
  },
];

export default routesDadoresCarga;
