import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import UnidadTransporteForm from './components/UnidadTransporteForm';
import UnidadTransporteList from './components/UnidadTransporteList';

const { Content } = Layout;

const UnidadesTransportePage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ UnidadTransporteList } />
        <Route path={ `${props.match.path}/new` } component={ UnidadTransporteForm } />
        <Route path={ `${props.match.path}/edit/:id` } component={ UnidadTransporteForm } />
      </Switch>
    </Content>
  );
}

export default UnidadesTransportePage;