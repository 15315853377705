import React from 'react';
import { Divider, Drawer, Tag, Descriptions } from 'antd';
import Parser from '../../../../../helpers/Parser';

const DescItem = Descriptions.Item;
 
const CamionDetail = props => {
  return (
    <Drawer 
      title={ <span>Camión: <Tag color="cyan">{ props.viajeEnCurso?.unidad_transporte?.camion?.patente }</Tag></span> }
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
        <DescItem label="Desde">{ props.viaje?.origen_direccion }</DescItem>
        <DescItem label="Hasta">{ props.viaje?.destino_direccion }</DescItem>
        <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
        <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
      </Descriptions>
      
      <Divider />

      <Descriptions column={1} bordered size="small" title="Camión">
        <DescItem label="Patente"><Tag color="cyan"><b>{ props.viajeEnCurso?.unidad_transporte?.camion?.patente }</b></Tag></DescItem>
        <DescItem label="Vencimiento Ruta"><b>{ Parser.date(props.viajeEnCurso?.unidad_transporte?.camion?.fecha_de_vencimiento_ruta) }</b></DescItem>
        <DescItem label="Vencimiento Seguro"><b>{ Parser.date(props.viajeEnCurso?.unidad_transporte?.camion?.fecha_de_vencimiento_seguro) }</b></DescItem>
        <DescItem label="Vencimiento VTV"><b>{ Parser.date(props.viajeEnCurso?.unidad_transporte?.camion?.fecha_de_vencimiento_vtv) }</b></DescItem>
        <DescItem label="Tipo Chasis"><b>{ props.viajeEnCurso?.unidad_transporte?.camion?.tipo_chasi?.nombre }</b></DescItem>
      </Descriptions>

      <Divider />

      <Descriptions column={1} bordered size="small" title="Remolque">
        <DescItem label="Patente"><Tag color="cyan"><b>{ props.viajeEnCurso?.unidad_transporte?.remolque?.patente }</b></Tag></DescItem>
        <DescItem label="Vencimiento Seguro"><b>{ Parser.date(props.viajeEnCurso?.unidad_transporte?.remolque?.fecha_de_vencimiento_seguro) }</b></DescItem>
        <DescItem label="Vencimiento VTV"><b>{ Parser.date(props.viajeEnCurso?.unidad_transporte?.remolque?.fecha_de_vencimiento_vtv) }</b></DescItem>
        <DescItem label="Tipo Acoplado"><b>{ props.viajeEnCurso?.unidad_transporte?.remolque?.tipo_acoplado?.nombre }</b></DescItem>
      </Descriptions>
    </Drawer>
  );
}

export default CamionDetail;