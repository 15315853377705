import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, Table, Button, Space, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ConfiguracionesApi from '../../../api/configuracionesApi';
import AppNotification from '../../../components/AppNotification';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import PageTitle from '../../../components/PageTitle';
import Search from '../../../components/Search';

const ConfiguracionList = props => {
  const history = useHistory();
  const [configuraciones, setConfiguraciones] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadConfiguraciones();
  }, []);

  const columns = [
    { title: 'Parámetro', dataIndex: 'clave', width: '30%', },
    { title: 'Valor', dataIndex: 'valor', width: '20%', },
    { title: 'Descripción', dataIndex: 'descripcion', width: '50%', },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push(history.location.pathname + '/edit/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar la configuración?" onConfirm={ () => handleDelete(record.id) }/>
        </Space>
      }
    }
  ];

  const loadConfiguraciones = () => {
    setWorking(true);
    ConfiguracionesApi.get()
      .then(response => {
        setWorking(false);
        setConfiguraciones(response);
      })
      .catch(error => setWorking(false));
  }

  const handleDelete = id => {
    setWorking(true);
    ConfiguracionesApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Configuracion eliminada correctamente');
        loadConfiguraciones();
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Configuraciones" />
      <Card
        /*title={<Col span={12}>
          <Search 
            module="application"
            model="configuraciones"
            columnsSearch={ ['clave'] }
            columnsReturn={ columns }
            onChange={ response => setConfiguraciones(response) }
            onClear={ loadConfiguraciones }
          />
        </Col>}*/
        extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ configuraciones }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>
    </Fragment>
  );
}

export default ConfiguracionList;