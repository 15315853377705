import { Input ,DatePicker, Space, Select,Card,Divider,Row,Col,Button,InputNumber,Tooltip,Switch,Layout,AutoComplete} from 'antd';
import React, { useEffect, useState, Fragment } from 'react';
import UvertracApi from '../../../api/uvertracApi';
import moment from "moment"
import OfertasApi from '../../../api/ofertasApi';
import { useLocation } from  'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import UIHelper from '../../../helpers/UIHelper';
import TransportistasApi from '../../../api/transportistasApi';

const style = {  margin:'8px',minWidth: '140px' };
const { RangePicker } = DatePicker;
const { Option } = Select;
const AntSearch = Input.Search;

const SearchOfertas = props => {
  const [working, setWorking] = useState(false);
  const [estadoOferta, setEstadoOferta] = useState('');
  const [estadoViaje, setEstadoViaje] = useState('');

  const [fechaHasta, setFechaHasta] = useState('');
  const [fechaDesde, setFechaDesde] = useState('');
  const [estadoOfertas] = useState([
    {label: 'ACEPTADA', value: 'ACEPTADA' },
    {label: 'RECHAZADA', value: 'RECHAZADA' },
    {label: 'PUBLICADA', value: 'PUBLICADA' },
    {label: 'TODAS', value: null },
  ]);
  const [estadoViajes] = useState([
    {label: 'INICIADO', value: 'INICIADO' },
    {label: 'VIAJANDO', value: 'VIAJANDO' },
    {label: 'TERMINADO', value: 'TERMINADO' },
    {label: 'TODAS', value: null },

  ]);
  const [disabledEnCurso, setDisabledEnCurso] = useState(false);
  const [transportistaRazonSocial, setTransportistaRazonSocial] = useState('');
  const [transportistas, setTransportistas] = useState([]);
  const onDateSelection = value => {
    return value &&value <moment().endOf("day");
  }

  useEffect(() => {
    loadTransportistas();

  }, []);

  const loadTransportistas = () => {
    setWorking(true);
    TransportistasApi.getTransportistaViajes()
      .then(response => {
        setWorking(false);
        setTransportistas(response)
      })
      .catch(error => setWorking(false));
  }
  function handleChangeRazonSocial(value) {
    setTransportistaRazonSocial(value)
  }

  const onchangeFecha =(value,dateString )=>{
    let fecha_desde=''
    let fecha_hasta=''
    if ( dateString[0] != ''  && dateString[1] != ''){
      console.log('data x',dateString[0])
      fecha_desde= dateString[0]+' 00:00:00'
      fecha_hasta= dateString[1]+' 23:59:59'
    }
    console.log('fecha desde',fecha_desde)
    console.log('fecha hasta',fecha_hasta)
    setFechaDesde( fecha_desde);
    setFechaHasta( fecha_hasta);
  }

  function handleChangeEstadoOferta(value) {
    setEstadoOferta(value)
  }

  function handleChangeEstadoDelViaje(value) {
    setEstadoViaje(value)
  }
  
  const loadOfertas = () => {
    let working = true;
    props.working(working)
    OfertasApi.searchOfertas(fechaDesde ,fechaHasta,estadoOferta,transportistaRazonSocial,
                            estadoViaje)
    .then(response => {
          let working = false;
          props.onChange(response)
          console.log(response)
          props.working(working)

        })
      .catch(error => setWorking(false));
  }
  
  const resetOfertas =()=>{
     // props.resetBusqueda()
      window.location.reload(false);
  }

  return (
      <>
      <Fragment>
       <Divider orientation="center">Ofertas</Divider>
        <Row gutter={[16, 24]}>
           <Col className="gutter-row" span={5}>
            <div style={style}>
              <Select
                showSearch
                placeholder="Razon social"
                optionFilterProp="children"
                onChange={handleChangeRazonSocial}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{
                  width: '100%',
                  
                  }}
              > 
                { transportistas.map(tipo => <Option key={tipo.razon_social} value={tipo.id}>{ tipo.razon_social }</Option>)}
              </Select>
            </div>
          </Col>
          
          <Col className="gutter-row" span={5}>
            <div style={style}>
              <RangePicker
              ranges={{
                    Hoy: [moment().startOf('date'), moment().endOf('date')],
                    'Mes': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  //showTime
                  format="YYYY/MM/DD"
              onChange={onchangeFecha}
              disableDate={onDateSelection}
              style={{
                  width: '100%',
                  display: 'flex',
                }}
              />
              </div>
          </Col>
          <Col className="gutter-row" span={3}>
            <div style={style}>
              <Button   type="primary" icon={<SearchOutlined />} style={{ width: '100%'}} size="medium" onClick={()=>loadOfertas()} >
                  Resultados
              </Button>
              </div>
          </Col>
         
        </Row>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={5}>
            <div style={style}>
              <Select
                showSearch
                placeholder="Estado de la oferta"
                optionFilterProp="children"
                onChange={handleChangeEstadoOferta}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{
                  width: '100%',
                  
                  }}
              > 
                { estadoOfertas.map(tipo => <Option key={tipo.value} value={tipo.value}>{ tipo.label }</Option>)}
              </Select>
            </div>
          </Col>
          <Col className="gutter-row" span={5}>
            <div style={style}>
              <Select
                showSearch
                placeholder="Estado del viaje"
                optionFilterProp="children"
                onChange={handleChangeEstadoDelViaje}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{
                  width: '100%',
                  }}
              >
                { estadoViajes.map(tipo => <Option key={tipo.value} value={tipo.value}>{ tipo.label }</Option>)}
              </Select></div>
          </Col>
          <Col span={3}>
            <div style={style}>
                  <Button type="primary" htmlType="submit" style={{ width: '100%'}} onClick={()=>resetOfertas()}>Borrar Busqueda</Button>
              </div>
          </Col>
        </Row>
        </Fragment>
</>
      );
}

export default SearchOfertas;