import jwtDecode from 'jwt-decode';
import { Redirect } from 'react-router';

export default class AuthHelper {
  static isJwtTokenStored = () => {
    return sessionStorage.jwtToken ? true : false;
  }

  static getJwtToken = () => {
    return (this.isJwtTokenStored()) ? sessionStorage.jwtToken : null;
  }

  static storeJwtToken = jwtToken => {
    if (jwtToken.indexOf('Bearer ') >= 0) {
      jwtToken = jwtToken.replace('Bearer ', '');
    }
    sessionStorage.setItem('jwtToken', jwtToken);
    return null;
  }

  static removeJwtToken = () => {
    sessionStorage.removeItem('jwtToken');
    return null;
  }

  static hasJwtPayloadKey = key => {
    if (this.isJwtTokenStored()) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key] !== undefined;
    }
    return null;
  }

  static getValueFromJwtPayload = key => {
    if (this.isJwtTokenStored()) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key];
    }
    return null;
  }

  static getDecodedToken = () => {
    return (this.isJwtTokenStored()) ? jwtDecode(sessionStorage.jwtToken) : null;
  }

  static requireAuth = Component => {
    const AuthenticatedComponent = props => {
      let result = null;
      let isAuthorized = AuthHelper.isJwtTokenStored();

      if (isAuthorized) {
        result = (<Component { ...props } />);
      }
      else {
        const location = props.location;
        const redirect = location.pathname + location.search;
        
        result = (<Redirect push to={ `/login?redirect=${redirect}` } />);
      }
      
      return result;
    }
    return AuthenticatedComponent;
  }
}