import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useHistory, useLocation } from 'react-router';
import UserConfirmationComponent from './UserConfirmationComponent';
import bgImage from '../assets/lannd-bg.png'
import logoImage from '../assets/uvertracLogo2.gif';
import AuthApi from '../../../api/authApi';
import {
  useParams
} from "react-router-dom";


const UserConfirmationPage = () => {
  const [_ , setWorking] = useState(false);
  const [userData, setUserData] = useState({});
  const { confirmation_hash } = useParams();
  useEffect(() => {
    const getUserData = async () => {
      setWorking(true);
      const ud = await AuthApi.getUserData(confirmation_hash);
      setWorking(false);
      setUserData(ud);
    }
    getUserData();
  }, []);

  
  return (
    <Layout style={ { height: '100vh', background: 'url(' + bgImage + ') center no-repeat', backgroundSize: 'cover'} }>
      <UserConfirmationComponent
        title={ 'UverTrac' }
        logo={ logoImage }
        nombre={userData.nombre}
        tipo={userData.tipo}
        email={userData.email}
        razon_social={userData.razon_social}
        token={confirmation_hash}
      />
    </Layout>
  );
}

export default UserConfirmationPage;
