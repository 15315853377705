import React from 'react';
import { Divider, Drawer, Tag, Descriptions } from 'antd';
import Parser from '../../../../../helpers/Parser';

const DescItem = Descriptions.Item;
 
const ChoferDetail = props => {
  return (
    <Drawer 
      title={ <span>Chofer: <Tag color="cyan">{ props.viajeEnCurso?.unidad_transporte?.chofer?.razon_social }</Tag></span> }
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
        <DescItem label="Desde">{ props.viaje?.origen_direccion }</DescItem>
        <DescItem label="Hasta">{ props.viaje?.destino_direccion }</DescItem>
        <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
        <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
      </Descriptions>
      
      <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Razón Social"><Tag color="cyan"><b>{ props.viajeEnCurso?.unidad_transporte?.chofer?.razon_social }</b></Tag></DescItem>
        <DescItem label="Cuit"><b>{ props.viajeEnCurso?.unidad_transporte?.chofer?.cuit }</b></DescItem>
        <DescItem label="Vencimiento de licencia"><b>{ Parser.date(props.viajeEnCurso?.unidad_transporte?.chofer?.fecha_de_vencimiento_licencia) }</b></DescItem>
      </Descriptions>
    </Drawer>
  );
}

export default ChoferDetail;