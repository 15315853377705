import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import UvertracApi from '../../api/uvertracApi';

const AntSearch = Input.Search;

const SearchDinamic = props => {
  const [searchType, setSearchType] = useState('ILIKE');
  const [columnsReturn, setColumnsReturn] = useState([]);
  const [columnsSearch, setColumnsSearch] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setColumnsSearch(props.columnsSearch.map(col =>`${col}::${searchType}`));
    if (props.columnsReturn?.length > 0){
      if (typeof props.columnsReturn[0] === 'string'){
        setColumnsReturn([...props.columnsReturn]);
      }
      if (typeof props.columnsReturn[0] === "object") {
        setColumnsReturn(props.columnsReturn.filter(x => x.dataIndex !== undefined).map(a => a.dataIndex));
      }
    }
  }, []);

  const onChange = e => {
    let value = e.target.value
    setWorking(true);
    let data = {
      module: props.module,
      model: props.model,
      textToSearch: '%' + value + '%',
      colReturn: columnsReturn.join(),
      colSearch: columnsSearch.join(),
      colOrderBy: props.orderBy,
      relationships: props.relationships 
    }

    UvertracApi.get(data)
      .then(response => {
        setWorking(false);
        props.onChange(response.data)
      })
      .catch(error => setWorking(false));
  }

  return <AntSearch
    { ...props }
    enterButton
    allowClear
    placeholder={ `Buscar por: ${props.columnsSearch.join(', ').replace(/_/gi, " ")}` }
    onChange={onChange }
    loading={ working }
  />
}

export default SearchDinamic;



