import React from 'react';
import { ConfigProvider, Spin } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import es from 'antd/lib/locale-provider/es_ES';
import Layout from './pages/layout';
import moment from 'moment-timezone';
import { momentConfig } from './settings';
import Spinner from './components/Spinner/Spinner';

moment.locale(momentConfig.locale);
moment.tz.setDefault(momentConfig.tz);

Spin.setDefaultIndicator(<Spinner />);

const App = () => (
  <Router>
    <ConfigProvider locale={ es }>
      <Layout className="app-uvertrac" />
    </ConfigProvider>
  </Router>
);

export default App;
