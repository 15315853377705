import React, { useEffect, useRef, useState } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow } from 'react-google-maps';

const defaultCenter = { lat: -26.8169119, lng: -65.2007883 };
const defaultMapOptions = {
  panControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  overviewMapControl: false,
  streetViewControl: false,
  rotateControl: true,
  //zoomControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
}

const MapItem = withGoogleMap(props => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <GoogleMap
      defaultCenter={ defaultCenter } 
      defaultZoom={ 17 }
      defaultOptions={ defaultMapOptions }
      onDblClick={ props.handleMapDoubleClick }
      center={ props.center ? props.center : defaultCenter }
    >
      <Marker
        key={ props.marker?.key }
        position={ props.marker?.position }
        onClick={ () => setShowInfo(true) }
      >
        { showInfo
          ? <InfoWindow options={ { maxWidth: 200 } } onCloseClick={ () => setShowInfo(false) }>
              <span>{ props.marker?.address }</span>
            </InfoWindow>
          : '' }
      </Marker>
    </GoogleMap>
  )
});

export default MapItem;