import { Fragment, useEffect, useState } from "react";
import { Col } from "antd";
import { RocketOutlined, CheckSquareOutlined, CarOutlined, TeamOutlined, ApiOutlined } from '@ant-design/icons';
import NumberCardLines from "../../../components/NumberCard/NumberCardLines";

const DashboardTotales = props => {
  const [publicados, setPublicados] = useState([]);
  const [conOfertas, setConOfertas] = useState([]);
  const [conViajes, setConViajes] = useState([]);

  useEffect(() => {
    setPublicados(props.dashboardInfo?.viajes_publicados?.map(x => x.codigo_uvertrac));
    setConOfertas(props.dashboardInfo?.viajes_publicados?.filter(x => x.oferta?.length > 0).map(x => x.codigo_uvertrac));
    setConViajes(props.dashboardInfo?.viajes_publicados?.filter(x => x.viajes_en_curso?.length > 0).map(x => x.codigo_uvertrac));
  }, [props.dashboardInfo]);

  return (
    <Fragment>
      <Col span={ 8 }>
        <NumberCardLines 
          number={ publicados?.length } 
          data={ publicados }
          icon={ <RocketOutlined className="iconWarp" style={ { backgroundColor : '#8FC2D9' } } /> }
          color="#8FC2D9" 
          title="Viajes publicados"
        />
      </Col>
      <Col span={ 8 }>
        <NumberCardLines 
          number={ conOfertas?.length } 
          data={ conOfertas } 
          icon={ <CheckSquareOutlined className="iconWarp" style={ { backgroundColor : '#4df293' } }/> } 
          color="#4df293" 
          title="Viajes con Oferta"
        />
      </Col>
      <Col span={ 8 }>
        <NumberCardLines 
          number={ conViajes?.length } 
          data={ conViajes }
          icon={ <CarOutlined className="iconWarp" style={ { backgroundColor : '#f8c82e' } }/> } 
          color="#f8c82e" 
          title="Viajes en curso"
        />
      </Col>
    </Fragment>
  );
}

export default DashboardTotales;