import React, { Component } from 'react';
import { Row, Col, Descriptions } from 'antd';
import { CloseOutlined } from '@ant-design/icons'
import truck from './assets/truck.png';
import "./pin.scss";

const DescItem = Descriptions.Item;

const Pin = props => {
  return (
    <div className="map-pin">
      <div className="tooltip">
        <span className="tooltiptext">{ props.text }</span>
        <img alt="ico" src={ props.icon ? props.icon : truck } />
      </div>
      { props.showInfo
        ? <div className="info-box">
          <Row style={ { float: 'right', marginBottom: 4 } }>
            <CloseOutlined type="close" onClick={ props.hideInfo }/>
          </Row>
          <Descriptions bordered size="small" column={1}>
            { props.info?.map(info => 
              <DescItem label={ info.key } 
                labelStyle={ { padding: 4, fontSize: 11 } }
                contentStyle={ { padding: 4, fontSize: 11 } }
              >{ info.value }</DescItem>) }
          </Descriptions>
        </div>
        : '' }
    </div>
  );
}

export default Pin;
