import React, { Component, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Pin from './pin';
import { mapsConfig } from '../../settings';

const Map = props => {
  const [selectedMarker, setSelectedMarker] = useState(-1);

  const addMarker = marker => {
    return (<Pin 
      key={ marker.id }
      id={ marker.id }
      lat={ marker.lat } 
      lng={ marker.lng } 
      text={ marker.text }
      info={ marker.info }
      showInfo={ selectedMarker == marker.id && marker.info }
      hideInfo={ () => setSelectedMarker(-1) }
    />);
  }

  const onChildClick = (key, childProps) => {
    setSelectedMarker(selectedMarker != childProps.id ? childProps.id : -1);
  }

  const apiIsLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();
    directionsDisplay.setMap(map);
    directionsService.route({
      origin: new maps.LatLng(props.routes.origin.lat, props.routes.origin.lng),
      destination: new maps.LatLng(props.routes.destination.lat, props.routes.destination.lng),
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    });
  };
    
  let gmapProps = {};
  if (props.routes) {
    gmapProps = {
      defaultCenter: {
        lat: Number(props.routes.origin.lat), 
        lng: Number(props.routes.origin.lng)
      },
      defaultZoom: 15,
      yesIWantToUseGoogleMapApiInternals: true,
      onGoogleApiLoaded: ({ map, maps }) => apiIsLoaded(map, maps),
      options: { scrollwheel: false, }
    };
  } else {
    gmapProps = {
      defaultCenter: {
        lat: props.defaultLat ? props.defaultLat : -31.3992876, 
        lng: props.defaultLng ? props.defaultLng : -64.2643838
      },
      defaultZoom: props.defaultZoom ? props.defaultZoom : 6,
      onChildClick: onChildClick,
      onClick: () => setSelectedMarker(-1),
      options: { scrollwheel: false, }
    };
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={ { key: mapsConfig.gmapsKey } }
      { ...gmapProps }
    >
      { props.markers?.map(marker => addMarker(marker)) }
    </GoogleMapReact>
  );
}

export default Map;
