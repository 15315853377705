import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Tag, Col, Space, Tooltip, Button } from 'antd';
import PageTitle from '../../../../components/PageTitle';
import VerButton from '../../../../components/Buttons/VerButton';
import ViajeDetail from './components/ViajeDetail';
import ViajesApi from '../../../../api/viajesApi';
import RealizarOferta from './components/RealizarOferta';
import ViajeEstadoTag from '../../../../components/Tags/ViajeEstadoTag';
import SearchDinamic from '../../../../components/Search/Search-Dinamic';

const ViajePublicadoList = props => {
  const [viajes, setViajes] = useState([]);
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);
  const [showRealizarOferta, setShowRealizarOferta] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadViajes();
  }, []);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Titulo', dataIndex: 'titulo', key: 'titulo' },
    { title: 'Camiones Pedidos', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center' },
      { title: 'Saldo de camiones', dataIndex: 'saldo_camiones', key: 'saldo_camiones', align: 'center',
        render: (_, record) => <span>{ calcSaldo(record) }</span>
      },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <ViajeEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          { calcSaldo(record) === 0
            ? <Tooltip title="El total de camiones requeridos fue alcanzado">
              <Tag color="#f50" size="small" style={ { marginRight: 0 } }>No se puede Ofertar</Tag>
            </Tooltip>
            : <Tooltip title="Realizar Oferta">
              <Button type="primary" size="small" onClick={ () => { setSelectedViaje(record); setShowRealizarOferta(true); } }>+ Ofertar</Button>
            </Tooltip> }
          <VerButton onClick={ () => { setSelectedViaje(record); setShowViajeDetail(true); } }/>
        </Space>
      }
    }
  ];

  const calcSaldo = record => {
    let saldo = record.cantidad_camiones;
    record.oferta.map(oferta =>{
      if(oferta.estado == 'ACEPTADA'){
        saldo -= oferta.cantidad_camiones;
      }
    });
    return saldo;
  }

  const loadViajes = () => {
    setWorking(true);
    ViajesApi.getViajesPublicadosTransportista()
      .then(response => {
        setWorking(false);
        setViajes(response);
      })
      .catch(error => setWorking(false));
  }

  return (
    <Fragment>
      <PageTitle title="Viajes publicados"/>
      <Card
        title={<Col span={12}>
          <SearchDinamic 
            module="uvertraccore"
            model="publicacionviaje"
            columnsSearch={ ["codigo_uvertrac", "titulo", "estado"] }
            columnsReturn={ ["*"] }
            orderBy="codigo_uvertrac"
            relationships={ "oferta,oferta.viajes_en_curso" }
            onchange={ response => setViajes(response) }
            onClear={ loadViajes }
          />
        </Col>}
        /*extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}*/
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />

        <ViajeDetail
          viaje={ selectedViaje }
          visible={ showViajeDetail }
          onClose={ () => setShowViajeDetail(false) }
        />

        <RealizarOferta
          viaje={ selectedViaje }
          visible={ showRealizarOferta }
          onClose={ () => setShowRealizarOferta(false) }
          loadViajes={ loadViajes }
        />
      </Card>
    </Fragment>
  );
}

export default ViajePublicadoList;