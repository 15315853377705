import React, { useEffect, useState } from "react";
import { Button, Card, Col, Space, Table, Tooltip, Popconfirm } from "antd";
import { FileDoneOutlined } from '@ant-design/icons';
import Search from '../../../../../components/Search';
import ViajeDetail from "./ViajeDetail";
import ViajeEstadoTag from "../../../../../components/Tags/ViajeEstadoTag";
import Parser from "../../../../../helpers/Parser";
import VerButton from "../../../../../components/Buttons/VerButton";
import AppNotification from "../../../../../components/AppNotification";
import { Fragment } from "react";
import Consts from "../../../../../helpers/Consts";

const ViajesList = props => {
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);
  const [working, setWorking] = useState(false);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Transportista', dataIndex: ['unidad_transporte', 'transportista', 'razon_social'], key: 'transportista', align: 'center' },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <ViajeEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedViaje(record); setShowViajeDetail(true); } }/>
          { record.estado != Consts.EstadoViaje.FACTURADO
            ? <Popconfirm
              placement="topRight"
              title="¿Está seguro de querer facturar el viaje?"
              onConfirm={ () => handleFacturarViaje(record.id) }
              okText="Facturar"
              cancelText="No"
            >
              <Tooltip title="Facturar Viaje" placement="topRight">
                <Button shape="circle" icon={ <FileDoneOutlined /> } size="small"/>
              </Tooltip>
            </Popconfirm>
            : '' }
        </Space>
      }
    }
  ];

  const handleFacturarViaje = id => {
    setWorking(true);
    props.facturarViaje(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Viaje facturado correctamente');
        props.loadViajes();
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <Card title="Viajes" size="small">
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ props.viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>

      <ViajeDetail
        viaje={ selectedViaje }
        visible={ showViajeDetail }
        onClose={ () => setShowViajeDetail(false) }
      />
    </Fragment>
  );
}

export default ViajesList;