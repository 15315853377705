import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Card, Tag, Button, Input, Col, Space, Tooltip } from 'antd';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import ChoferesApi from '../../../api/choferesApi';
import PageTitle from '../../../components/PageTitle';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import AppNotification from '../../../components/AppNotification';
import Parser from '../../../helpers/Parser';
import moment from 'moment';
import SearchDinamic from '../../../components/Search/Search-Dinamic';

const ChoferList = props => {
  const history = useHistory();
  const [choferes, setChoferes] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadChoferes();
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'razon_social', width: '20%', },
    { title: 'Cuit', dataIndex: 'cuit', width: '20%', },
    { title: 'Teléfono celular', dataIndex: 'telefono_celular', width: '20%', }, 
    { title: 'Vencimiento de licencia', dataIndex: 'fecha_de_vencimiento_licencia', width: '20%',
      render: text => moment().diff(moment(text, 'YYYY-MM-DD')) > 0 ? <Tag color="red">{ Parser.date(text) }</Tag> : Parser.date(text)
    }, 
    { title: 'Estado documentación', dataIndex: 'fecha_de_vencimiento_licencia', width: '20%', align: 'center',
      render: (text, record) => {
        let docSinAprobar = record.media.filter(x => !x.custom_properties?.aprobada_at);
        return docSinAprobar.length > 0 
          ? <Tag color="red"><div style={{display: 'flex', flexDirection: 'column'}}><span>Falta aprobar:</span>{ docSinAprobar.map(doc => <span>{ doc.custom_properties?.imagen }</span>) }</div></Tag> 
          : <Tag color="green">OK</Tag>
      }
    }, 
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push(history.location.pathname + '/edit/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar el chofer?" onConfirm={ () => handleDelete(record.id) }/>
        </Space>
      }
    }
  ];

  const loadChoferes = () => {
    setWorking(true);
    ChoferesApi.get()
      .then(response => {
        setWorking(false);
        setChoferes(response);
      })
      .catch(error => setWorking(false));
  }
  
  const handleDelete = id => {
    setWorking(true);
    ChoferesApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Chofer eliminado correctamente');
        loadChoferes();
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Choferes"/>
      <Card
        title={<Col span={12}>
          <SearchDinamic 
            module="uvertraccore"
            model="chofer"
            columnsSearch={ ['razon_social', 'cuit'] }
            columnsReturn={ columns }
            orderBy="id"
            onChange={ response => setChoferes(response) }
            onClear={ loadChoferes }
          />
        </Col>}
        extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ choferes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          //onRow={ record => ({ onClick: () => { setSelectedRow(record.id); }}) }
        />
      </Card>
    </Fragment>
  );
}

export default ChoferList;
