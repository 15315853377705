export default class ConfigHelper {

  static storeParameters = configurationParameters => {
    if (configurationParameters) {
      let mappedConfigurationParameters = {};
      configurationParameters.forEach(parameterObj => {
        mappedConfigurationParameters[`${parameterObj.clave}`] = parameterObj;
      });
      sessionStorage.setItem('configurationParameters', JSON.stringify(mappedConfigurationParameters));
    }
    else {
      sessionStorage.removeItem('configurationParameters');
    }
  }

  static removeParameters = () => {
    sessionStorage.removeItem('configurationParameters');
  }

  static getParameters = () => {
    return JSON.parse(sessionStorage.getItem('configurationParameters'));
  }

  static getParameter = parameterKey => {
    const configurationParameters = this.getParameters();
    let result = null;

    if (configurationParameters) {
      result = configurationParameters[parameterKey];
    }

    return result;
  }

  static getParameterValue = parameterKey => {
    const parameterObj = this.getParameter(parameterKey);
    let result = null;

    if (parameterObj) {
      result = parameterObj.valor;
    }

    return result;
  }
}