import Fetcher from "../helpers/Fetcher";

const ChoferesApi = {
  get: id => {
    let url = 'uvertraccore/choferes/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'uvertraccore/choferes/';
    if(!data.id){
      return Fetcher
        .post(url, data)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
      .put(url + data.id, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('uvertraccore/choferes/' + id)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  updateLicencia: (choferId, data) => {
    return Fetcher
      .postMultipart('uvertraccore/choferes/actualizar-imagen/licencia_de_conducir/' + choferId, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
}

export default ChoferesApi;