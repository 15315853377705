import React, { useEffect, useState } from 'react';
import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import basicStyle from '../../../../../themes/basicStyle';
import UIHelper from '../../../../../helpers/UIHelper';
import UvertracApi from '../../../../../api/uvertracApi';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const DatosPersonalesStep = props => {
  const [tiposCarga] = useState(['GRANEL', 'ANIMALES', 'CARGAS_GENERALES', 'CONTENEDORES', 'PALLETIZADOS', 'MAQUINARIAS']);
  const [tiposProducto, setTiposProducto] = useState([]);
  const [tiposProductoSeleccionado, setTiposProductoSeleccionado] = useState([]);
  const [form] = Form.useForm();
  

  useEffect(() => {
    loadTiposProducto();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [props.initialValues]);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      form.validateFields()
        .then(values => {
          const data = {
            ...values,
            producto: tiposProducto.find(x => x.id === values.tipo_producto_id).nombre
          }
          props.onValidate(true, data);
        })
        .catch(error => props.onValidate(false, {}));
    }
  }, [props.current, props.status]);
  
  const loadTiposProducto = () => UvertracApi.get({ model: 'TipoProducto', colReturn: ['id','nombre','metodo_carga'], colOrderBy: 'nombre' }).then(response => setTiposProducto(response.data));
  
  const handleProducto = function(e){
    const data = e.target.outerText;
    const productoSeleccionado = tiposProducto.filter(producto => producto.metodo_carga.includes(data))
    setTiposProductoSeleccionado(productoSeleccionado)
  }
  

  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      initialValues={ { ...props.initialValues } }
      scrollToFirstError
    >
      <FormItem name="tipo_carga" label="Tipo de Carga" hasFeedback rules={ UIHelper.defaultRules } autoComplete="off"   >
        <Select showSearch filterOption={ UIHelper.defaultSelectFilter } onClick={handleProducto}  >
          { tiposCarga.map(tipo => (<Option key={ tipo } value={ tipo }>{ tipo }</Option>)) }
        </Select>
      </FormItem>
      <FormItem name="tipo_producto_id" label="Producto" hasFeedback rules={ UIHelper.defaultRules } autoComplete="off">
        <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
          { tiposProductoSeleccionado.map(tipo => (<Option key={ tipo.id } value={ tipo.id }>{ tipo.nombre }</Option>)) }
        </Select>
      </FormItem>
      <FormItem name="cantidad_camiones" label="Cantidad de Camiones" hasFeedback rules={ UIHelper.defaultRules } autoComplete="off">
        <InputNumber style={ { width: '100%' } } min={1}/>
      </FormItem>
      <FormItem name="informacion_adicional_publica" label="Información Adicional Pública">
        <TextArea rows={4}/>
      </FormItem>
      <FormItem name="informacion_adicional_privada" label="Información Adicional Privada">
        <TextArea rows={4}/>
      </FormItem>
    </Form>
  );
}

export default DatosPersonalesStep;