import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import CamionForm from './components/form/CamionForm';
import CamionList from './components/CamionList';

const { Content } = Layout;

const CamionesPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ CamionList } />
        <Route path={ `${props.match.path}/new` } component={ CamionForm } />
        <Route path={ `${props.match.path}/edit/:id` } component={ CamionForm } />
      </Switch>
    </Content>
  );
}

export default CamionesPage;