import Fetcher from "../helpers/Fetcher";

const UsuariosApi = {
  getDashboard: () => {
    return Fetcher
      .get('uvertraccore/dashboard-usuario')
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  get: id => {
    let url = 'authentication/users/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'authentication/users/';
    if(!data.id){
      return Fetcher
        .post(url, data)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
      .put(url + data.id, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
    }
  },
  delete: userId => {
    return Fetcher
      .delete('authentication/users/' + userId)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  enable_disable: (id, enable) => {
    if(enable == true){
      return Fetcher
        .post('authentication/users/habilitar/' + id)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
        .post('authentication/users/deshabilitar/' + id)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
  },
  aprobarImagen: (entidad, tipo, id) => {
    return Fetcher
      .put('uvertraccore/' + entidad + '/aprobar-imagen/' + tipo + '/' + id)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  clearMacAddress: id => {
    return Fetcher
      .post('authentication/users/clear-mac-address/' + id)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
}

export default UsuariosApi;