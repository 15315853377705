import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import ParametricasList from './components/ParametricasList';
import ProvinciaList from './components/provincias/ProvinciaList';
import ProvinciaForm from './components/provincias/ProvinciaForm';
import DepartamentoList from './components/departamentos/DepartamentoList';
import DepartamentoForm from './components/departamentos/DepartamentoForm';
import LocalidadList from './components/localidades/LocalidadList';
import LocalidadForm from './components/localidades/LocalidadForm';
import MarcaList from './components/marcas/MarcaList';
import MarcaForm from './components/marcas/MarcaForm';
import RegionList from './components/regiones/RegionList';
import RegionForm from './components/regiones/RegionForm';
import TipoDeAcopladoForm from './components/tipos-de-acoplados/TipoDeAcopladoForm';
import TipoDeAcopladoList from './components/tipos-de-acoplados/TipoDeAcopladoList';
import TipoDeChasisList from './components/tipos-de-chasis/TipoDeChasisList';
import TipoDeChasisForm from './components/tipos-de-chasis/TipoDeChasisForm';
import TipoDeIvaList from './components/tipo-de-iva/TipoDeIvaList';
import TipoDeIvaForm from './components/tipo-de-iva/TipoDeIvaForm';
import TipoDeProductoForm from './components/tipos-de-producto/TipoDeProductoForm';
import TipoDeProductoList from './components/tipos-de-producto/TipoDeProductoList';

const { Content } = Layout;

const ParametricasPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path }/` } component={ ParametricasList } />

        <Route exact path={ `${props.match.path }/provincias` } component={ ProvinciaList } />
        <Route path={ `${props.match.path }/provincias/view/:id` } render={ props => <ProvinciaForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/provincias/new` } render={ props => <ProvinciaForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/provincias/edit/:id` } render={ props => <ProvinciaForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/departamentos_de_provincias/:provId?` } component={ DepartamentoList } />
        <Route path={ `${props.match.path }/departamentos_de_provincias/view/:provId/:id` } render={ props => <DepartamentoForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/departamentos_de_provincias/new/:provId` } render={ props => <DepartamentoForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/departamentos_de_provincias/edit/:provId/:id` } render={ props => <DepartamentoForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/localidades_de_departamento/:provId?/:dptoId?` } component={ LocalidadList } />
        <Route path={ `${props.match.path }/localidades_de_departamento/view/:provId/:dptoId/:id` } render={ props => < LocalidadForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/localidades_de_departamento/new/:provId/:dptoId` } render={ props => < LocalidadForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/localidades_de_departamento/edit/:provId/:dptoId/:id` } render={ props => < LocalidadForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/marcas_de_vehiculo` } component={ MarcaList } />
        <Route path={ `${props.match.path }/marcas_de_vehiculo/view/:id` } render={ props => <MarcaForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/marcas_de_vehiculo/new` } render={ props => <MarcaForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/marcas_de_vehiculo/edit/:id` } render={ props => <MarcaForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/regiones` } component={ RegionList } />
        <Route path={ `${props.match.path }/regiones/view/:id` } render={ props => <RegionForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/regiones/new` } render={ props => <RegionForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/regiones/edit/:id` } render={ props => <RegionForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/tipos_de_acoplado` } component={ TipoDeAcopladoList } />
        <Route path={ `${props.match.path }/tipos_de_acoplado/view/:id` } render={ props => <TipoDeAcopladoForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_acoplado/new` } render={ props => <TipoDeAcopladoForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_acoplado/edit/:id` } render={ props => <TipoDeAcopladoForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/tipos_de_chasis` } component={ TipoDeChasisList } />
        <Route path={ `${props.match.path }/tipos_de_chasis/view/:id` } render={ props => <TipoDeChasisForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_chasis/new` } render={ props => <TipoDeChasisForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_chasis/edit/:id` } render={ props => <TipoDeChasisForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/tipos_de_iva` } component={ TipoDeIvaList } />
        <Route path={ `${props.match.path }/tipos_de_iva/view/:id` } render={ props => <TipoDeIvaForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_iva/new` } render={ props => <TipoDeIvaForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_iva/edit/:id` } render={ props => <TipoDeIvaForm view={ false } { ...props } /> } />

        <Route exact path={ `${props.match.path }/tipos_de_producto` } component={ TipoDeProductoList  } />
        <Route path={ `${props.match.path }/tipos_de_producto/view/:id` } render={ props => <TipoDeProductoForm view={ true } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_producto/new` } render={ props => <TipoDeProductoForm view={ false } { ...props } /> } />
        <Route path={ `${props.match.path }/tipos_de_producto/edit/:id` } render={ props => <TipoDeProductoForm view={ false } { ...props } /> } />*/}
      </Switch>
    </Content>
  );
}

export default ParametricasPage;
