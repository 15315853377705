import React from 'react';
import { Alert, Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import basicStyle from '../../../../../themes/basicStyle';
import UIHelper from '../../../../../helpers/UIHelper';
import ImageFormItem from '../../../../../components/FormItems/ImageFormItem';
import moment from 'moment';

const FormItem = Form.Item;

const RemolqueEditForm = props => {
  const [form] = Form.useForm();

  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      onFinish={ props.onFinish }
      initialValues={ { ...props.remolque,
        fecha_de_vencimiento_seguro: props.remolque?.fecha_de_vencimiento_seguro ? moment(props.remolque.fecha_de_vencimiento_seguro) : null,
        fecha_de_vencimiento_vtv: props.remolque?.fecha_de_vencimiento_vtv ? moment(props.remolque.fecha_de_vencimiento_vtv) : null,
        cedula_verde: props.remolque?.entityMedia?.cedula_verde ? [props.remolque?.entityMedia?.cedula_verde] : [],
        seguro: props.remolque?.entityMedia?.seguro ? [props.remolque?.entityMedia?.seguro] : [],
        vtv: props.remolque?.entityMedia?.vtv ? [props.remolque?.entityMedia?.vtv] : []
      } }
      scrollToFirstError
    >
      <Row gutter={ [16,0] }>
        <Col span={ 24 }>
          <FormItem name="patente" label="Patente" rules={ UIHelper.defaultRules }
            normalize={ (value, prevValue) => value !== '' ? (/^[\w]+$/.test(value.toUpperCase()) ? value.toUpperCase() : prevValue) : value }
          >
            <Input maxLength={7}/>
          </FormItem>
          <FormItem name="fecha_de_vencimiento_seguro" label="Fecha de vencimiento del seguro" rules={ UIHelper.defaultRules }>
            <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
              disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
            />
          </FormItem>
          <FormItem name="fecha_de_vencimiento_vtv" label="Fecha de vencimiento de VTV" rules={ UIHelper.defaultRules }>
            <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
              disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
            />
          </FormItem>
          <ImageFormItem form={ form } name="cedula_verde" label="Cédula Verde"
            extra={ props.remolque?.entityMedia?.cedula_verde?.aprobada === false 
              ? <Alert type="warning" message={ <small>La imagen no fue aprobada todavia</small> } style={ { padding: '4px 8px' } }/>
              : '' }
          />
          <ImageFormItem form={ form } name="seguro" label="Seguro"
            extra={ props.remolque?.entityMedia?.seguro?.aprobada === false 
              ? <Alert type="warning" message={ <small>La imagen no fue aprobada todavia</small> } style={ { padding: '4px 8px' } }/>
              : '' }
          />
          <ImageFormItem form={ form } name="vtv" label="VTV"
            extra={ props.remolque?.entityMedia?.vtv?.aprobada === false 
              ? <Alert type="warning" message={ <small>La imagen no fue aprobada todavia</small> } style={ { padding: '4px 8px' } }/>
              : '' }
          />
        </Col>
        
      </Row>

      <Row justify="end">
        <FormItem>
          <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
        </FormItem>
      </Row>
    </Form>
  );
}

export default RemolqueEditForm;