import React, { useEffect, useState } from 'react';
import { Calendar, Card, Col, Form, Row, TimePicker, Select, Alert } from 'antd';
import basicStyle from '../../../../../themes/basicStyle';
import UIHelper from '../../../../../helpers/UIHelper';
import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select

const disabledMinutes = () => [...Array(60).keys()].filter(item => ![0, 15, 30, 45].includes(item));

const RetiroEntregaStep = props => {
  const [monthOptions] = useState(
    Array.apply(0, Array(12)).map((item, idx) => <Option className="month-item" key={ idx }>{ moment().localeData().monthsShort(moment().month(idx)) }</Option>)
  );
  const [yearOptions] = useState(
    [moment().year(), moment().year() + 1].map((item, idx) => <Option key={ idx } value={ item } className="year-item">{ item }</Option>)
  );
  const [selectedFechaRetiro, setSelectedFechaRetiro] = useState(undefined);
  const [selectedHoraRetiro, setSelectedHoraRetiro] = useState(undefined);
  const [selectedFechaEntrega, setSelectedFechaEntrega] = useState(undefined);
  const [selectedHoraEntrega, setSelectedHoraEntrega] = useState(undefined);
  const [showError, setShowError] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    setSelectedFechaRetiro(props.initialValues ? moment(props.initialValues.fecha_hora_retiro, 'YYYY-MM-DD') : moment());
    setSelectedHoraRetiro(props.initialValues ? moment(props.initialValues.fecha_hora_retiro.split(' ')[1], 'HH:mm') : moment('13:00', 'HH:mm'));
    setSelectedFechaEntrega(props.initialValues ? moment(props.initialValues.fecha_hora_entrega, 'YYYY-MM-DD') : moment());
    setSelectedHoraEntrega(props.initialValues ? moment(props.initialValues.fecha_hora_entrega.split(' ')[1], 'HH:mm') : moment('13:00', 'HH:mm'));
  }, [props.initialValues]);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      form.validateFields()
        .then(values => {
          const data = {
            ...values,
            fecha_hora_retiro: values.fecha_retiro.format("YYYY-MM-DD") + ' ' + values.hora_retiro.format("HH:mm:ss"),
            fecha_hora_entrega: values.fecha_entrega.format("YYYY-MM-DD") + ' ' + values.hora_entrega.format("HH:mm:ss")
          }

          if (moment(data.fecha_hora_entrega).isSameOrBefore(moment(data.fecha_hora_retiro))) {
            setShowError(true);
            throw "error";
          }

          props.onValidate(true, data);
        })
        .catch(error => props.onValidate(false, {}));
    }
  }, [props.current, props.status]);

  const calendarHeaderRenderer = ({ value, onChange }) => {
    const month = value.month();
    const year = value.year();

    return (
      <Row type="flex" justify="space-between" style={ { padding: 16 } }>
        <Col>
          <Select size="small" dropdownMatchSelectWidth={ false }
            value={ String(month) }
            onChange={ selectedMonth => {
              const newValue = value.clone();
              newValue.month(parseInt(selectedMonth, 10));
              onChange(newValue);
            } }
          >
            { monthOptions }
          </Select>
        </Col>
        <Col>
          <Select size="small" dropdownMatchSelectWidth={ false }
            value={ String(year) }
            onChange={ newYear => {
              const now = value.clone().year(newYear);
              onChange(now);
            } }
          >
            { yearOptions }
          </Select>
        </Col>
      </Row>
    );
  }

  const validate = (fechaRetiro, horaRetiro, fechaEntrega, horaEntrega) => {
    if(!fechaRetiro || !horaRetiro || !fechaEntrega || !horaEntrega){ return; }
    
    //setShowError(false);
    const data = {
      fecha_hora_retiro: fechaRetiro.format("YYYY-MM-DD") + ' ' + horaRetiro.format("HH:mm:ss"),
      fecha_hora_entrega: fechaEntrega.format("YYYY-MM-DD") + ' ' + horaEntrega.format("HH:mm:ss")
    }
    setShowError(moment(data.fecha_hora_entrega).isSameOrBefore(moment(data.fecha_hora_retiro)));
  }
  
  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      initialValues={ { ...props.initialValues,
        fecha_retiro: props.initialValues ? moment(props.initialValues.fecha_hora_retiro, 'YYYY-MM-DD') : moment(),
        hora_retiro: props.initialValues ? moment(props.initialValues.fecha_hora_retiro.split(' ')[1], 'HH:mm') : moment('13:00', 'HH:mm'),
        fecha_entrega: props.initialValues ? moment(props.initialValues.fecha_hora_entrega, 'YYYY-MM-DD') : moment(),
        hora_entrega: props.initialValues ? moment(props.initialValues.fecha_hora_entrega.split(' ')[1], 'HH:mm') : moment('13:00', 'HH:mm')
      } }
      scrollToFirstError
      autoComplete="off"
    >
      <Card type="inner" title="Retiro" style={ { marginBottom: 16 } }>
        <FormItem name="fecha_retiro" rules={ UIHelper.defaultRules }>
          <Calendar
            style={ { border: '1px solid #d9d9d9', borderRadius: 4 } }
            disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
            headerRender={ calendarHeaderRenderer }
            fullscreen={ false }
            onChange={ value => { setSelectedFechaRetiro(value); validate(value, selectedHoraRetiro, selectedFechaEntrega, selectedHoraEntrega); } }
          />
        </FormItem>
        <FormItem name="hora_retiro" rules={ UIHelper.defaultRules }>
          <TimePicker
            disabledMinutes={ disabledMinutes }
            hideDisabledOptions
            defaultOpenValue={ moment('13:00', 'HH:mm') }
            placeholder="Hora"
            format={ 'HH:mm' }
            allowClear={ false }
            showNow={ false }
            onChange={ value => { setSelectedHoraRetiro(value); validate(selectedFechaRetiro, value, selectedFechaEntrega, selectedHoraEntrega); } }
          />
        </FormItem>
      </Card>

      <Card type="inner" title="Entrega" style={ { marginBottom: 16 } }>
        <FormItem name="fecha_entrega" rules={ UIHelper.defaultRules }>
          <Calendar
            style={ { border: '1px solid #d9d9d9', borderRadius: 4 } }
            disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
            headerRender={ calendarHeaderRenderer }
            fullscreen={ false }
            onChange={ value => { setSelectedFechaEntrega(value); validate(selectedFechaRetiro, selectedHoraRetiro, value, selectedHoraEntrega); } }
          />
        </FormItem>
        <FormItem name="hora_entrega" rules={ UIHelper.defaultRules }>
          <TimePicker
            disabledMinutes={ disabledMinutes }
            hideDisabledOptions
            defaultOpenValue={ moment('13:00', 'HH:mm') }
            placeholder="Hora"
            format={ 'HH:mm' }
            allowClear={ false }
            showNow={ false }
            onChange={ value => { setSelectedHoraEntrega(value); validate(selectedFechaRetiro, selectedHoraRetiro, selectedFechaEntrega, value); } }
          />
        </FormItem>
      </Card>

      { showError
        ? <Alert type="error"
          message="Fecha y Hora de entrega deben ser posteriores a Fecha y Hora de retiro." 
        />
        : '' }
    </Form>
  );
}

export default RetiroEntregaStep;
