import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Layout, Button, Card, Form, Input, Row, Col, Spin } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PageTitle from '../../../../components/PageTitle';
import basicStyle from '../../../../themes/basicStyle';
import UIHelper from '../../../../helpers/UIHelper';
import AppNotification from '../../../../components/AppNotification';
import TipoAcopladoApi from '../../../../api/parametricas/tipoAcopladoApi';

const { Content } = Layout;
const FormItem = Form.Item;

const TipoDeChasisForm = props => {
  const history = useHistory();
  const [tipoDeChasis, setTipoDeChasis] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm(); 

  useEffect(()=>{
    if(history.location.pathname.indexOf('edit') > -1){
      loadTipoDeChasis();
    }
  }, []);

  const loadTipoDeChasis = () => {
    setWorking(true);
    TipoAcopladoApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setTipoDeChasis(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);
    let data = { 
      id: tipoDeChasis?.id,
      ...values,
    }
    TipoAcopladoApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Tipo de chasis guardado correctamente');
        history.goBack();
      })
      .catch(error => { setWorking(false); });
  };

  return (
    <Content>
      <PageTitle title={ `${tipoDeChasis?.id ? 'Editar' : 'Nuevo'} Tipo de Chasis` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            initialValues={ { ...tipoDeChasis } }
            scrollToFirstError
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                <FormItem name="nombre" label="Nombre" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input maxLength={255}/>
                </FormItem>
              </Col>
              
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
              </FormItem>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Content>
  );
}

export default TipoDeChasisForm;