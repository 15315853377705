import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Descriptions, Modal, Row } from 'antd';
import moment from 'moment';

const DescItem = Descriptions.Item;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    boxSizing: 'border-box'
  },
  label: {
    flex: '1',
    textAlign: 'right',
    fontWeight: 'bold',
    padding: '10px'
  },
  value: {
    flex: '1',
    padding: '10px'
  },
  img: {
    width: '80px', 
    height: '50px'
  }
};

const ConfirmacionStep = props => {
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imageModalTitle, setImageModalTitle] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      props.onValidate(true, {});
    }
  }, [props.current, props.status]);

  const renderCard = data => {
    let licencia_url;
    if(data.licencia_de_conducir){ licencia_url = URL.createObjectURL(data.licencia_de_conducir); }

    return <Descriptions bordered column={ 1 } size="small">
      <DescItem label="Cuit">{ data?.cuit }</DescItem>
      <DescItem label="Nombre">{ data?.razon_social }</DescItem>
      <DescItem label="Teléfono celular">{ data?.telefono_celular }</DescItem>
      <DescItem label="Fecha de vencimiento de licencia">{ data?.fecha_de_vencimiento_licencia.format('DD-MM-YYYY') }</DescItem>
      { licencia_url
        ? <DescItem label="Licencia">
          <img alt="Licencia de conducir" style={ styles.img } src={ licencia_url } 
            onClick={ () => { setSelectedImage(licencia_url); setImageModalTitle('Licencia de conducir'); setShowImageModal(true); } } />
        </DescItem>
        : '' }
    </Descriptions>
  }

  return (
    <Fragment>
      <h3 style={ { marginBottom: 16 } }>Datos del nuevo chofer:</h3>
      <div style={ styles.container }>
        <Row gutter={[16,16]}>
          <Col sm={24} xs={24}>
            { props.data ? renderCard(props.data) : '' }
          </Col>
        </Row>
      </div>

      <Modal title={ imageModalTitle }
        visible={ showImageModal } 
        footer={ null } 
        onCancel={ () => setShowImageModal(false) }
      >
        <img alt={ imageModalTitle } style={ { width: '100%' } } src={ selectedImage } />
      </Modal>
    </Fragment>
  );
}

export default ConfirmacionStep;