import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import UIHelper from '../../../helpers/UIHelper';

const FormItem = Form.Item;

const LoginForm = props => {
  const [form] = Form.useForm();

  const onFinish = () => {
    form.validateFields()
      .then(values => {
        props.handleLogin(values.userName, values.password);
      });
  }

  /*const loginErrorMessageVisibility = this.props.loginErrorMessage
    && !this.state.isLoading
    && this.state.isFormSent ? 'block' : 'none';*/

  return(
    <Form 
      name="formulario"
      onFinish={ onFinish }
      form={ form }
    >
      <FormItem name="userName" rules={ UIHelper.defaultRules }>
        <Input placeholder={ 'Usuario' }
          prefix={ <UserOutlined style ={ { fontSize: 13 } } /> }
        />
      </FormItem>
      <FormItem name="password" rules={ UIHelper.defaultRules }>
        <Input placeholder={ 'Contraseña' } type="password"
          prefix={ <LockOutlined style={ { fontSize: 13 } } /> }
        />
      </FormItem>
      <FormItem>
        <Button
          type = "primary"
          htmlType = "submit"
          loading = { props.working }
        >
          { !props.working ? 'Entrar' : '' }
        </Button>
      </FormItem>
      { props.loginErrorMessage
        ? <p className="loginErrorMessage">
          { props.loginErrorMessage }
        </p>
        : '' }
    </Form>
  );
}

export default LoginForm;
