import { Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const VerButton = props => {
  return <Tooltip 
      title={ props.title ? props.title : 'Ver' }
    >
      <Button 
        shape="circle" 
        icon={ <EyeOutlined /> } 
        size="small" 
        type="primary"
        onClick={ props.onClick }
      />
  </Tooltip>
}

export default VerButton;