import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { Card } from 'antd';
import { data } from './ParametricasListData';
import PageTitle from '../../../components/PageTitle';
import './ParametricasList.scss';

const ParametricasList = props => {
  const history = useHistory();

  return (
    <Fragment>
      {/*<BreadcrumbRoutes />*/}
      <PageTitle title="Paramétricas"/>
      <Card style={ { padding: 24 } }>
        { data.map((param, i) => 
          <Card.Grid key={ i } className="parametrica" 
            onClick={ () => history.push(`/parametricas/` + param.url) }
          >
            <span>{ param.icon }</span>
            <p>{param.name}</p>
          </Card.Grid>
        ) }
      </Card>
    </Fragment>
  );
}

export default ParametricasList;