import React, { useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import TransportistasApi from '../../api/transportistasApi';
import DashboardTotales from './components/DashboardTotales';
import './DashboardTransportistaPage.scss';
import ViajesTable from './components/ViajesTable';
import OfertasTable from './components/OfertasTable';
import OfertasApi from '../../api/ofertasApi';
import UnidadesTransporteApi from '../../api/unidadesTransporteApi';
import ViajesEnCursoMap from './components/ViajesEnCursoMap';

const { Content } = Layout;

const DashboardTransportistaPage = props => {
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = () => {
    setWorking(true);
    TransportistasApi.getDashboard()
      .then(response => {
        setWorking(false);
        setDashboardInfo(response?.length > 0 ? response[0] : response);
      })
      .catch(error => setWorking(false));
  }

  return (
    <Content style={ { margin: '24px 16px' } } className="dashboard-transportistas">
      <Row gutter={ [24,24] }>
        <DashboardTotales
          dashboardInfo={ dashboardInfo }
        />
        <Col span={ 24 }>
          <OfertasTable
            dashboardInfo={ dashboardInfo }
            working={ working }
            getUnidadesTransporteNoAsignadas={ () => UnidadesTransporteApi.getNoAsignadas() }
            getOferta={ id => OfertasApi.get(id) }
            asignarUnidadesTransporteOferta={ (id, utIds) => OfertasApi.asignarUnidadesTransporte(id, utIds) }
            loadDashboard={ loadDashboard }
          />
        </Col>
        <Col span={ 24 }>
          <ViajesTable
            dashboardInfo={ dashboardInfo }
            working={ working }
          />
        </Col>
        <Col span={ 24 }>
          <ViajesEnCursoMap
            dashboardInfo={ dashboardInfo }
            loadDashboard={ loadDashboard }
            working={ working }
          />
        </Col>
      </Row>
    </Content>
  );
}

export default DashboardTransportistaPage;