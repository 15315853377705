import React, { Fragment, useEffect, useState } from 'react';

import { Layout, Row, Col, Card, Button, Form, Spin, Input, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import DadoresCargaApi from '../../../api/dadoresCargaApi';
import AppNotification from '../../../components/AppNotification';
import UIHelper from '../../../helpers/UIHelper';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import basicStyle from '../../../themes/basicStyle';
import UvertracApi from '../../../api/uvertracApi';
import CuitFormItem from '../../../components/FormItems/CuitFormItem';
import LocalizacionFormItem from '../../../components/FormItems/LocalizacionFormItem';

const { Content } = Layout;
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const DadorCargaForm = props => {
  const history = useHistory();
  const [dadorCarga, setDadorCarga] = useState(undefined);
  const [tiposIva, setTiposIva] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    loadTiposIva();
    if(history.location.pathname.indexOf('edit') > -1){
      loadDadorCarga();
    }
  }, []);

  const loadTiposIva = () => UvertracApi.get({ model: 'TipoIva', colReturn: ['id','nombre'], colOrderBy: 'nombre' }).then(response => setTiposIva(response.data));

  const loadDadorCarga = () => {
    setWorking(true);

    DadoresCargaApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setDadorCarga(response);
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);
    let data = { 
      id: dadorCarga?.id,
      ...values,
      parametrica_provincia_id: values.localizacion ? values.localizacion[0] : null,
      parametrica_departamento_de_provincia_id: values.localizacion ? values.localizacion[1] : null,
      parametrica_localidad_de_departamento_id: values.localizacion ? values.localizacion[2] : null
    }
    DadoresCargaApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Dador de carga guardado correctamente');
        history.goBack();
      })
      .catch(error => { setWorking(false); });
  };

  return (
    <Content>
      <PageTitle title={ `${dadorCarga?.id ? 'Editar' : 'Nuevo'} Dador de Carga` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            initialValues={ { ...dadorCarga,
              localizacion: dadorCarga ? [dadorCarga.parametrica_provincia_id, dadorCarga.parametrica_departamento_de_provincia_id, dadorCarga.parametrica_localidad_de_departamento_id] : []
            } }
            scrollToFirstError
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                <FormItem name="codigo" label="Código"
                  extra={ <small>El código es autogenerado</small> }
                >
                  <Input disabled />
                </FormItem>
                <FormItem name="nombre" label="Nombre" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input maxLength={255}/>
                </FormItem>
                <FormItem name="apellido" label="Apellido">
                  <Input maxLength={255}/>
                </FormItem>
                <FormItem name="direccion" label="Dirección">
                  <Input maxLength={255}/>
                </FormItem>
                <LocalizacionFormItem name="localizacion" label="Localizacion"
                />
                <FormItem name="telefono" label="Teléfono">
                  <Input />
                </FormItem>
                <FormItem name="email" label="Email" hasFeedback rules={ [ ...UIHelper.defaultRules, {type:'email', message: 'Debe ingresar un email valido'} ] }>
                  <Input />
                </FormItem>
                <FormItem name="parametrica_tipo_de_iva_id" label="Tipo IVA" hasFeedback rules={ UIHelper.defaultRules } autoComplete="off">
                  <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
                    { tiposIva.map(tipo => (<Option key={ tipo.id } value={ tipo.id }>{ tipo.nombre }</Option>)) }
                  </Select>
                </FormItem>
                <CuitFormItem
                  id={ dadorCarga?.id }
                  form={ form }
                  onCuitExistente={ response => {
                    if (!response.dadorDeCargaExistente && response.transportistaExistente){
                      AppNotification.showInfo(`El Transportista ${response.transportista.nombre} ${response.transportista.apellido} posee el mismo CUIT. `);
                    }
                    return response.dadorDeCargaExistente;
                  } }
                />
                <FormItem name="observacion_administracion" label="Observacion Administrador">
                  <TextArea rows={4} />
                </FormItem>
              </Col>
              
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
              </FormItem>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Content>
  );
}

export default DadorCargaForm;
