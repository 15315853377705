import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import ConfiguracionList from './components/ConfiguracionList';
import ConfiguracionForm from './components/ConfiguracionForm';

const { Content } = Layout;

const ConfiguracionPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ ConfiguracionList } />
        <Route path={ `${props.match.path}/new` } component={ ConfiguracionForm } />
        <Route path={ `${props.match.path}/edit/:id` } component={ ConfiguracionForm } />
      </Switch>
    </Content>
  );
}

export default ConfiguracionPage;