import React, { useEffect, useState } from 'react';

import { Layout, Card, Spin } from 'antd';
import AppNotification from '../../../../components/AppNotification';
import PageTitle from '../../../../components/PageTitle';
import StepsForm from '../../../../components/StepsForm';
import InformacionGeneralStep from './components/InformacionGeneralStep';
import ViajesApi from '../../../../api/viajesApi';
import LocalizacionStep from './components/LocalizacionStep';
import RetiroEntregaStep from './components/RetiroEntregaStep';
import TarifasStep from './components/TarifasStep';
import ConfirmacionStep from './components/ConfirmacionStep';
import { useHistory } from 'react-router';

const { Content } = Layout;

const PublicarViajeForm = props => {
  const history = useHistory();
  const [viaje, setViaje] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [working, setWorking] = useState(false);

  useEffect(()=>{
    if(props.match.params.id){
      loadViaje();
    }
  }, []);

  const steps = [
    { title: 'Información General', content: InformacionGeneralStep, description: 'Info general de la carga a publicar.' },
    { title: 'Localización', content: LocalizacionStep, description: 'Direcciones Inicio y Fin de la carga.' },
    { title: 'Retiro / Entrega', content: RetiroEntregaStep, description: 'Fechas de envío y entrega.' },
    { title: 'Tarifas', content: TarifasStep, description: 'Tarifas y Formas de pago.' },
    { title: 'Resumen', content: ConfirmacionStep, description: 'Resumen y Confirmación de la carga.' }
  ];

  const loadViaje = () => {
    setWorking(true);
    ViajesApi.getViajesPublicados()
      .then(response => { 
        setWorking(false);
        setViaje(response.find(x => x.id == props.match.params.id)); 
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);

    let data = {
      ...values
    }

    ViajesApi.save(data)
      .then(response => {
        setWorking(false);
        setResult('success');
        AppNotification.showSuccess('Viaje guardado correctamente');
        history.push('/viajes-en-curso-dador');
      })
      .catch(error => { 
        setResult('error');
        setWorking(false); 
      });
  };

  return (
    <Content>
      <PageTitle title={ props.match.params.id ? 'Editar viaje' : 'Publicar nuevo viaje' } />

      <Spin spinning={ working }>
        <Card>
          <StepsForm
            steps={ steps }
            onFinish={ onFinish }
            enablePrevious={ true }
            result={ result }
            vertical
            initialValues={ viaje }
          />
        </Card>
      </Spin>
    </Content>
  );
}

export default PublicarViajeForm;
