import React, { Fragment, useEffect } from 'react';
import { Col, Descriptions, Row } from 'antd';
import Parser from '../../../../../helpers/Parser';

const DescItem = Descriptions.Item;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    boxSizing: 'border-box'
  },
  label: {
    flex: '1',
    textAlign: 'right',
    fontWeight: 'bold',
    padding: '10px'
  },
  value: {
    flex: '1',
    padding: '10px'
  }
};

const ConfirmacionStep = props => {
  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      const data = {
        titulo: props.data.producto + ': ' + props.data.origen_localidad + ' >>> ' + props.data.destino_localidad
      }
      props.onValidate(true, data);
    }
  }, [props.current, props.status]);

  const renderCardInformacionGeneral = data => {
    const titulo = data?.producto + ': ' + data?.origen_localidad + ' >>> ' + data?.destino_localidad;

    return <Descriptions bordered title="Información General" column={ 1 } size="small">
        <DescItem label="Título">{ titulo }</DescItem>
        <DescItem label="Tipo de Carga">{ data?.tipo_carga }</DescItem>
        <DescItem label="Producto">{ data?.producto }</DescItem>
        <DescItem label="Cantidad de Camiones">{ data?.cantidad_camiones }</DescItem>
        <DescItem label="Información Adicional Pública">{ data?.informacion_adicional_publica || '' }</DescItem>
        <DescItem label="Información Adicional Privada">{ data?.informacion_adicional_privada || '' }</DescItem>
      </Descriptions>
  }

  const renderCardLocalizacion = data => {
    return <Descriptions bordered title="Localización" column={ 1 } size="small">
        <DescItem label="Dirección Origen">{ data?.origen_direccion + ' - ' + data?.origen_direccion_google }</DescItem>
        <DescItem label="Dirección Destino">{ data?.destino_direccion + ' - ' + data?.destino_direccion_google }</DescItem>
        <DescItem label="Distancia en Kmts.">{ data?.kmts_finales }</DescItem>
      </Descriptions>
  }

  const renderCardRetiroEntrega = data => {
    return <Descriptions bordered title="Retiro / Entrega" column={ 1 } size="small">
        <DescItem label="Fecha Retiro">{ data?.fecha_retiro?.format('DD-MM-YYYY') }</DescItem>
        <DescItem label="Hora">{ data?.hora_retiro?.format('HH:mm') }</DescItem>
        <DescItem label="Fecha Entrega">{ data?.fecha_entrega?.format('DD-MM-YYYY') }</DescItem>
        <DescItem label="Hora">{ data?.hora_entrega?.format('HH:mm') }</DescItem>
      </Descriptions>
  }

  const renderCardTarifas = data => {
    return <Descriptions bordered title="Tarifas" column={ 1 } size="small">
        <DescItem label="Viaje Completo">{ Parser.currency(data?.tarifa_viaje_completo) }</DescItem>
        <DescItem label="Por Tonelada">{ Parser.currency(data?.tarifa_por_tonelada) }</DescItem>
        <DescItem label="Condición de pago">{ data?.condicion_de_pago }</DescItem>
      </Descriptions>
  }

  return (
    <Fragment>
      <h3 style={ { marginBottom: 16 } }>Datos publicación de viaje:</h3>
      <div style={ styles.container }>
        <Row gutter={[16,16]}>
          <Col sm={24} xs={24}>
            { renderCardInformacionGeneral(props.data) }
          </Col>
          <Col sm={24} xs={24}>
            { renderCardLocalizacion(props.data) }
          </Col>
          <Col sm={12} xs={24}>
            { renderCardRetiroEntrega(props.data) }
          </Col>
          <Col sm={12} xs={24}>
            { renderCardTarifas(props.data) }
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default ConfirmacionStep;