import React, { Fragment, useState } from 'react';
import { Tag, Alert, Table, Space, Card, Tooltip, Button } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import VerButton from '../../../components/Buttons/VerButton';
import OfertaEstadoTag from '../../../components/Tags/OfertaEstadoTag';
import Parser from '../../../helpers/Parser';
import CargaDadorOfertaDetail from './CargaDadorOfertaDetail';

const CargaDadorOfertaList = props => {
  const [selectedOferta, setSelectedOferta] = useState(undefined);
  const [showOfertaDetail, setShowOfertaDetail] = useState(false);
  const [showAceptarOferta, setShowAceptarOferta] = useState(false);
  const [showRechazarOferta, setShowRechazarOferta] = useState(false);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Tarifa', dataIndex: 'tarifa_viaje_completo', key: 'tarifa_viaje_completo',
      render: text => Parser.currency(text)
    },
    { title: 'Camiones', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center' },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <OfertaEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedOferta(record); setShowOfertaDetail(true); } }/>
        </Space>
      }
    }
  ];
    
  return (
    <Fragment>
      <Card title="Ofertas" size="small">
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ props.working }
          columns={ columns } 
          dataSource={ props.ofertas }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          
        />
      </Card>
      <CargaDadorOfertaDetail
        visible ={ showOfertaDetail }
        onClose={ () => setShowOfertaDetail(false) }
        oferta={ selectedOferta }
        viaje={ props.viaje }
      />

      
      
    </Fragment>
  );
}

export default CargaDadorOfertaList;