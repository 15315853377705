import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const LicenciaStep = props => {
  const [fileList, setFileList] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      let data = {};
      if (fileList.length > 0){ data.licencia_de_conducir = fileList[0]; }

      props.onValidate(true, data); 

      /*if (fileList.length === 1) {
        props.onValidate(true, { licencia_de_conducir: fileList[0] });
      }
      else {
        setShowError(true);
        props.onValidate(false, {});
      } */ 
    }
  }, [props.current, props.status]);

  return (
    <Space direction="vertical" style={ { width: '100%' } }>
      <Upload.Dragger
        accept={ '.jpeg,.jpg,.png,.gif,image/jpeg,image/pjpeg,image/png,image/gif,' }
        beforeUpload={ file => { setFileList([file]); setShowError(false); return false; } }
        onRemove={ file => { setFileList([]); } }
        fileList={ fileList }
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Cliquea o arrastra la imagen a esta area para subir</p>
        <p className="ant-upload-hint">Formatos aceptados: .jpeg, .jpg, .png, .gif</p>
      </Upload.Dragger>
      { showError
        ? <Alert
          message="Debe ingresar al menos un archivo"
          type="error"
        />
        : '' }
    </Space>
  );
}

export default LicenciaStep;
