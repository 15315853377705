import { Input ,DatePicker, Space} from 'antd';
import React, { useEffect, useState,  } from 'react';
import UvertracApi from '../../api/uvertracApi';
import moment from "moment"

const { RangePicker } = DatePicker;

const SearchByDays = props => {
  const [searchType, setSearchType] = useState('like');
  const [columnsReturn, setColumnsReturn] = useState([]);
  const [columnsSearch, setColumnsSearch] = useState([]);
  const [working, setWorking] = useState(false);

  
  const onDateSelection = value => {
    return value &&value <moment().endOf("day");
  }

  const onchange =(value,dateString )=>{
    console.log(dateString)
  }

  return (
  <RangePicker
  format='L'
  onChange={onchange}
  disableDate={onDateSelection}
  />
  );
}

export default SearchByDays;