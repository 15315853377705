import { Button, Tooltip } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';

const EnableDisableButton = props => {
  return <Tooltip 
      title={ props.habilitado ? 'Deshabilitar' : 'Habilitar' }
    >
      <Button 
        shape="circle" 
        icon={ props.habilitado ? <DislikeOutlined /> : <LikeOutlined /> } 
        size="small" 
        onClick={ props.onClick }
      />
  </Tooltip>
}

export default EnableDisableButton;