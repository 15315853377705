import VerButton from '../../../components/Buttons/VerButton';
import OfertaEstadoTag from '../../../components/Tags/OfertaEstadoTag';
import Parser from '../../../helpers/Parser';
import React, { Fragment, useState } from 'react';
import { Tag, Alert, Table, Space, Card, Tooltip, Button } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import TransportistaViajeDetail from './TransportistaViajeDetail';

const TransportistaViajesList = props => {
  const [selectUnidadTransporte, setSelectUnidadTransporte] = useState(undefined);
  const [selectOferta, setSelectOferta] = useState(undefined);
  const [showOfertaDetail, setShowOfertaDetail] = useState(false);
  const [showAceptarOferta, setShowAceptarOferta] = useState(false);
  const [showRechazarOferta, setShowRechazarOferta] = useState(false);

  const columns = [
    { title: 'Codigo Uvertrac', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Origen', dataIndex: 'origen_direccion', key: 'origen_direccion' },
    { title: 'Destino', dataIndex: 'destino_direccion', key: 'destino_direccion' },
    { title: 'Estado', dataIndex: 'estado', key: 'estado',
        render: text => <Tag color="green">{ text }</Tag>
      },
      { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectUnidadTransporte(record.unidad_transporte); setShowOfertaDetail(true);  setSelectOferta(record);} }/>
        </Space>
      }
    }
  ];
  return (
    <Fragment>
      <Card title="Ofertas" size="small">
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ props.working }
          columns={ columns } 
          dataSource={ props.viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          
        />
      </Card>
      <TransportistaViajeDetail
        visible ={ showOfertaDetail }
        onClose={ () => setShowOfertaDetail(false) }
        unidad={ selectUnidadTransporte }
        oferta={ selectOferta }
        viajeFinalizado={props.viajeFinalizado}

      />
    </Fragment>
  );
}

export default TransportistaViajesList;
