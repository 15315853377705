import React from 'react';
import moment from 'moment';

export default class Parser{  
  static currency(text){
    return text ? new Intl.NumberFormat('es-AR', {style: 'currency', currency: 'ARS' }).format(text) : '';
  }

  static date(text){
    if(!text){ return null; }
    let date = new moment(text).format('DD-MM-YYYY');
    return date != "01-01-0001" ? date : '';
  }

  static datetime(text){
    if(!text){ return null; }
    let datetime = new moment(text).format('DD-MM-YYYY HH:mm:ss');
    return datetime != "01-01-0001 00:00:00" ? datetime : '';
  }

  static datetimeLink(text){
    return new moment(text, "YYYYMMDDHHmmssSSS").format('DD-MM-YYYY HH:mm:ss');
  }

  static fileSize(size){
    if(size > 0){
      let i = Math.floor( Math.log(size) / Math.log(1024) );
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }
    return size + 'B';
  }

  static parseDownloadError = error => {
    let reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = evt => {
        try {
          resolve(JSON.parse(evt.target.result));
        } catch(e) {
          resolve(evt.target.result);
        }
      };
      reader.onerror = e => {
        reader.abort();
        resolve(e);
      };
      if(error.response && error.response.data){
        reader.readAsText(new Blob([error.response.data]));
      }
      else{ resolve(error); }
    });
  }

  static parseError = error => {
    let err = error?.response ? error.response : error;
    let message = '';
    try {
      if(typeof err === 'object'){
        if(err.data){
          message = typeof err.data !== 'object' ? err.data
            : err.data.error ? err.data.error 
              : err.data.ExceptionMessage ? err.data.ExceptionMessage
                : err.data.Message ? err.data.Message
                    : err.data.message ? err.data.message
                      : 'Ocurrio un error desconocido';
        }
        else{
          message = err.message ? err.message : err.Message;
        }
      }
      else{
        message = err;
      }
    } catch (e) {
      message = 'Ocurrio un error desconocido';
    }

    message = message === 'Network Error' ? 'No se obtuvo respuesta del servidor' : message;

    return message ? message : '';
  }
}