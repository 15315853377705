import Fetcher from "../helpers/Fetcher";

const ViajesApi = {
  getViajesPublicados: id => {
    let url = 'uvertraccore/viajes-publicados/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getViajesPublicadosTransportista: id => {
    let url = 'uvertraccore/publicacion-viajes/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  
  
  saveCartaPorte: (viajeId, data) => {
    return Fetcher
      .post('uvertraccore/viajes/save-campo-carta-porte/' + viajeId, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'uvertraccore/publicacion-viajes/';
    if(!data.id){
      return Fetcher
        .post(url, data)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
      .put(url + data.id, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
    }
  },
  disable: viajeId => {
    return Fetcher
      .put('uvertraccore/publicacion-viajes/deshabilitar/' + viajeId)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  cancelarViajeEnCurso: viajeId => {
    return Fetcher
      .post('uvertraccore/viajes/cambiar-estado/' + viajeId, { nuevo_estado:'CANCELADO' })
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  terminarViajeEnCurso: viajeId => {
    return Fetcher
      .post('uvertraccore/viajes/cambiar-estado/' + viajeId, { nuevo_estado:'TERMINADO' })
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  reactivarViaje: viajeId => {
    return Fetcher
      .post('uvertraccore/viajes/cambiar-estado/' + viajeId, { nuevo_estado:'VIAJANDO' })
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  facturarViaje: viajeId => {
    return Fetcher
      .post('uvertraccore/viajes/cambiar-estado/' + viajeId, { nuevo_estado:'FACTURADO' })
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
}

export default ViajesApi;