import { HomeOutlined, DesktopOutlined, CarOutlined, UserOutlined, ProfileOutlined } from '@ant-design/icons';

import AuthHelper from '../../../helpers/AuthHelper';
import DashboardUvertracUserPage from '../../dashboard-uvertrac-users/DashboardUvertracUserPage';
import DadoresCargaPage from '../../dadores-carga/DadoresCargaPage';
import TransportistasPage from '../../transportistas/TransportistasPage';
import UsuariosPage from '../../usuarios/UsuariosPage';
import ParametricasPage from '../../parametricas/ParametricasPage';
import ConfiguracionPage from '../../configuracion/ConfiguracionPage';
import ViajeTransportistaPage from '../../viajes-transportistas/ViajesTransportistaPage'
import CargaDadores from '../../cargas-dadores/CargaDadoresPage';

const routesUvertracUsers = [
  {
    permission: "uver_dashboard_page.visualizar_left_menu",
    key: "inicio-usuario-administrativo",
    breadcrumbName: "Inicio",
    path: "/inicio-usuario-administrativo",
    menu: true,
    icon: {
      type: <HomeOutlined />,
      title: "Inicio"
    },
    component: AuthHelper.requireAuth(DashboardUvertracUserPage)
  },
  {
    permission: "uver_dashboard_page.visualizar_left_menu",
    key: "dadores_de_carga",
    breadcrumbName: "Dadores de Carga",
    path: "/dadores-de-carga",
    menu: true,
    icon: {
      type: <DesktopOutlined />,
      title: "Dadores de Carga"
    },
    component: AuthHelper.requireAuth(DadoresCargaPage)
  },
  {
    permission: "uver_parametricas.visualizar_left_menu",
    key: "cargas-dadores",
    path: "/cargas-dadores",
    menu: true,
    breadcrumbName: "cargas-dadores",
    component: AuthHelper.requireAuth(CargaDadores),
    icon: {
      type: <CarOutlined />,
      title: "Viajes Dadores"
    }
  },
  {
    permission: "uver_dashboard_page.visualizar_left_menu",
    key: "transportistas",
    breadcrumbName: "Transportistas",
    path: "/transportistas",
    menu: true,
    icon: {
      type: <CarOutlined />,
      title: "Transportistas"
    },
    component: AuthHelper.requireAuth(TransportistasPage)
  },
  {
    permission: "uver_parametricas.visualizar_left_menu",
    key: "viajes-transportistas",
    path: "/viajes-transportistas",
    menu: true,
    breadcrumbName: "viajes-transportista",
    component: AuthHelper.requireAuth(ViajeTransportistaPage),
    icon: {
      type: <CarOutlined />,
      title: "Viajes Transportistas"
    }
  },
  
  {
    permission: "uver_dashboard_page.visualizar_left_menu",
    key: "usuarios",
    breadcrumbName: "Usuarios",
    path: "/usuarios",
    menu: true,
    icon: {
      type: <UserOutlined />,
      title: "Usuarios"
    },
    component: AuthHelper.requireAuth(UsuariosPage)
  },
  {
    permission: "uver_parametricas.visualizar_left_menu",
    key: "parametricas",
    path: "/parametricas",
    menu: true,
    breadcrumbName: "Paramétricas",
    component: AuthHelper.requireAuth(ParametricasPage),
    icon: {
      type: <ProfileOutlined />,
      title: "Paramétricas"
    }
  },
  
  
  {
    permission: "admin_parametros.visualizar_left_menu",
    key: "parametros_del_sistema",
    path: "/parametros-del-sistema",
    menu: true,
    breadcrumbName: "Configuración Sistema",
    component: AuthHelper.requireAuth(ConfiguracionPage),
    icon: {
      type: <ProfileOutlined />,
      title: "Configuracion Sistema"
    }
  },
];

export default routesUvertracUsers;
