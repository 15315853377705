import React from 'react';
import './Patente.scss';

const Patente = props => {

  const formatPatente = patente =>{
    let grupos = [];
    let grupoActual = '';
    for (let i = 0; i < patente.length; i++) {
      if(grupoActual == ''){
        grupoActual = patente[i];
      }
      else{
        if((patente[i].match(/^\d/) && grupoActual.match(/^\d/)) || (!patente[i].match(/^\d/) && !grupoActual.match(/^\d/))){
          grupoActual = grupoActual + patente[i];
        }
        else{
          grupos.push(grupoActual);
          grupoActual = patente[i];
        }
      }
    }
    grupos.push(grupoActual);

    let nuevaPatente = '';
    grupos.map(grupo => {
      nuevaPatente = nuevaPatente + grupo + ' ';
    });
    return nuevaPatente.trim();
  }

  return (
    <div className="patente" { ...props }>
      <div className={ `title ${props.remolque ? 'remolque' : ''}` }>{ props.remolque ? 'TRAILER' : 'ARGENTINA' }</div>
      <div className="number">{ formatPatente(props.patente) }</div>
    </div>
  );
}

export default Patente;