import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Tag,Col, Select} from 'antd';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import OfertaEstadoTag from '../../../components/Tags/OfertaEstadoTag';
import Parser from '../../../helpers/Parser';
import TransportistasApi from '../../../api/transportistasApi';
import TransportistaViajesList from './TransportistaViajesList';
import SearchOfertas from './SearchOfertas'

const TransportistaTableOfertas = props => {
  const history = useHistory();
  const [ofertas, setOfertas] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadOfertas();
  }, []);

  const columns = [
    { title: 'Codigo Uvertrac', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Condición de pago', dataIndex: 'condicion_de_pago', key: 'condicion_de_pago' },
    { title: 'Tarifa', dataIndex: 'tarifa_viaje_completo', key: 'tarifa_viaje_completo',
      render: text => <span>{ Parser.currency(text) }</span>
    },
    { title: 'Cantidad camiones solicitados', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center',
      render: text => <Tag color="green">{ text == '1' ? text + ' camión' : text + ' camiones' }</Tag>
    },
    { title: 'Cantidad viajes en curso', key: 'viajes_en_curso', align: 'center',
      render: (text, record) => <Tag color="green">{ record.viajes_en_curso.length == 1 ? record.viajes_en_curso?.length + ' viaje' : record.viajes_en_curso?.length + ' viajes' }</Tag>
    },
    { title: 'Cantidad viajes finalizados', key: 'viajes_en_curso', align: 'center',
      render: (text, record) => <Tag color="cyan">{ record.viajes_finalizados?.length == 1 ? record.viajes_finalizados?.length + ' viaje' : record.viajes_finalizados?.length + ' viajes' }</Tag>
    },
    { title: 'Estado de la Oferta', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <OfertaEstadoTag estado={ text }/>
    },
    { title: '', render: (_, record) => record.estado === 'ACEPTADA'
      ? <Fragment key={ record.id }>
      </Fragment>
      : '' },
  ];

  const loadOfertas = () => {
    setWorking(true);
    TransportistasApi.getOfertasRealizadas()
      .then(response => {
        setWorking(false);
        setOfertas(response);
      })
      .catch(error => setWorking(false));
  }

  return (
    <Fragment>
      <PageTitle title="Ofertas Realizadas" onBack={ () => history.goBack() } />
      
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ ofertas }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          expandable={ {
            expandedRowRender: record => 
              <TransportistaViajesList 
                viajes={ record.viajes_en_curso }
                loadViajes={ loadOfertas }
              />,
            rowExpandable: record => record.viajes_en_curso?.length > 0
          } }
        />
    </Fragment>
  );
}

export default TransportistaTableOfertas;
