import LoginPage from '../../login/LoginPage';
import routesDadoresCarga from './routes-dadores-de-carga';
import routesTransportistas from './routes-transportistas';
import routesUvertracUsers from './routes-uvertrac-users';
import UserConfirmationPage from '../../login/components/UserConfirmationPage'

const routes = [
  {
    key: "login",
    breadcrumbName: "Login",
    path: "/login",
    exact: true,
    component: LoginPage
  },
  {
    key: "user_confirmation",
    breadcrumbName: "User Confirmation",
    path: "/user-confirmation/:confirmation_hash",
    exact: true,
    component: UserConfirmationPage
  },

  ...routesDadoresCarga,
  ...routesTransportistas,
  ...routesUvertracUsers,

  {
    key: "default",
    component: LoginPage
  },
];

export default routes;
