import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Card, Tag, Button, Input, Col, Space, Tooltip } from 'antd';
import { PlusOutlined, UserAddOutlined ,CloseSquareOutlined, DislikeOutlined} from '@ant-design/icons';
import DadoresCargaApi from '../../../api/dadoresCargaApi';
import PageTitle from '../../../components/PageTitle';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import AppNotification from '../../../components/AppNotification';
import EnableDisableButton from '../../../components/Buttons/EnableDisableButton';
import VerButton from '../../../components/Buttons/VerButton';
import SearchDinamic from '../../../components/Search/Search-Dinamic';
import CargaDadorDetail from './CargaDadorDetail';


const CargaDadorList = props => {
  const history = useHistory();
  const [dadoresCarga, setDadoresCarga] = useState([]);
  const [working, setWorking] = useState(false);
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);

  useEffect(() => {
    loadDadoresCarga();
  }, []);

  const columns = [
    { title: 'Id', dataIndex: 'id', width: '10%', },
    { title: 'Nombre', dataIndex: 'nombre', width: '25%', },
    { title: 'Apellido', dataIndex: 'apellido', width: '25%', }, 
    { title: 'Razon social', dataIndex: 'razon_social', width: '25%', }, 
    { title: 'Estado', dataIndex: 'habilitado', width: '10%', align: 'center',
      render: text => <Tag color={ text ? 'geekblue' : 'red' }>{ text ? 'Habilitado' : 'Deshabilitado' }</Tag>
    },
    { key: 'actions', align: 'center',title: 'Accion', 
      render: (text, record) => {
        return <Space size="small">
          {record.viajes_publicados?.length > 0 
          ? 
          <VerButton onClick={ () => { history.push(history.location.pathname + '/view/' + record.id) } }/>
          :
          <Tag color={ 'orange' }>{ 'No posee viajes' }</Tag>
          }
        </Space>
      }
    }
  ];

  const loadDadoresCarga = () => {
    setWorking(true);
    DadoresCargaApi.get()
      .then(response => {
        setWorking(false);
        setDadoresCarga(response);
      })
      .catch(error => setWorking(false));
  }
  
  
  const handleEnableDisable = (id, status) => {
    setWorking(true);
    DadoresCargaApi.enable_disable(id, status)
      .then(response => {
        setWorking(false);
        loadDadoresCarga();
      })
      .catch(error => setWorking(false));
  };


  return (
    <Fragment>
      <PageTitle title="Dadores de carga"/>
      <Card
        title={<Col span={12}>
          <SearchDinamic
            module="uvertraccore"
            model="dadorcarga"
            columnsSearch={ ['apellido','razon_social'] }
            columnsReturn={ columns }
            relationships={'viajesPublicados'}
            onChange={ response => setDadoresCarga(response) }
            onClear={ loadDadoresCarga }
          />
        </Col>}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ dadoresCarga }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          //onRow={ record => ({ onClick: () => { setSelectedRow(record.id); }}) }
        />
        <CargaDadorDetail
          viaje={ selectedViaje}
          visible={ showViajeDetail }
          onClose={ () => setShowViajeDetail(false) }
        />

      </Card>
    </Fragment>
  );
}

export default CargaDadorList;
