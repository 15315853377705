import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Card, Tag, Col, Space, Tooltip, Button, Modal, Alert } from 'antd';
import { DislikeOutlined } from '@ant-design/icons';
import ViajesApi from '../../../../api/viajesApi';
import PageTitle from '../../../../components/PageTitle';
import EditarButton from '../../../../components/Buttons/EditarButton';
import OfertasList from './components/OfertasList';
import ViajeDetail from './components/ViajeDetail';
import VerButton from '../../../../components/Buttons/VerButton';
import ViajeReject from './components/ViajeReject';
import OfertasApi from '../../../../api/ofertasApi';
import ViajeEstadoTag from '../../../../components/Tags/ViajeEstadoTag';
import DadoresCargaApi from '../../../../api/dadoresCargaApi';
import SearchDinamic from '../../../../components/Search/Search-Dinamic';

const ViajePublicadoList = props => {
  const history = useHistory();
  const [viajes, setViajes] = useState([]);
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);
  const [showViajeReject, setShowViajeReject] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadViajes();
  }, []);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Titulo', dataIndex: 'titulo', key: 'titulo' },
    { title: 'Camiones Pedidos', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center' },
    { title: 'Saldo de camiones', dataIndex: 'saldo_camiones', key: 'saldo_camiones', align: 'center',
      render: (text, record) => <span>{ calcSaldo(record) }</span>
    },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <ViajeEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedViaje(record); setShowViajeDetail(true); } }/>
          <EditarButton onClick={ () => handleEdit(record) }/>
          <Tooltip title={ 'Despublicar viaje' } placement="topRight">
            <Button shape="circle" icon={ <DislikeOutlined /> } size="small"
              onClick={ () => { setSelectedViaje(record); setShowViajeReject(true); } }
            />
          </Tooltip>
        </Space>
      }
    }
  ];

  const calcSaldo = record => {
    let saldo = record.cantidad_camiones;
    record.oferta.map(oferta =>{
      if(oferta.estado == 'ACEPTADA'){
        saldo -= oferta.cantidad_camiones;
      }
    });
    return saldo;
  }

  const loadViajes = () => {
    setWorking(true);
    DadoresCargaApi.getViajesPublicados()
      .then(response => {
        setWorking(false);
        setViajes(response);
      })
      .catch(error => setWorking(false));
  }
  
  const handleEdit = viaje => {
    if(viaje.oferta.length > 0){
      Modal.warning({
        width: '40%',
        title:<span>Viaje: <Tag color="cyan">{ viaje.codigo_uvertrac }</Tag></span>,
        content: <Alert style={ { marginTop: 24 } } message={<div>
            <p><b>El viaje tiene ofertas, no puede modificar información.</b></p>
            <p style={ { margin: 0 } }>Si considera cambios contactase con el transportista.</p>
          </div> }
          type="warning"
        />
      });
    }
    else{
      history.push('/publicar_viaje/' + viaje.id);
    }
  };

  return (
    <Fragment>
      <PageTitle title="Viajes publicados"/>
      <Card
        title={<Col span={12}>
          <SearchDinamic 
            module="uvertraccore"
            model="publicacionviaje"
            columnsSearch={ ["codigo_uvertrac", "titulo", "estado"] }
            columnsReturn={ ["*"] }
            orderBy="codigo_uvertrac"
            relationships={ "oferta,oferta.viajes_en_curso" }
            onchange={ response => setViajes(response) }
            onClear={ loadViajes }
          />
        </Col>}
        /*extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}*/
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          expandable={ {
            expandedRowRender: record => 
              <OfertasList 
                ofertas={ record.oferta }
                viaje={ record } 
                aceptarOferta={ ofertaId => OfertasApi.aceptar(ofertaId) }
                rechazarOferta={ ofertaId => OfertasApi.rechazar(ofertaId) }
                loadViajes={ loadViajes }
              />,
            rowExpandable: record => record.oferta?.length > 0
          } }
        />

        <ViajeDetail
          viaje={ selectedViaje }
          visible={ showViajeDetail }
          onClose={ () => setShowViajeDetail(false) }
        />
        <ViajeReject
          viaje={ selectedViaje }
          visible ={ showViajeReject }
          onClose={ () => setShowViajeReject(false) }
          disableViaje={ viajeId => ViajesApi.disable(viajeId) }
          loadViajes={ loadViajes }
        />

      </Card>
    </Fragment>
  );
}

export default ViajePublicadoList;
