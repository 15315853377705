import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import RemolqueForm from './components/form/RemolqueForm';
import RemolqueList from './components/RemolqueList';

const { Content } = Layout;

const RemolquesPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ RemolqueList } />
        <Route path={ `${props.match.path}/new` } component={ RemolqueForm } />
        <Route path={ `${props.match.path}/edit/:id` } component={ RemolqueForm } />
      </Switch>
    </Content>
  );
}

export default RemolquesPage;