import React from 'react';
import { Divider, Drawer, Tag, Alert, Descriptions } from 'antd';
import Parser from '../../../../../helpers/Parser';

const DescItem = Descriptions.Item;
 
const ViajeDetail = props => {
  const getTotal = () => {
    return <Descriptions column={1} size="small">
    { Boolean(Number(props.viaje?.tarifa_por_tonelada))
        ? <DescItem label="Total por Tn"><b>{ Parser.currency(props.viaje?.tarifa_por_tonelada) }</b></DescItem>
        : Boolean(Number(props.viaje?.tarifa_viaje_completo))
          ? <DescItem label="Tarifa Total"><b>{ Parser.currency(props.viaje?.tarifa_viaje_completo) }</b></DescItem>
          : <DescItem label="Costo del viaje"><b>en espera de oferta</b></DescItem> }
    </Descriptions>
  }

  return (
    <Drawer 
      title="Detalle del Viaje Realizado"
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Título del Viaje">{ props.viaje?.publicacion_viaje?.titulo }</DescItem>
        <DescItem label="Fecha de Carga"><Tag color="cyan">{ Parser.datetime(props.viaje?.publicacion_viaje?.fecha_hora_retiro) }</Tag></DescItem>
        <DescItem label="Desde">{ props.viaje?.origen_direccion + ' - ' + props.viaje?.publicacion_viaje?.origen_direccion_google }</DescItem>
        <DescItem label="Hasta">{ props.viaje?.destino_direccion + ' - ' + props.viaje?.publicacion_viaje?.destino_direccion_google }</DescItem>
        <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
        <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
        <DescItem label="Transportista">{ props.viaje?.unidad_transporte?.transportista?.razon_social }</DescItem>
      </Descriptions>

      <Divider />

      <Alert style={ { fontSize: 16} } type="success" message={ getTotal() } />
    </Drawer>
  );
}

export default ViajeDetail;