import { useEffect, useState } from "react";
import { Card, Table, Tag, Button, Tooltip } from "antd";
import { ReloadOutlined } from '@ant-design/icons';

import Parser from "../../../helpers/Parser";
import OfertaEstadoTag from "../../../components/Tags/OfertaEstadoTag";
//import AgregarViajeOfertaDrawer from "./AgregarViajeOfertaDrawer";

const OfertasTable = props => {
  const [ofertas, setOfertas] = useState([]);
  //const [selectedOferta, setSelectedOferta] = useState([]);
  //const [showAgregarViajeOfertaDrawer, setShowAgregarViajeOfertaDrawer] = useState(false);
    
  useEffect(() => {
    setOfertas(props.dashboardInfo?.ofertas?.map(oferta => {
      return {
        id: oferta.id,
        codigo_uvertrac: oferta.codigo_uvertrac,
        condicion_de_pago: oferta.condicion_de_pago,
        cantidad_camiones: oferta.cantidad_camiones,
        estado: oferta.estado,
        tarifa_viaje_completo: oferta.tarifa_viaje_completo,
        viajes_en_curso: oferta.viajes_en_curso
      };
    }));
  }, [props.dashboardInfo]);

  

  const columns = [
    { title: 'Codigo Uvertrac', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Condición de pago', dataIndex: 'condicion_de_pago', key: 'condicion_de_pago' },
    { title: 'Tarifa', dataIndex: 'tarifa_viaje_completo', key: 'tarifa_viaje_completo',
      render: text => <span>{ Parser.currency(text) }</span>
    },
    { title: 'Cantidad camiones solicitados', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center',
      render: text => <Tag color="green">{ text == '1' ? text + ' camión' : text + ' camiones' }</Tag>
    },
    { title: 'Cantidad viajes en curso', key: 'viajes_en_curso', align: 'center',
      render: (text, record) => <Tag color="green">{ record.viajes_en_curso.length == 1 ? record.viajes_en_curso.length + ' viaje' : record.viajes_en_curso.length + ' viajes' }</Tag>
    },
    { title: 'Estado de la Oferta', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <OfertaEstadoTag estado={ text }/>
    },
    /*{ title: '', render: (_, record) => record.estado === 'ACEPTADA'
      ? <Fragment key={ record.id }>
        <Tooltip title={ record.cantidad_camiones > (record.viajes_en_curso ? record.viajes_en_curso.length : 0) ? '' : 'La cantidad de camiones ya fue cubierta' }>
          <Button icon={ <PlusOutlined /> } size={ 'small' } type="primary" 
            disabled={ record.cantidad_camiones <= (record.viajes_en_curso ? record.viajes_en_curso.length : 0) }
            onClick={ () => { setSelectedOferta(record.id); setShowAgregarViajeOfertaDrawer(true); } } 
          >
            { 'Agregar Viaje' }
          </Button>
        </Tooltip>
        {' '}
      </Fragment>
      : '' },*/
  ];

  return (
    <Card title="Ofertas Realizadas"
      extra={ <Tooltip title="Actualizar" placement="topRight">
                <Button type="primary" icon={ <ReloadOutlined />} onClick={ () => props.loadDashboard()}/>
              </Tooltip>}>
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ props.working }
        columns={ columns } 
        dataSource={ ofertas }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
      />

      {/*<AgregarViajeOfertaDrawer
        visible={ showAgregarViajeOfertaDrawer }
        onClose={ () => setShowAgregarViajeOfertaDrawer(false) }
        ofertaId={ selectedOferta }
        getUnidadesTransporteNoAsignadas={ props.getUnidadesTransporteNoAsignadas }
        getOferta={ props.getOferta }
        asignarUnidadesTransporteOferta={ props.asignarUnidadesTransporteOferta }
        loadDashboard={ props.loadDashboard }
      />*/}
    </Card>
  );
}

export default OfertasTable;