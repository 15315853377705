import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Tag, Col, Space, Tooltip, Button, Row, Popconfirm } from 'antd';
import { RollbackOutlined, EditOutlined } from '@ant-design/icons';
import PageTitle from '../../../../components/PageTitle';
import Search from '../../../../components/Search';
import ViajeDetail from './components/ViajeDetail';
import VerButton from '../../../../components/Buttons/VerButton';
import Parser from '../../../../helpers/Parser';
import Patente from '../../../../components/Patente';
import TransportistasApi from '../../../../api/transportistasApi';
import NroCartaPorteModal from './components/NroCartaPorteModal';
import ViajesApi from '../../../../api/viajesApi';
import AppNotification from '../../../../components/AppNotification';
import ViajeEstadoTag from '../../../../components/Tags/ViajeEstadoTag';
import Consts from '../../../../helpers/Consts';

const ViajeRealizadoList = props => {
  const [viajes, setViajes] = useState([]);
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);
  const [showCartaPorteModal, setShowCartaPorteModal] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadViajes();
  }, []);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' ,},
    { title: 'Titulo', dataIndex: ['publicacion_viaje','titulo'], key: 'titulo' },
    { title: 'Fecha', dataIndex: ['publicacion_viaje', 'fecha_hora_retiro'], key: 'fecha_carga', align: 'center',

      render: text => <span>{ Parser.datetime(text)}</span>

    },
    { title: 'Unidad de Transporte', dataIndex: 'unidad_transporte', key: 'unidad_transporte',
      render: (text, record) => {
        let unidad = record.unidad_transporte;
        return <Row>
          <Col span={24}>{ unidad?.chofer?.razon_social }</Col>
          <Col span={12}>{ unidad?.camion?.patente ? <Patente patente={ unidad.camion.patente } style={ { width: 120 } }/> : '' }</Col>
          <Col span={12}>{ unidad?.remolque?.patente ? <Patente patente={ unidad.remolque.patente } remolque={ true } style={ { width: 120 } } /> : '' }</Col>
        </Row>
      }
    },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <ViajeEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedViaje(record); setShowViajeDetail(true); } }/>
          <Tooltip title="Nº de CTG" placement="topRight">
            <Button shape="circle" icon={ <EditOutlined /> } size="small"
              onClick={ () => { setSelectedViaje(record); setShowCartaPorteModal(true); } }
            />
          </Tooltip>
          { record.estado === Consts.EstadoViaje.TERMINADO
              ? <Tooltip title="Volver viaje al estado 'VIAJANDO'">
              <Popconfirm title="¿Está seguro?" okText="Si" cancelText="No"
                onConfirm={ () => handleReactivar(record.id) }
              >
                <Button icon={ <RollbackOutlined /> } size="small" type="primary">Activar</Button>
              </Popconfirm>
            </Tooltip>
              : '' }
        </Space>
      }
    }
  ];

  const loadViajes = () => {
    setWorking(true);
    TransportistasApi.getViajesRealizados()
      .then(response => {
        setWorking(false);
        setViajes(response);
      })
      .catch(error => setWorking(false));
  }

  const handleReactivar = id => {
    setWorking(true);
    ViajesApi.reactivarViaje(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Viaje reactivado correctamente');
        loadViajes();
      })
      .catch(error => setWorking(false));
  }

  return (
    <Fragment>
      <PageTitle title="Viajes Realizados"/>
      <Card
        /*title={<Col span={12}>
          <Search 
            module="uvertraccore"
            model="publicacionviaje"
            columnsSearch={ ["codigo_uvertrac", "titulo", "estado"] }
            columnsReturn={ ["*"] }
            orderBy="codigo_uvertrac"
            relationships={ "oferta,oferta.viajes_en_curso" }
            onSearch={ response => setViajes(response) }
            onClear={ loadViajes }
          />
        </Col>}*/
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          
        />

        <ViajeDetail
          viaje={ selectedViaje }
          visible={ showViajeDetail }
          onClose={ () => setShowViajeDetail(false) }
        />

        <NroCartaPorteModal
          viaje={ selectedViaje }
          visible={ showCartaPorteModal }
          onClose={ () => setShowCartaPorteModal(false) }
          loadViajes={ loadViajes }
        />
      </Card>
    </Fragment>
  );
}

export default ViajeRealizadoList;
