import { Fragment, useEffect, useState } from "react";
import { Button, Card, Table, Tag, Tooltip } from "antd";
import { PlusOutlined , ReloadOutlined} from '@ant-design/icons';
import Parser from "../../../helpers/Parser";
import OfertaEstadoTag from "../../../components/Tags/OfertaEstadoTag";

const OfertasTable = props => {
  const [ofertas, setOfertas] = useState([]);
    
  useEffect(() => {
    let ofertasViajes = [];
    props.dashboardInfo?.viajes_publicados?.map(viajePublicado => 
      viajePublicado.oferta?.map(oferta => 
        ofertasViajes.push({
          id: oferta.id,
          codigo_uvertrac: oferta.codigo_uvertrac,
          condicion_de_pago: oferta.condicion_de_pago,
          cantidad_camiones: oferta.cantidad_camiones,
          estado: oferta.estado,
          tarifa_viaje_completo: oferta.tarifa_viaje_completo
        })
      )
    )
    setOfertas(ofertasViajes);
  }, [props.dashboardInfo]);

  const columns = [
    { title: 'Codigo Uvertrac', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Condicion de pago', dataIndex: 'condicion_de_pago', key: 'condicion_de_pago', align: 'center' },
    { title: 'Tarifa', dataIndex: 'tarifa_viaje_completo', key: 'tarifa_viaje_completo',
      render: text => <span>{ Parser.currency(text) }</span>
    },
    { title: 'Cantidad camiones solicitados', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center',
      render: text => <Tag color="green">{ text == '1' ? text + ' camión' : text + ' camiones' }</Tag>
    },
    { title: 'Estado de la Oferta', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <OfertaEstadoTag estado={ text }/>
    },
  ];

  return (
    <Card title="Ofertas"
    extra={ <Tooltip title="Actualizar" placement="topRight">
                <Button type="primary" icon={ <ReloadOutlined />} onClick={ () => props.loadDashboard() }/>
            </Tooltip>}
    >
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ props.working }
        columns={ columns } 
        dataSource={ ofertas }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        //onRow={ record => ({ onClick: () => { setSelectedRow(record.id); }}) }
      />
    </Card>
  );
}

export default OfertasTable;