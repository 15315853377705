import React, { Fragment } from 'react';
import { Divider, Drawer, Tag, Alert, Row, Col, Descriptions } from 'antd';
import Parser from '../../../helpers/Parser';
import Map from '../../../components/Map';

const DescItem = Descriptions.Item;
 
const CargaDadorDetail = props => {
  const getMessage = (viajesSolicitados, viajesTomados) => {
    return viajesSolicitados === 1 && viajesTomados === 0
      ? "Un camion solicitado."
      : viajesSolicitados === viajesTomados
        ? 'Todos los viajes solicitados están tomados.'
        : viajesSolicitados > 1 && viajesTomados === 0
          ? `Camiones solicitados: ${viajesSolicitados}.`
          : viajesSolicitados > 1 && viajesTomados > 0
            ? `Sobre un total de ${viajesSolicitados} camiones solicitados, se tomaron ${viajesTomados}.`
            : '';
  }

  const getTotal = () => {
    return <Descriptions column={1} size="small">
    { Boolean(Number(props.viaje?.tarifa_por_tonelada))
        ? <DescItem label="Total por Tn"><b>{ Parser.currency(props.viaje?.tarifa_por_tonelada) }</b></DescItem>
        : Boolean(Number(props.viaje?.tarifa_viaje_completo))
          ? <DescItem label="Tarifa Total"><b>{ Parser.currency(props.viaje?.tarifa_viaje_completo) }</b></DescItem>
          : <DescItem label="Costo del viaje"><b>en espera de oferta</b></DescItem> }
    </Descriptions>
  }

  const ofertas = props.viaje?.oferta.flatMap(x => x);
  const viajesSolicitados = props.viaje?.cantidad_camiones;
  const viajesTomados = ofertas?.reduce((a, b) => a + b.viajes_en_curso.length, 0);
  const message = getMessage(viajesSolicitados, viajesTomados);
  

  return (
    <Drawer 
      title="Detalle del Viaje"
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <div style={ { height: 200 } }>
        { props.visible
          ? <Map routes={ {
            origin: {
              lat: props.viaje?.origen_latitud,
              lng: props.viaje?.origen_longitud
            },
            destination: {
              lat: props.viaje?.destino_latitud,
              lng: props.viaje?.destino_longitud
            }
          } }/>
          : '' }
      </div>
     
      <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
        <DescItem label="Fecha de Carga"><Tag color="cyan">{ Parser.datetime(props.viaje?.fecha_hora_retiro) }</Tag></DescItem>
        <DescItem label="Desde">{ props.viaje?.origen_direccion }</DescItem>
        <DescItem label="Hasta">{ props.viaje?.destino_direccion }</DescItem>
        <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
        <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
      </Descriptions>

      <Divider />

      <Row>
        <Col span={ 24 }><Alert message={ <div>{ message }</div> } type="error" /></Col>
      </Row>
      
      <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Este viaje posee">
          <Tag color="cyan"><b>{ props.viaje?.oferta.length }</b> { props.viaje?.oferta.length > 1 ? "ofertas activas" : "oferta activa" }</Tag>
        </DescItem>
      </Descriptions>

      <Divider />

    </Drawer>
  );
}

export default CargaDadorDetail;