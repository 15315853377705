import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UnidadesTransporteApi from '../../../api/unidadesTransporteApi';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import AppNotification from '../../../components/AppNotification';
import Patente from '../../../components/Patente';

const UnidadTransporteList = props => {
  const history = useHistory();
  const [unidadesTransporte, setUnidadesTransporte] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadUnidadesTransporte();
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: ['chofer', 'razon_social'] },
    { title: 'Cuit Chofer', dataIndex: ['chofer', 'cuit'] }, 
    { title: 'Patente Camión', dataIndex: ['camion', 'patente'], width: 150, align: 'center',
      render: text => <Patente patente={ text }/>
    },
    { title: 'Patente Remolque', dataIndex: ['remolque', 'patente'], width: 150, align: 'center',
      render: text => text ? <Patente patente={ text } remolque={ true }/> : ''
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          {/*<EditarButton onClick={ () => { history.push(history.location.pathname + '/edit/' + record.id) } }/>*/}
          <EliminarButton popTitle="¿Está seguro de querer eliminar el unidad de transporte?" onConfirm={ () => handleDelete(record.id) }/>
        </Space>
      }
    }
  ];

  const loadUnidadesTransporte = () => {
    setWorking(true);
    UnidadesTransporteApi.get()
      .then(response => {
        setWorking(false);
        setUnidadesTransporte(response);
      })
      .catch(error => setWorking(false));
  }
  
  const handleDelete = id => {
    setWorking(true);
    UnidadesTransporteApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Unidad de transporte eliminada correctamente');
        loadUnidadesTransporte();
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Unidades de Transporte"/>
      <Card
        /*title={<Col span={12}>
          <Search 
            module="uvertraccore"
            model="unidadTransporte"
            columnsSearch={ ['apellido','razon_social'] }
            columnsReturn={ columns }
            onchange={ response => setUnidadesTransporte(response) }
            onClear={ loadUnidadesTransporte }
          />
        </Col>}*/
        extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ unidadesTransporte }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>
    </Fragment>
  );
}

export default UnidadTransporteList;
