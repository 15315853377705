import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form } from 'antd';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MapItem from './components/MapItem';
import AutocompleteItem from './components/AutoCompleteItem';
import './MapFormItem.scss';

const FormItem = Form.Item;

const MapFormItem = props => {
  const [address, setAddress] = useState('');
  const [marker, setMarker] = useState(undefined);
  const [center, setCenter] = useState(undefined);

  useEffect(() => {
    if(props.initialValue){
      handleSelect(props.initialValue);
    }
  }, [props.initialValue]);

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = address => {
    setAddress(address);
    props.form.setFieldsValue({ [props.name]: address});

    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        let address = '';
        if (place.address_components) {
          address = [
            (place.address_components[1] && place.address_components[1].short_name || null),
            (place.address_components[0] && place.address_components[0].short_name || null),
            (place.address_components[2] && place.address_components[2].short_name || null),
            (place.address_components[3] && place.address_components[3].short_name || null),
            (place.address_components[4] && place.address_components[4].short_name || null),
            (place.address_components[5] && place.address_components[5].short_name || null),
          ].filter(Boolean).join(', ');
        }

        const markerData = {
          address: address,
          address_components: place.address_components,
          position: place.geometry.location,
        };

        setMarker(markerData);
        setCenter(place.geometry.location);
        if(props.onMarkerChanged) { props.onMarkerChanged(markerData); }
      })
      .catch(error => console.error('Error', error));
  };

  const handleMapDoubleClick = e => {
    let newPosition = e.latLng;

    let promise = new Promise(resolve => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        let address = '';
        let address_components = null;
        
        if (status !== window.google.maps.GeocoderStatus.OK) {
          resolve({ adress: 'Dirección inválida [' + status + ']', address_components});
        }

        if (!results || !results[0]) {
          resolve({ adress: 'No se encuentraron resultados', address_components});
        }

        if (!results[0].address_components) {
          resolve({ adress: results[0].formatted_address, address_components});
        }

        const place = results[0];
        address = [
          (place.address_components[1] && place.address_components[1].short_name || null),
          (place.address_components[0] && place.address_components[0].short_name || null),
          (place.address_components[2] && place.address_components[2].short_name || null),
          (place.address_components[3] && place.address_components[3].short_name || null),
          (place.address_components[4] && place.address_components[4].short_name || null),
          (place.address_components[5] && place.address_components[5].short_name || null),
        ].filter(Boolean).join(', ');
        address_components = results[0].address_components;
        resolve({address, address_components});
      });
    });

    promise.then(addresses => {
      const markerData = {
        address: addresses.address,
        address_components: addresses.address_components,
        position: newPosition,
      };

      setAddress(markerData.address);
      props.form.setFieldsValue({ [props.name]: markerData.address});
      setMarker(markerData);
      if(props.onMarkerChanged) { props.onMarkerChanged(markerData); }
    });
  }

  return (
    <Fragment>
      <FormItem 
        { ...props }
      >
        <AutocompleteItem 
          value={ address }
          onChange={ handleChange }
          onSelect={ handleSelect }
        >
        </AutocompleteItem>
      </FormItem>

      <FormItem label=" " colon={ false }>
        <MapItem
          containerElement={ <div style={ props.mapStyle ? props.mapStyle : { height: 400 }} /> }
          mapElement={ <div style={{ height: '100%' }} /> }
          loadingElement={<div style={{ height: '100%' }} />}
          handleMapDoubleClick={ handleMapDoubleClick }
          marker={ marker }
          center={ center }
        />
      </FormItem>
    </Fragment>
  );
};

export default MapFormItem;