import siteLogo from '../assets/uvertracLogo.png';
import packageJson from '../../package.json';
import 'moment/locale/es';

const siteConfig = {
  //baseName: '/',
  siteLogo: siteLogo,
  footerText: 'Uvertrac - Gestion de Cargas - Versión ' + packageJson.version
};

const mapsConfig = {
  gmapsKey: 'AIzaSyCEIdGj-Ki2URrGU4jUgdwDDjgSLlGj2a4'
};

const momentConfig = {
  tz: 'America/Argentina/Buenos_Aires',
  locale: 'es'
};

export { siteConfig, mapsConfig, momentConfig };