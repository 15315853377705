import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Layout, Row, Col, Card, Button, Form, Spin, Input, Select, Alert } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import UnidadesTransporteApi from '../../../api/unidadesTransporteApi';
import AppNotification from '../../../components/AppNotification';
import UIHelper from '../../../helpers/UIHelper';
import PageTitle from '../../../components/PageTitle';
import basicStyle from '../../../themes/basicStyle';
import UvertracApi from '../../../api/uvertracApi';

const { Content } = Layout;
const FormItem = Form.Item;
const { Option } = Select;

const UnidadTransporteForm = props => {
  const history = useHistory();
  const [unidadTransporte, setUnidadTransporte] = useState(undefined);
  const [choferes, setChoferes] = useState([]);
  const [camiones, setCamiones] = useState([]);
  const [remolques, setRemolques] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    loadChoferes();
    loadCamiones();
    loadRemolques();
    if(history.location.pathname.indexOf('edit') > -1){
      loadUnidadTransporte();
    }
  }, []);

  const loadChoferes = () => UvertracApi.get({ 
    model: 'Chofer', 
    colReturn: ['id','cuit','razon_social'], 
    colSearch: 'id::=::whereNotInAnotherModel->UnidadTransporte->chofer_id',
    colOrderBy: 'razon_social',
    JWTFilter: 'ENTITY.transportista_id=TOKEN.parent_entity_id'
  }).then(response => setChoferes(response.data));
  const loadCamiones = () => UvertracApi.get({ 
    model: 'Camion', 
    colReturn: ['id','patente'], 
    colSearch: 'id::=::whereNotInAnotherModel->UnidadTransporte->camion_id',
    colOrderBy: 'patente',
    JWTFilter: 'ENTITY.transportista_id=TOKEN.parent_entity_id'
  }).then(response => setCamiones(response.data));
  const loadRemolques = () => UvertracApi.get({ 
    model: 'Remolque', 
    colReturn: ['id','patente'], 
    colSearch: 'id::=::whereNotInAnotherModel->UnidadTransporte->remolque_id',
    colOrderBy: 'patente',
    JWTFilter: 'ENTITY.transportista_id=TOKEN.parent_entity_id'
  }).then(response => setRemolques(response.data));

  const loadUnidadTransporte = () => {
    setWorking(true);
    UnidadesTransporteApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setUnidadTransporte(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);
    let data = { 
      id: unidadTransporte?.id,
      ...values,
    }
    UnidadesTransporteApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Unidad de Transporte guardada correctamente');
        history.goBack();
      })
      .catch(error => { setWorking(false); });
  };

  return (
    <Content>
      <PageTitle title={ `${unidadTransporte?.id ? 'Editar' : 'Nueva'} Unidad de Transporte` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card
          title={
            <Alert type="warning" showIcon message="Advertencia"
              description="Los Choferes, Camiones o Remolques que ya esten asignados a una Unidad de Transporte no aparecerán listados."
            /> }
        >
          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            initialValues={ { ...unidadTransporte } }
            scrollToFirstError
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                <FormItem name="chofer_id" label="Chofer" hasFeedback rules={ UIHelper.defaultRules } autoComplete="off">
                  <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
                    { choferes.map(chofer => (<Option key={ chofer.id } value={ chofer.id }>{ chofer.razon_social + ' - ' + chofer.cuit }</Option>)) }
                  </Select>
                </FormItem>
                <FormItem name="camion_id" label="Camión" hasFeedback rules={ UIHelper.defaultRules } autoComplete="off">
                  <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
                    { camiones.map(camion => (<Option key={ camion.id } value={ camion.id }>{ camion.patente }</Option>)) }
                  </Select>
                </FormItem>
                <FormItem name="remolque_id" label="Remolque" hasFeedback rules={ UIHelper.defaultRules [{ required: false}]} autoComplete="off"  >
                  <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
                    { remolques.map(remolque => (<Option key={ remolque.id } value={ remolque.id }>{ remolque.patente }</Option>)) }
                  </Select>
                </FormItem>
                
              </Col>
              
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
              </FormItem>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Content>
  );
}

export default UnidadTransporteForm;
