import React, { Fragment } from 'react';
import { Descriptions, Divider, Drawer, Tag } from 'antd';
import './appRightSideBar.scss';

const DescItem = Descriptions.Item;

const AppRightSideBar = props => {
  return (
    <Drawer
      title="Detalle del usuario"
      placement="right"
      closable={ true }
      visible={ props.collapsed }
      width={ props.width ? props.width : 200 }
      onClose={ props.handleOnClose }
    >
      <Descriptions column={ 1 } bordered size="small"
        labelStyle={ { fontSize: 12 } }
      >
        <DescItem label="Usuario"><Tag color="cyan"><b>{ props.user?.email }</b></Tag></DescItem>
        <DescItem label="Nombre y Apellido"><b>{ `${props.user?.nombre} ${props.user?.apellido}` }</b></DescItem>
        <DescItem label="Razon Social"><b>{ props.user?.razonSocial }</b></DescItem>
        <DescItem label="Dirección"><b>{ props.user?.direccion }</b></DescItem>
        <DescItem label="Rol"><b>{ props.user?.role }</b></DescItem>
      </Descriptions>
    </Drawer>
  );
}

export default AppRightSideBar;
