import { HomeOutlined, UserOutlined, 
  DollarOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
  CreditCardOutlined, AliwangwangOutlined, DatabaseOutlined
} from '@ant-design/icons';
import AuthHelper from '../../../helpers/AuthHelper';
import DashboardTransportistaPage from '../../dashboard-transportistas/DashboardTransportistaPage';
import ViajesPublicadosTransportistasPage from '../../viajes-publicados-transportistas/ViajesPublicadosTransportistasPage';
import OfertasRealizadasTransportistasPage from '../../ofertas-realizadas-transportistas/OfertasRealizadasTransportistasPage';
import ViajesEnCursoTransportistasPage from '../../viajes-en-curso-transportistas/ViajesEnCursoTransportistasPage';
import ViajesRealizadosTransportistasPage from '../../viajes-realizados-transportistas/ViajesRealizadosTransportistasPage';
import ChoferesPage from '../../choferes/ChoferesPage';
import CamionesPage from '../../camiones/CamionesPage';
import RemolquesPage from '../../remolques/RemolquesPage';
import UnidadesTransportePage from '../../unidades-transporte/UnidadesTransportePage';

const routesTransportistas = [
  {
    permission: "tr_dashboard_page.visualizar_left_menu",
    key: "inicio-transportista",
    breadcrumbName: "Inicio",
    path: "/inicio-transportista",
    menu: true,
    icon: {
      type: <HomeOutlined />,
      title: "Inicio"
    },
    component: AuthHelper.requireAuth(DashboardTransportistaPage)
  },
  {
    permission: "tr_buscar_viajes_page.visualizar_left_menu",
    key: "viajes_publicados_transportista",
    breadcrumbName: "Viajes Publicados",
    path: "/viajes-publicados-transportista",
    menu: true,
    icon: {
      type: <DollarOutlined />,
      title: "Viajes Publicados"
    },
    component: AuthHelper.requireAuth(ViajesPublicadosTransportistasPage)
  },
  {
    permission: "tr_buscar_viajes_page.visualizar_left_menu",
    key: "ofertas_realizadas_transportista",
    breadcrumbName: "Ofertas Realizadas",
    path: "/ofertas-realizadas-transportista",
    menu: true,
    icon: {
      type: <DollarOutlined />,
      title: "Ofertas Realizadas"
    },
    component: AuthHelper.requireAuth(OfertasRealizadasTransportistasPage)
  },
  {
    permission: "tr_viajes_en_curso_page.visualizar_left_menu",
    key: "viajes_en_curso_transportista",
    breadcrumbName: "Buscar Viajes",
    path: "/viajes-en-curso-transportista",
    menu: true,
    icon: {
      type: <MenuUnfoldOutlined />,
      title: "Viajes en Curso"
    },
    component: AuthHelper.requireAuth(ViajesEnCursoTransportistasPage)
  },
  
  {
    permission: "tr_historial_page.visualizar_left_menu",
    key: "historial_viajes_transportista",
    breadcrumbName: "Viajes Realizados",
    path: "/historial-viajes-transportista",
    menu: true,
    icon: {
      type: <MenuFoldOutlined />,
      title: "Viajes Realizados"
    },
    component: AuthHelper.requireAuth(ViajesRealizadosTransportistasPage)
  },
  // {
  //   permission: "tr_mi_flota_page.visualizar_left_menu",
  //   key: "mi_flota",
  //   breadcrumbName: "Mi Flota",
  //   path: "/mi-flota",
  //   menu: true,
  //   icon: {
  //     type: "car",
  //     title: "Mi Flota"
  //   },
  //   component: AuthHelper.requireAuth(MiFlotaPage)
  // },
  {
    permission: "tr_mi_flota_page.visualizar_left_menu",
    key: "choferes",
    breadcrumbName: "Choferes",
    path: "/choferes",
    menu: true,
    icon: {
      type: <UserOutlined />,
      title: "Choferes"
    },
    component: AuthHelper.requireAuth(ChoferesPage)
  },
  {
    permission: "tr_mi_flota_page.visualizar_left_menu",
    key: "camiones",
    breadcrumbName: "Camiones",
    path: "/camiones",
    menu: true,
    icon: {
      type: <CreditCardOutlined />,
      title: "Camiones"
    },
    component: AuthHelper.requireAuth(CamionesPage)
  },
  {
    permission: "tr_mi_flota_page.visualizar_left_menu",
    key: "remolques",
    breadcrumbName: "Remolques",
    path: "/remolques",
    menu: true,
    icon: {
      type: <DatabaseOutlined />,
      title: "Remolques"
    },
    component: AuthHelper.requireAuth(RemolquesPage)
  },
  {
    permission: "tr_mi_flota_page.visualizar_left_menu",
    key: "unidad_de_transporte",
    breadcrumbName: "Unidad de Transporte",
    path: "/unidad-de-transporte",
    menu: true,
    icon: {
      type: <AliwangwangOutlined />,
      title: "Unidad de Transporte"
    },
    component: AuthHelper.requireAuth(UnidadesTransportePage)
  },
];

export default routesTransportistas;
