import React, { Fragment, useEffect, useState } from 'react';

import { Layout, Card, Form, Spin } from 'antd';
import AppNotification from '../../../../components/AppNotification';
import { useHistory } from 'react-router';
import PageTitle from '../../../../components/PageTitle';
import CamionesApi from '../../../../api/camionesApi';
import StepsForm from '../../../../components/StepsForm';
import moment from 'moment';
import DatosCamionStep from './components/DatosCamionStep';
import DocumentacionStep from './components/DocumentacionStep';
import CamionEditForm from './components/CamionEditForm';
import ConfirmacionStep from './components/ConfirmacionStep';

const { Content } = Layout;

const CamionForm = props => {
  const history = useHistory();
  const [camion, setCamion] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    if(history.location.pathname.indexOf('edit') > -1){
      loadCamion();
    }
  }, []);

  const steps = [
    { title: 'Datos del Camión', content: DatosCamionStep },
    { title: 'Documentación', content: DocumentacionStep },
    { title: 'Confirmación', content: ConfirmacionStep }
  ];

  const loadCamion = () => {
    setWorking(true);
    CamionesApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setCamion(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);

    if(!camion?.id){
      let formData = new FormData();
      for (let key in values) {
        if(values[key]){
          const dashedKey = key.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
          if (values[key] instanceof moment) { formData.set(dashedKey, values[key].format("YYYY-MM-DD")); }
          else if (values[key] instanceof File) { formData.append(dashedKey, values[key]); }
          else { formData.set(dashedKey, values[key]); }
        }
      }
      saveCamion(formData);
    }
    else{
      let data = {
        id: camion.id,
        patente: values.patente,
        fecha_de_vencimiento_ruta: values.fecha_de_vencimiento_ruta?.format("YYYY-MM-DD"),
        fecha_de_vencimiento_seguro: values.fecha_de_vencimiento_seguro?.format("YYYY-MM-DD"),
        fecha_de_vencimiento_vtv: values.fecha_de_vencimiento_vtv?.format("YYYY-MM-DD")
      };

      let cedulaVerdeFormData = null;
      if (Array.isArray(values.cedula_verde) && values.cedula_verde.length === 1) {
        if(values.cedula_verde[0] instanceof File){
          cedulaVerdeFormData = new FormData();
          cedulaVerdeFormData.set('id', camion.id);
          cedulaVerdeFormData.append('cedula_verde', values.cedula_verde[0]);
        }
      }
      let rutaFormData = null;
      if (Array.isArray(values.ruta) && values.ruta.length === 1) {
        if(values.ruta[0] instanceof File){
          rutaFormData = new FormData();
          rutaFormData.set('id', camion.id);
          rutaFormData.append('ruta', values.ruta[0]);
        }
      }
      let seguroFormData = null;
      if (Array.isArray(values.seguro) && values.seguro.length === 1) {
        if(values.seguro[0] instanceof File){
          seguroFormData = new FormData();
          seguroFormData.set('id', camion.id);
          seguroFormData.append('seguro', values.seguro[0]);
        }
      }
      let vtvFormData = null;
      if (Array.isArray(values.vtv) && values.vtv.length === 1) {
        if(values.vtv[0] instanceof File){
          vtvFormData = new FormData();
          vtvFormData.set('id', camion.id);
          vtvFormData.append('vtv', values.vtv[0]);
        }
      }

      (cedulaVerdeFormData ? CamionesApi.updateCedulaVerde(camion.id, cedulaVerdeFormData) : Promise.resolve())
      .then(() => (rutaFormData ? CamionesApi.updateRuta(camion.id, rutaFormData) : Promise.resolve()))
      .then(() => (seguroFormData ? CamionesApi.updateSeguro(camion.id, seguroFormData) : Promise.resolve()))
      .then(() => (vtvFormData ? CamionesApi.updateVTV(camion.id, vtvFormData) : Promise.resolve()))
      .then(() => (saveCamion(data)))
      .catch(error => { setResult('error'); setWorking(false); });
    }
  };

  const saveCamion = data => {
    CamionesApi.save(data)
      .then(response => {
        setWorking(false);
        setResult('success');
        AppNotification.showSuccess('Camión guardado correctamente');
        history.goBack();
      })
      .catch(error => { 
        setResult('error');
        setWorking(false); 
      });
  }

  return (
    <Content>
      <PageTitle title={ `${camion?.id ? 'Editar' : 'Nuevo'} Camión` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          { !camion
            ? <StepsForm
              steps={ steps }
              onFinish={ onFinish }
              enablePrevious={ true }
              result={ result }
            />
            : <CamionEditForm
              camion={ camion }
              onFinish={ onFinish }
            />
          }
        </Card>
      </Spin>
    </Content>
  );
}

export default CamionForm;
