import React from 'react';
import { Route } from 'react-router-dom';
import routes from '../routes';

let arrayRoute = [];

const getRoute = route => {
  if (route.withItemGroup) {
    route.withItemGroup.forEach(routeSubmenu => {
      getRoute(routeSubmenu);
    });
  } else {
    if (route.menues) {
      route.menues.forEach(routeMenu => arrayRoute.push(routeMenu));
    } else {
      arrayRoute.push(route);
    }
  }
};

routes.forEach(route => getRoute(route));

const AppRoutes = arrayRoute.map(route =>
  (<Route
    key={ route.key }
    exact={ route.exact ? route.exact : false }
    path={ route.path }
    component={ route.component }
  />)
);

export default AppRoutes;
