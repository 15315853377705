import React, { useState } from 'react';
import { Divider, Drawer, Button, Alert, Tag, Row, Col, Descriptions, Popconfirm, Tooltip, Spin } from 'antd';
import { DislikeOutlined } from '@ant-design/icons';
import Parser from '../../../../../helpers/Parser';
import AppNotification from '../../../../../components/AppNotification';

const DescItem = Descriptions.Item;
 
const ViajeReject = props => {
  const [working, setWorking] = useState(false);

  const getTotal = () => {
    return <Descriptions column={1} size="small">
    { Boolean(Number(props.viaje?.tarifa_por_tonelada))
        ? <DescItem label="Total por Tn"><b>{ Parser.currency(props.viaje?.tarifa_por_tonelada) }</b></DescItem>
        : Boolean(Number(props.viaje?.tarifa_viaje_completo))
          ? <DescItem label="Tarifa Total"><b>{ Parser.currency(props.viaje?.tarifa_viaje_completo) }</b></DescItem>
          : <DescItem label="Costo del viaje"><b>en espera de oferta</b></DescItem> }
    </Descriptions>
  }

  const handleOK = () => {
    setWorking(true);
    props.disableViaje(props.viaje.id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Viaje despublicado correctamente');
        props.loadViajes();
        props.onClose();
      })
      .catch(error => setWorking(false));
  }

  return (
    <Drawer
      title="Detalle de Viaje"
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <Spin spinning={ working}>
        <Alert message={ 'Si despublica este viaje, todas las operaciones relacionadas al mismo seran canceladas' } type="error" />
        
        <Divider />

        <Descriptions column={1} bordered size="small">
          <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
          <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
          <DescItem label="Desde">{ props.viaje?.origen_direccion }</DescItem>
          <DescItem label="Hasta">{ props.viaje?.destino_direccion }</DescItem>
          <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
          <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
        </Descriptions>
        
        <Divider />

        <Descriptions column={1} bordered size="small">
          <DescItem label="Este viaje posee">
            <Tag color="cyan"><b>{ props.viaje?.oferta.length }</b> { props.viaje?.oferta.length > 1 ? "ofertas activas" : "oferta activa" }</Tag>
          </DescItem>
        </Descriptions>

        <Divider />

        <Alert style={ { fontSize: 16} } type="success" message={ getTotal() } />

        <Divider/>

        <Popconfirm
          placement="topRight"
          title={ '¿Está seguro de querer despublicar el viaje?' }
          onConfirm={ handleOK }
          okText="Despublicar"
          cancelText="No"
        >
          <Button type="danger" size="large" icon={ <DislikeOutlined /> } block>
            Despublicar Viaje
          </Button>
        </Popconfirm>
      </Spin>
    </Drawer>
  );
}

export default ViajeReject;