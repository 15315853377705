import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Drawer, Tag, Alert, Row, Col, Descriptions } from 'antd';
import Parser from '../../../../../helpers/Parser';
import Map from '../../../../../components/Map';
import ViajeEstadoTag from '../../../../../components/Tags/ViajeEstadoTag';

const DescItem = Descriptions.Item;
 
const ViajeEnCursoDetail = props => {
  const [markers, setMarkers] = useState([]);
  const [position, setPosition] = useState({});

  useEffect(() => {
    let markersArr = [];

    if(props.viajeEnCurso?.ultima_geoposicion_insertada){
      if (Object.keys(props.viajeEnCurso?.ultima_geoposicion_insertada).length){
        markersArr.push({
          id: props.viajeEnCurso.id,
          codigo_uvertrac: props.viajeEnCurso.codigo_uvertrac,
          estado: props.viajeEnCurso.estado,
          fecha_hora: Parser.datetime(props.viajeEnCurso.ultima_geoposicion_insertada.fecha_hora),
          unidad_transporte: props.viajeEnCurso.unidad_transporte,
          ultima_geoposicion_insertada: {
            latitud: parseFloat(props.viajeEnCurso.ultima_geoposicion_insertada.latitud),
            longitud: parseFloat(props.viajeEnCurso.ultima_geoposicion_insertada.longitud)
          }
        });
      }
    }
    setMarkers(markersArr);

    setPosition({
      lat: markersArr.length
        ? markersArr.length === 1
          ? markersArr[0].ultima_geoposicion_insertada.latitud
          : markersArr[markersArr.length - 1].ultima_geoposicion_insertada.latitud
        : -31.3992876,
      lng: markersArr.length
        ? markersArr.length === 1
          ? markersArr[0].ultima_geoposicion_insertada.longitud
          : markersArr[markersArr.length - 1].ultima_geoposicion_insertada.longitud
        : -64.2643838
    });
  }, [props.viaje, props.viajeEnCurso]);

  const generateMapMarkers = () => {
    let mapMarkers = [];

    markers.map(marker => {
      let mapMarker = {
        id: marker.id,
        text: marker.codigo_uvertrac,
        lat: marker.ultima_geoposicion_insertada.latitud,
        lng: marker.ultima_geoposicion_insertada.longitud,
        info: [
          { key: 'Estado', value: marker.estado },
          { key: 'Fecha', value: marker.fecha_hora },
          { key: 'Patente', value: marker.unidad_transporte.camion.patente },
          { key: 'Chofer', value: marker.unidad_transporte.chofer.razon_social },
          { key: 'Cuit', value: marker.unidad_transporte.chofer.cuit  }
        ]
      };
      mapMarkers.push(mapMarker);
    });

    return mapMarkers;
  }

  return (
    <Drawer 
      title={ <span>Viaje: <Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></span> }
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <div style={ { height: 200 } }>
        { props.visible
          ? <Map
            defaultLat={ position.lat }
            defaultLng={ position.lng }
            defaultZoom={ markers.length == 1 ? 6 : 4 }
            markers={ generateMapMarkers() }
            selectedMarker={ -1 }
          />
          : '' }
        { markers.length == 0
          ? <Alert type="warning" message="No se pudo recuperar información" />
          : '' }
      </div>

      <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
        <DescItem label="Desde">{ props.viaje?.origen_direccion }</DescItem>
        <DescItem label="Hasta">{ props.viaje?.destino_direccion }</DescItem>
        <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
        <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
      </Descriptions>
      
      <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Código del viaje"><Tag color="cyan"><b>{ props.viajeEnCurso?.codigo_uvertrac }</b></Tag></DescItem>
        <DescItem label="Chofer"><b>{ props.viajeEnCurso?.unidad_transporte.chofer.razon_social }</b></DescItem>
        <DescItem label="Estado"><ViajeEstadoTag estado={ props.viajeEnCurso?.estado.replace('_', ' ').toUpperCase() }/></DescItem>
      </Descriptions>
    </Drawer>
  );
}

export default ViajeEnCursoDetail;