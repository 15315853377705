import React from 'react';
import { Alert, Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import basicStyle from '../../../../../themes/basicStyle';
import CuitFormItem from '../../../../../components/FormItems/CuitFormItem';
import AppNotification from '../../../../../components/AppNotification';
import UIHelper from '../../../../../helpers/UIHelper';
import ImageFormItem from '../../../../../components/FormItems/ImageFormItem';
import moment from 'moment';

const FormItem = Form.Item;

const ChoferEditForm = props => {
  const [form] = Form.useForm();

  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      onFinish={ props.onFinish }
      initialValues={ { ...props.chofer,
        fecha_de_vencimiento_licencia: props.chofer?.fecha_de_vencimiento_licencia ? moment(props.chofer.fecha_de_vencimiento_licencia) : null,
        licencia_de_conducir: props.chofer?.entityMedia?.licencia_de_conducir ? [props.chofer?.entityMedia?.licencia_de_conducir] : []
      } }
      scrollToFirstError
    >
      <Row gutter={ [16,0] }>
        <Col span={ 24 }>
          <CuitFormItem
            id={ props.chofer?.id }
            form={ form }
            onCuitExistente={ response => {
              if (response.choferExistente){
                AppNotification.showInfo(`Un chofer posee el mismo CUIT. `);
              }
              return response.choferExistente;
            } }
          />
          <FormItem name="telefono_celular" label="Teléfono" rules={ UIHelper.defaultRules }>
            <Input maxLength={10} />
          </FormItem>
          <FormItem name="fecha_de_vencimiento_licencia" label="Fecha de vencimiento de licencia">
            <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
              disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
            />
          </FormItem>
          <ImageFormItem form={ form } name="licencia_de_conducir" label="Licencia de conducir"
            extra={ props.chofer?.entityMedia?.licencia_de_conducir?.aprobada === false 
              ? <Alert type="warning" message={ <small>La imagen no fue aprobada todavia</small> } style={ { padding: '4px 8px' } }/>
              : '' }
          />
        </Col>
        
      </Row>

      <Row justify="end">
        <FormItem>
          <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
        </FormItem>
      </Row>
    </Form>
  );
}

export default ChoferEditForm;