import axios from 'axios';
import AuthHelper from './AuthHelper';
import AppNotification from '../components/AppNotification';
import Parser from './Parser';
import AuthApi from '../api/authApi';

axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/`;

axios.interceptors.request.use(config => {
  config.headers = AuthHelper.isJwtTokenStored() ? { ...config.headers, Authorization: `Bearer ${AuthHelper.getJwtToken()}` } : config.headers;
  config.headers['Access-Control-Allow-Origin'] = 'test.uvertrac.com';
  config.headers['Access-Control-Allow-Methods'] = 'GET,POST,PUT,OPTIONS,HEAD';
  return config;
});

axios.interceptors.response.use(
  response => {
    if(response.data.ValidationErrors){
      for (const validationType in response.data.ValidationErrors) {
        const validationMessage = response.data.ValidationErrors[validationType]; 
        AppNotification.showError(validationMessage);
      }
      throw response;
    }
    return response;
  },
  error => {
    console.debug('ERROR: ' + error);

    //RESPUESTAS BLOB
    if (error.response &&
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ){
      return Parser.parseDownloadError(error)
        .then(err => {
          let message = Parser.parseError(err.Message);
          AppNotification.showError(message);
          return Promise.reject(err)
        });
    }

    //RESPUESTAS DE LA API
    if(error.response){
      let message = Parser.parseError(error);
      message = message.length > 400 ? message.substring(0, 400) + '...' : message;

      switch(error.response.status){
        case 401:
          console.debug(window.location.pathname);
          if(window.location.pathname !== '/' && window.location.pathname !== '/login'){
            AuthApi.logout().then(() => window.location.reload());
          }
          AppNotification.showError(message);
          break;
        case 404:
          message = '[Endpoint No encontrado] ' + error.response.config.url;
          AppNotification.showError(message);
          break;
        case 504:
          message = 'No se obtuvo respuesta del servidor';
          AppNotification.showError(message);
          break;
        default: 
        AppNotification.showError(message);
      }
      return Promise.reject(error);

      /*if (error.response.status === 401) {
        AuthHelper.removeToken();
        window.location.reload();
        return Promise.reject(error);
      }

      if (error.response.status === 500) {
        let message;
        try {
          message = error.response.data.error ? error.response.data.error : 'Ocurrio un error...';
        } catch (e) {
          message = 'Ocurrio un error...';
        }
        notification['error']({
          message: 'Error',
          description: message,
        });
        return Promise.reject(error);
      }*/
    }
    
    //OTRAS RESPUESTAS
    let message = Parser.parseError(error.message);
    AppNotification.showError(message);
    return Promise.reject(error);
  }
);

export default class Fetcher {
  static get(path, dispatch) {
    return axios.get(path, { dispatch });
  }

  static getWithParams(path, params) {
    return axios.get(path, { params: params });
  }

  static post(path, data = {}, dispatch) {
    return axios.post(path, data, { dispatch });
  }

  static postMultipart(path, data = {}, dispatch) {
    return axios.post(path, data, { dispatch, headers: {'Content-Type': `multipart/form-data; boundary=${data._boundary}` } });
  }

  static putMultipart(path, data = {}, dispatch) {
    return axios.put(path, data, { dispatch, headers: {'Content-Type': `multipart/form-data; boundary=${data._boundary}` } });
  }

  static put(path, data = {}, dispatch) {
    return axios.put(path, data, { dispatch });
  }

  static delete(path, dispatch) {
    return axios.delete(path, { dispatch });
  }

  static patch(path, data = {}, dispatch) {
    return axios.patch(path, data, { dispatch });
  }
}
