import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Drawer, Tag, Alert, Row, Col, Descriptions, Modal } from 'antd';
import Parser from '../../../../../helpers/Parser';
import Map from '../../../../../components/Map';
import Patente from '../../../../../components/Patente';
import EventosTimeline from '../../../../../components/Eventos/EventosTimeline';

const DescItem = Descriptions.Item;
 
const ViajeDetail = props => {
  const [markers, setMarkers] = useState([]);
  const [position, setPosition] = useState({});

  useEffect(() => {
    let markersArr = [];

    if(props.viaje?.ultima_geoposicion_insertada){
      if (Object.keys(props.viaje?.ultima_geoposicion_insertada).length){
        markersArr.push({
          id: props.viaje.id,
          codigo_uvertrac: props.viaje.codigo_uvertrac,
          estado: props.viaje.estado,
          fecha_hora: Parser.datetime(props.viaje.ultima_geoposicion_insertada.fecha_hora),
          unidad_transporte: props.viaje.unidad_transporte,
          ultima_geoposicion_insertada: {
            latitud: parseFloat(props.viaje.ultima_geoposicion_insertada.latitud),
            longitud: parseFloat(props.viaje.ultima_geoposicion_insertada.longitud)
          }
        });
      }
    }
    setMarkers(markersArr);

    setPosition({
      lat: markersArr.length
        ? markersArr.length === 1
          ? markersArr[0].ultima_geoposicion_insertada.latitud
          : markersArr[markersArr.length - 1].ultima_geoposicion_insertada.latitud
        : -31.3992876,
      lng: markersArr.length
        ? markersArr.length === 1
          ? markersArr[0].ultima_geoposicion_insertada.longitud
          : markersArr[markersArr.length - 1].ultima_geoposicion_insertada.longitud
        : -64.2643838
    });
  }, [props.viaje]);

  const getDesde = () => {
    let localidad = '';
    if(props.viaje?.publicacion_viaje?.origen_direccion_google){
      let origen_google = props.viaje.publicacion_viaje.origen_direccion_google.split(',');
      if(origen_google.length > 3){
        localidad = origen_google[origen_google.length - 3].trim();
      }
    }
    return localidad + ' >>> ' + props.viaje?.publicacion_viaje?.origen_direccion;
  }

  const getHasta = () => {
    let localidad = '';
    if(props.viaje?.publicacion_viaje?.destino_direccion_google){
      let destino_google = props.viaje.publicacion_viaje.destino_direccion_google.split(',');
      if(destino_google.length > 3){
        localidad = destino_google[destino_google.length - 3].trim();
      }
    }
    return localidad + ' >>> ' + props.viaje?.publicacion_viaje?.destino_direccion;
  }

  const getTotal = () => {
    return Boolean(Number(props.viaje?.tarifa_por_tonelada))
        ? <span>Total por Tn: <b>{ Parser.currency(props.viaje?.tarifa_por_tonelada) }</b></span>
        : Boolean(Number(props.viaje?.tarifa_viaje_completo))
          ? <span>Tarifa Total<b>{ Parser.currency(props.viaje?.tarifa_viaje_completo) }</b></span>
          : <span>Costo del viaje<b>en espera de oferta</b></span>
  }

  const generateMapMarkers = () => {
    let mapMarkers = [];

    markers.map(marker => {
      let mapMarker = {
        id: marker.id,
        text: marker.codigo_uvertrac,
        lat: marker.ultima_geoposicion_insertada.latitud,
        lng: marker.ultima_geoposicion_insertada.longitud,
        info: [
          { key: 'Estado', value: marker.estado },
          { key: 'Fecha', value: marker.fecha_hora },
          { key: 'Patente', value: marker.unidad_transporte.camion.patente },
          { key: 'Chofer', value: marker.unidad_transporte.chofer.razon_social },
          { key: 'Cuit', value: marker.unidad_transporte.chofer.cuit  }
        ]
      };
      mapMarkers.push(mapMarker);
    });

    return mapMarkers;
  }

  const showCartaPorteModal = image => {
    Modal.info({
      width: '60%',
      title: 'Codigo de trazabilidad de granos (CTG)',
      content: (
        <div>
          <img alt="carta" style={ { width: '100%', maxHeight: 500 } } src={ image } />
        </div>
      ),
      onOk() { },
    });
  }

  return (
    <Drawer 
      title="Detalle del Viaje"
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      { props.viaje?.ultima_geoposicion_insertada 
        ? <div style={ { height: 200 } }>
          { props.visible
            ? <Map
              defaultLat={ position.lat }
              defaultLng={ position.lng }
              defaultZoom={ markers.length == 1 ? 6 : 4 }
              markers={ generateMapMarkers() }
              selectedMarker={ -1 }
            />
            : '' }
          { markers.length == 0
            ? <Alert type="warning" message="No se pudo recuperar información" />
            : '' }
        </div>
        : <Alert style={ { fontSize: 12 } } type="warning" message="El viaje todavia no tiene datos de geolocalización" />
      }

      <Divider />

      <Descriptions column={1} bordered size="small">
        <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
        <DescItem label="Código de Publicación">{ props.viaje?.publicacion_viaje.codigo_uvertrac }</DescItem>
        <DescItem label="Título del Viaje">{ props.viaje?.publicacion_viaje.titulo }</DescItem>
        <DescItem label="Fecha Retiro Publicada"><Tag color="cyan">{ Parser.datetime(props.viaje?.publicacion_viaje.fecha_hora_retiro) }</Tag></DescItem>
        <DescItem label="Fecha Descarga Publicada"><Tag color="cyan">{ Parser.datetime(props.viaje?.publicacion_viaje.fecha_hora_entrega) }</Tag></DescItem>
        <DescItem label="Desde">{ getDesde() }</DescItem>
        <DescItem label="Hasta">{ getHasta() }</DescItem>
        <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
        <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
        <DescItem label="Forma de Pago">{ getTotal() }</DescItem>
      </Descriptions>

      <Divider />

      <Alert style={ { fontSize: 16} } type="success" 
        message={ <Row gutter={[16,8]}>
          <Col span={ 24 }>Unidad de transporte:</Col>
          <Col span={ 24 }><b>{ props.viaje?.unidad_transporte.chofer.razon_social + ' >>> ' + props.viaje?.unidad_transporte.chofer.cuit}</b>
          </Col>
          <Col span={ 12 }><Patente patente={ props.viaje?.unidad_transporte.camion.patente } /></Col>
          { props.viaje?.unidad_transporte.remolque 
            ? <Col span={ 12 }><Patente patente={ props.viaje.unidad_transporte.remolque.patente } remolque={ true } /></Col>
            : '' }
        </Row> } 
      />

      { props.viaje?.entityMedia?.carta_porte
        ? <Fragment>

          <Divider />

          <h3 style={ { paddingBottom: 10 } }>Codigo de trazabilidad de granos (CTG)</h3>
          <div style={ { textAlign: 'center' } }>
            <img alt="carta"
              style={ { width: 125, height: 75, cursor: 'pointer' } }
              src={ props.viaje.entityMedia.carta_porte.url } 
              onClick={ () => showCartaPorteModal(props.viaje.entityMedia.carta_porte.url) } 
            />
          </div>   
        </Fragment>
        : '' }
      
      <Divider />

      <h3 style={ { paddingBottom: 10 } }>Eventos</h3>
      { props.viaje?.historial_eventos?.length > 0
        ? <div style={ { marginLeft: 10 } }>
          <EventosTimeline eventos={ props.viaje?.historial_eventos } />
        </div>
        : <Alert style={ { fontSize: 12} } type="warning" message="No se registran eventos todavia" />
      }
    </Drawer>
  );
}

export default ViajeDetail;