import React, { useState } from 'react';
import { Divider, Drawer, Tag, Alert, Descriptions, Button, Spin, Popconfirm } from 'antd';
import { DislikeOutlined } from '@ant-design/icons';
import Parser from '../../../../../helpers/Parser';
import AppNotification from '../../../../../components/AppNotification';

const DescItem = Descriptions.Item;
 
const RechazarOferta = props => {
  const [working, setWorking] = useState(false);

  const handleOK = () => {
    setWorking(true);
    props.rechazarOferta(props.oferta.id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Oferta rechazada correctamente');
        props.loadViajes();
        props.onClose();
      })
      .catch(error => setWorking(false));
  }

  return (
    <Drawer 
      title={ <span>Oferta <Tag color="cyan">{ props.oferta?.codigo_uvertrac }</Tag></span> }
      placement="right"
      closable={ true }
      visible={ props.visible }
      onClose={ props.onClose }
      width={ '40%' }
    >
      <Spin spinning={ working }>
        <Alert type="warning" showIcon 
          message="Tenga en cuenta que si una oferta es rechazada, los viajes ofertados se cancelaran."
        />

        <Divider/>

        <Descriptions column={1} bordered size="small">
          <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
          <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
          <DescItem label="Desde">{ props.viaje?.origen_direccion }</DescItem>
          <DescItem label="Hasta">{ props.viaje?.destino_direccion }</DescItem>
          <DescItem label="Distancia Total">{ props.viaje?.kmts_finales } kms</DescItem>
          <DescItem label="Condición de Pago">{ props.viaje?.condicion_de_pago }</DescItem>
        </Descriptions>

        <Divider />

        <Descriptions column={1} bordered size="small">
          <DescItem label="Código de Oferta"><Tag color="cyan">{ props.oferta?.codigo_uvertrac }</Tag></DescItem>
          <DescItem label="Cantidad de camiones ofertados">{ props.oferta?.cantidad_camiones }</DescItem>
          <DescItem label="Monto ofertado por camión">{ Parser.currency(props.oferta?.tarifa_viaje_completo) }</DescItem>
        </Descriptions>
        
        <Divider />

        <Alert style={ { fontSize: 16} } type="success" message={ 
          <Descriptions column={1} size="small">
            <DescItem label="Total a Pagar"><b>{ Parser.currency(props.oferta?.tarifa_viaje_completo * props.oferta?.cantidad_camiones) }</b></DescItem>
          </Descriptions> } 
        />

        <Divider />

        <Popconfirm
          placement="topRight"
          title={ '¿Está seguro de querer rechazar la oferta?' }
          onConfirm={ handleOK }
          okText="Rechazar"
          cancelText="No"
        >
          <Button type="danger" icon={ <DislikeOutlined /> } size="large" block>
            Rechazar Oferta
          </Button>
        </Popconfirm>
      </Spin>
    </Drawer>
  );
}

export default RechazarOferta;