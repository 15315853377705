import React, { useState } from 'react';
import { Form, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const ImageFormItem = props => {
  return (
    <FormItem {...props }
      valuePropName="fileList" 
      getValueFromEvent={ event => {
        return event && (event.fileList.length > 0 ? [event.file] : [])
      } }
      //extra={ "Click si desea cambiar la imagen" }
    >
      <Upload
        name="logo"
        listType="picture"
        accept={ '.jpeg,.jpg,.png,.gif,image/jpeg,image/pjpeg,image/png,image/gif,' }
        beforeUpload={ file => { return false } }
      >
        <Button icon={ <UploadOutlined /> }>Subir imagen</Button>
      </Upload>
    </FormItem>
  );
}

export default ImageFormItem;