import React, { useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import basicStyle from '../../../../../themes/basicStyle';
import moment from 'moment';
import UIHelper from '../../../../../helpers/UIHelper';
import UvertracApi from '../../../../../api/uvertracApi';

const FormItem = Form.Item;
const { Option } = Select;

const DatosRemolqueStep = props => {
  const [tiposAcoplado, setTiposAcoplado] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    loadTiposAcoplado();
  }, []);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      form.validateFields()
        .then(values => {
          const data = {
            ...values,
            tipo_acoplado: tiposAcoplado.find(x => x.id === values.tipo_acoplado_id).nombre,
          }
          props.onValidate(true, data);
        })
        .catch(error => props.onValidate(false, {}));
    }
  }, [props.current, props.status]);

  const loadTiposAcoplado = () => UvertracApi.get({ model: 'TipoAcoplado', colReturn: ['id','nombre'], colOrderBy: 'nombre' }).then(response => setTiposAcoplado(response.data));

  return (
    <Form
      { ...basicStyle.formItemLayout }
      form={ form }
      name="formulario"
      scrollToFirstError
      autoComplete="off"
    >
      <FormItem name="tipo_acoplado_id" label="Tipo de Acoplado" hasFeedback rules={ UIHelper.defaultRules }>
        <Select showSearch filterOption={ UIHelper.defaultSelectFilter }>
          { tiposAcoplado.map(tipo => (<Option key={ tipo.id } value={ tipo.id }>{ tipo.nombre }</Option>)) }
        </Select>
      </FormItem>
      <FormItem name="patente" label="Patente" rules={ UIHelper.defaultRules }
        normalize={ (value, prevValue) => value !== '' ? (/^[\w]+$/.test(value.toUpperCase()) ? value.toUpperCase() : prevValue) : value }
      >
        <Input maxLength={7}/>
      </FormItem>
      <FormItem name="fecha_de_vencimiento_seguro" label="Fecha de vencimiento del seguro" rules={ UIHelper.defaultRules }>
        <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
          disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
        />
      </FormItem>
      <FormItem name="fecha_de_vencimiento_vtv" label="Fecha de vencimiento de VTV" rules={ UIHelper.defaultRules }>
        <DatePicker style={ { width: '100%' } } format="DD-MM-YYYY"
          disabledDate={ d => !d || d.isBefore(moment().format('YYYY-MM-DD')) }
        />
      </FormItem>
    </Form>
  );
}

export default DatosRemolqueStep;