import { Fragment, useEffect, useState } from "react";
import { Col } from "antd";
import { TrophyOutlined, CheckSquareOutlined, CarOutlined, TeamOutlined, ApiOutlined } from '@ant-design/icons';
import NumberCardLines from "../../../components/NumberCard/NumberCardLines";

const DashboardTotales = props => {
  const [choferesConDocumentacionSinAprobar, setChoferesConDocumentacionSinAprobar] = useState([]);
  const [camionesConDocumentacionSinAprobar, setCamionesConDocumentacionSinAprobar] = useState([]);
  const [remolquesConDocumentacionSinAprobar, setRemolquesConDocumentacionSinAprobar] = useState([]);

  const [ofertasActivas, setOfertasActivas] = useState([]);
  const [transportes, setTransportes] = useState(0);
  const [choferes, setChoferes] = useState([]);
  const [camiones, setCamiones] = useState([]);
  const [remolques, setRemolques] = useState([]);

  useEffect(() => {
    setChoferesConDocumentacionSinAprobar(props.dashboardInfo?.choferesConDocumentacionSinAprobar ? props.dashboardInfo.choferesConDocumentacionSinAprobar : []);
    setCamionesConDocumentacionSinAprobar(props.dashboardInfo?.camionesConDocumentacionSinAprobar ? props.dashboardInfo.camionesConDocumentacionSinAprobar : []);
    setRemolquesConDocumentacionSinAprobar(props.dashboardInfo?.remolquesConDocumentacionSinAprobar ? props.dashboardInfo.remolquesConDocumentacionSinAprobar : []);
    setOfertasActivas(props.dashboardInfo?.ofertas?.filter(x => x.habilitado == true).map(x => x.codigo_uvertrac));
    setTransportes(props.dashboardInfo?.unidades_de_transporte ? props.dashboardInfo.unidades_de_transporte.length : 0);
    setChoferes(props.dashboardInfo?.choferes_no_asignados?.map(x => x.cuit));
    setCamiones(props.dashboardInfo?.camiones_no_asignados?.map(x => x.patente));
    setRemolques(props.dashboardInfo?.remolques_no_asignados?.map(x => x.patente));
  }, [props.dashboardInfo]);

  return (
    <Fragment>
      <Col span={ 12 }>
        <NumberCardLines 
          number={ ofertasActivas?.length } 
          data={ ofertasActivas }
          icon={ <TrophyOutlined className="iconWarp" style={ { backgroundColor : '#8FC2D9' } } /> }
          color="#8FC2D9" 
          title="Ofertas activas"
        />
      </Col>
      <Col span={ 12 }>
        <NumberCardLines 
          number={ transportes } 
          icon={ <CheckSquareOutlined className="iconWarp" style={ { backgroundColor : '#4df293' } }/> } 
          color="#4df293" 
          title="Unidades de transporte"
        />
      </Col>
      <Col span={ 8 }>
        <NumberCardLines 
          number={ choferes?.length } 
          data={ choferes }
          icon={ <CarOutlined className="iconWarp" style={ { backgroundColor : '#8FC2D9' } }/> } 
          color="#8FC2D9" 
          title="Choferes sin unidad de transporte"
        />
      </Col>
      <Col span={ 8 }>
        <NumberCardLines 
          number={ camiones?.length } 
          data={ camiones }
          icon={ <TeamOutlined className="iconWarp" style={ { backgroundColor : '#f8c82e' } }/> } 
          color="#f8c82e" 
          title="Camiones sin unidad de transporte"
        />
      </Col>
      <Col span={ 8 }>
        <NumberCardLines 
          number={ remolques?.length } 
          data={ remolques }
          icon={ <ApiOutlined className="iconWarp" style={ { backgroundColor : '#d897eb' } }/> } 
          color="#d897eb" 
          title="Remolques sin unidad de transporte"
        />
      </Col>
    </Fragment>
  );
}

export default DashboardTotales;