import Fetcher from "../helpers/Fetcher";

const TransportistasApi = {
  getDashboard: () => {
    return Fetcher
      .get('uvertraccore/dashboard-transportista')
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  get: id => {
    let url = 'uvertraccore/transportistas/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'uvertraccore/transportistas/';
    if(!data.id){
      return Fetcher
        .post(url, data)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
      .put(url + data.id, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('uvertraccore/transportistas/' + id)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  enable_disable: (id, enable) => {
    if(enable == true){
      return Fetcher
        .post('uvertraccore/transportistas/habilitar/' + id)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
        .post('uvertraccore/transportistas/deshabilitar/' + id)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
  },
  getViajesEnCurso: () => {
    let url = 'uvertraccore/transportistas/get-viajes-en-curso';
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getViajesRealizados: () => {
    let url = 'uvertraccore/transportistas/get-viajes-finalizados';
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getOfertasRealizadas: () => {
    let url = 'uvertraccore/transportistas/get-Ofertas-Realizadas';
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getTransportistaViajes: () => {
    let url = 'uvertraccore/transportistas/get-Transportista-Viajes';
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
}

export default TransportistasApi;