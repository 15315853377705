import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import CargaDadorList from '../cargas-dadores/components/CargaDadorList';
import CargaDadorViaje from './components/CargaDadorViaje';
const { Content } = Layout;

const CargaDadores = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ CargaDadorList } />
        <Route path={ `${props.match.path}/view/:id` } component={ CargaDadorViaje } />
      </Switch>
    </Content>
  );
}

export default CargaDadores;