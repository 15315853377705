import React, { Component, Fragment, useState } from 'react';
import { Tag, Button, Tooltip, Alert, Table, Card, Space } from 'antd';
import { IdcardOutlined, CarOutlined } from '@ant-design/icons';
import VerButton from '../../../../../components/Buttons/VerButton';
import ViajeEnCursoDetail from './ViajeEnCursoDetail';
import ChoferDetail from './ChoferDetail';
import CamionDetail from './CamionDetail';
import ViajeEstadoTag from '../../../../../components/Tags/ViajeEstadoTag';

const ViajeEnCursoList = props => {
  const [selectedViaje, setSelectedViaje] = useState(undefined);
  const [showViajeDetail, setShowViajeDetail] = useState(false);
  const [showChoferDetail, setShowChoferDetail] = useState(false);
  const [showCamionDetail, setShowCamionDetail] = useState(false);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac' },
    { title: 'Chofer', dataIndex: ['unidad_transporte', 'chofer', 'razon_social'], key: 'chofer' },
    { title: 'Transportista', dataIndex:['unidad_transporte', 'transportista', 'razon_social'], key: 'chofer'},
    { title: 'Estado', key: 'estado', dataIndex: 'estado',
      render: text => <ViajeEstadoTag estado={ text }/>
      //<Tag color={ text === 'cancelado' ? "#f50" : '#87d068' }><span style={ { textTransform: 'capitalize' } }>{ text }</span></Tag>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedViaje(record); setShowViajeDetail(true); } }/>
          <Tooltip title="Datos del Chofer">
            <Button shape="circle" icon={ <IdcardOutlined /> } size="small" onClick={ ()=>{ setSelectedViaje(record); setShowChoferDetail(true); } } />
          </Tooltip>
          <Tooltip title="Datos del Camión">
            <Button shape="circle" icon={ <CarOutlined /> } size="small" onClick={ ()=>{ setSelectedViaje(record); setShowCamionDetail(true); } } />
          </Tooltip>
        </Space>
      }
    }
  ];

  //const viajesEnCurso = props.viaje?.oferta?.map(oferta => oferta.viajes_en_curso).flatMap(x => x);

  return(
    <Fragment>
      <Card title="Viajes en curso" size="small">
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ props.working }
          columns={ columns } 
          dataSource={ props.viajes }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>

      <ViajeEnCursoDetail
        visible={ showViajeDetail }
        onClose={ () => setShowViajeDetail(false) }
        viajeEnCurso={ selectedViaje }
        viaje={ props.viaje }
      />
      <ChoferDetail
        visible={ showChoferDetail }
        onClose={ () => setShowChoferDetail(false) }
        viajeEnCurso={ selectedViaje }
        viaje={ props.viaje }
      />
      <CamionDetail
        visible={ showCamionDetail }
        onClose={ () => setShowCamionDetail(false) }
        viajeEnCurso={ selectedViaje }
        viaje={ props.viaje }
      />
    </Fragment>
  );
}

export default ViajeEnCursoList;
