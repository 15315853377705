import React, { useEffect, useState } from 'react';
import { Modal, Input, Form, Spin, Row, Col } from 'antd';
import ViajesApi from '../../../../../api/viajesApi';
import AppNotification from '../../../../../components/AppNotification';
import basicStyle from '../../../../../themes/basicStyle';
import UIHelper from '../../../../../helpers/UIHelper';
  
const FormItem = Form.Item;

const NroCartaPorteModal = props => {
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if(props.visible == true){
      form.resetFields();
    }
  }, [props.visible])

  const onOk = () => {
    form.validateFields()
      .then(values => {
        setWorking(true);
        let data = { 
          ...values,
        }
        ViajesApi.saveCartaPorte(props.viaje.id, data)
          .then(response => {
            setWorking(false);
            AppNotification.showSuccess('Codigo de trazabilidad de granos guardado correctamente');
            props.loadViajes();
            props.onClose();
          })
          .catch(error => { setWorking(false); });
      });
  };
 
  return (
    <Modal 
      title="Codigo de trazabilidad de granos (CTG)" 
      visible={ props.visible } 
      onCancel={ props.onClose }
      onOk = { onOk }
      width="40%"
    >
      <Spin spinning={ working }>
        <Form
          { ...basicStyle.formItemLayout }
          form={ form }
          name="formulario"
          initialValues={ {
            numero_carta_porte: props.viaje ? props.viaje.numero_carta_porte : ''
          } }
          scrollToFirstError
        >
          <Row gutter={ [16,0] }>
            <Col span={ 24 }>
              <FormItem name="numero_carta_porte" label="Codigo (CTG)" rules={ UIHelper.defaultRules }>
                <Input />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default NroCartaPorteModal;