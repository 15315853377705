import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const EliminarButton = props => {
  return props.onConfirm
    ? <Popconfirm
      placement="topRight"
      title={ props.popTitle }
      onConfirm={ props.onConfirm }
      onCancel={ props.onCancel }
      okText="Eliminar"
      cancelText="No"
      disabled={ props.disabled }
    >
      <Tooltip 
        title={ props.title ? props.title : 'Eliminar' }
      >
        <Button 
          shape="circle" 
          icon={ <DeleteOutlined /> } 
          size="small" 
          type="danger"
          disabled={ props.disabled }
        />
      </Tooltip>
    </Popconfirm>
    : <Tooltip 
        title={ props.title ? props.title : 'Eliminar' }
      >
        <Button 
          shape="circle" 
          icon={ <DeleteOutlined /> } 
          size="small" 
          type="danger"
          onClick={ props.onClick }
          disabled={ props.disabled }
        />
    </Tooltip>
}

export default EliminarButton;