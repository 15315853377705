import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Layout, Button, Card, Form, Input, Row, Col, Spin } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PageTitle from '../../../../components/PageTitle';
import basicStyle from '../../../../themes/basicStyle';
import LocalidadesApi from '../../../../api/parametricas/localidadesApi';
import UIHelper from '../../../../helpers/UIHelper';
import AppNotification from '../../../../components/AppNotification';
import ProvinciasApi from '../../../../api/parametricas/provinciasApi';
import DepartamentosApi from '../../../../api/parametricas/departamentosApi';

const { Content } = Layout;
const FormItem = Form.Item;

const LocalidadForm = props => {
  const history = useHistory();
  const [provincia, setProvincia] = useState(undefined);
  const [departamento, setDepartamento] = useState(undefined);
  const [localidad, setLocalidad] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm(); 

  useEffect(()=>{
    loadProvincia();
    loadDepartamento();
    if(history.location.pathname.indexOf('edit') > -1){
      loadLocalidad();
    }
  }, []);

  const loadProvincia = () => {
    setWorking(true);
    ProvinciasApi.get(props.match.params.provId)
      .then(response => { 
        setWorking(false);
        setProvincia(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const loadDepartamento = () => {
    setWorking(true);
    DepartamentosApi.get(props.match.params.dptoId)
      .then(response => { 
        setWorking(false);
        setDepartamento(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const loadLocalidad = () => {
    setWorking(true);
    LocalidadesApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setLocalidad(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);
    let data = { 
      id: localidad?.id,
      ...values,
      parametrica_provincia_id: provincia.id,
      parametrica_departamento_id: departamento.id,
    }
    LocalidadesApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Localidad guardada correctamente');
        history.goBack();
      })
      .catch(error => { setWorking(false); });
  };

  return (
    <Content>
      <PageTitle title={ `${localidad?.id ? 'Editar' : 'Nuevo'} Localidad` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            initialValues={ { ...localidad,
              provincia: provincia?.nombre,
              departamento: departamento?.nombre
            } }
            scrollToFirstError
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                <FormItem name="provincia" label="Provincia" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input disabled/>
                </FormItem>
                <FormItem name="departamento" label="Departamento" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input disabled/>
                </FormItem>
                <FormItem name="nombre" label="Nombre" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input maxLength={255}/>
                </FormItem>
                <FormItem name="codigo_postal" label="Código Postal" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input />
                </FormItem>
              </Col>
              
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
              </FormItem>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Content>
  );
}

export default LocalidadForm;