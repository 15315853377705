import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Drawer, Select, Alert, Row, Col, Tag, Button, message, Spin, Descriptions, Space } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import Parser from '../../../../../helpers/Parser';
import AppNotification from '../../../../../components/AppNotification';

const { Option } = Select;
const DescItem = Descriptions.Item;

const AgregarViajeOferta = props => {
  const [oferta, setOferta] = useState(undefined);
  const [unidadesTransporte, setUnidadedTransporte] = useState([]);
  const [UTSeleccionados, setUTSeleccionados] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if(props.ofertaId){
      setUTSeleccionados([]);
      loadUnidadesNoAsignadas();
      loadOferta();
    }
  }, [props.ofertaId]);

  useEffect(() => {
    if(props.visible == true){
      setUTSeleccionados([]);
    }
  }, [props.visible]);

  const loadUnidadesNoAsignadas = () => {
    props.getUnidadesTransporteNoAsignadas()
      .then(response => {
        let unidades = [];
        unidades = response.map(unidad => {
          return {
            ...unidad,
            con_problemas: unidad.chofer.media.filter(x => !x.custom_properties?.aprobada_at).length > 0 ||
              unidad.camion.media.filter(x => (x.custom_properties.imagen == 'seguro' || x.custom_properties.imagen == 'vtv') && !x.custom_properties?.aprobada_at).length > 0 || 
              unidad.remolque?.media.filter(x => (x.custom_properties.imagen == 'seguro' || x.custom_properties.imagen == 'vtv') && !x.custom_properties?.aprobada_at).length > 0 
          }
        });

        setUnidadedTransporte(unidades);
      });
  }

  const loadOferta = () => {
    setWorking(true);
    props.getOferta(props.ofertaId)
      .then(response => {
        setWorking(false);
        setOferta(response);
      })
      .catch(error => setWorking(false));
  }

  const onUTChange = utSeleccionados => {
    setUTSeleccionados(utSeleccionados);
  }

  const saveUnidades = () => {
    setWorking(true);
    props.asignarUnidadesTransporteOferta(props.ofertaId, UTSeleccionados.join())
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Viajes generados correctamente');
        props.loadOfertas();
        props.onClose();
      })
      .catch(error => setWorking(false));
  }

  return (
    <Drawer
      title={ <span>Oferta</span> }
      placement="right"
      closable={ true }
      onClose={ props.onClose }
      visible={ props.visible }
      width={ '40%' }
    >
      <Spin spinning={ working }>
        <Descriptions column={ 1 } bordered size="small" labelStyle={ { fontSize: 12 } }>
          <DescItem label="Codigo de la oferta"><Tag color="cyan"><b>{ oferta?.codigo_uvertrac }</b></Tag></DescItem>
          <DescItem label="Condicion de Pago"><b>{ oferta?.condicion_de_pago }</b></DescItem>
          <DescItem label="Tarifa"><Tag color="cyan"><b>{ Parser.currency(oferta?.tarifa_viaje_completo) }</b></Tag></DescItem>
        </Descriptions>

        <Divider/>

        <Descriptions column={ 1 } bordered size="small" labelStyle={ { fontSize: 12 } }>
          <DescItem label="Transportista"><Tag color="cyan"><b>{ oferta?.transportista ? oferta?.transportista.razon_social : '' }</b></Tag></DescItem>
        </Descriptions>

        { oferta?.viajes_en_curso && oferta?.viajes_en_curso.length > 0
          ? <div>
            <Divider/>
            
            <Row>
              <Col span={ 24 }>Viajes en curso:</Col>
            </Row>
            <Row>
              { oferta?.viajes_en_curso.map(viaje => <Col span={ 12 } key={ viaje.codigo_uvertrac }> <Tag color="cyan"><b>{ viaje.codigo_uvertrac }</b></Tag></Col>) }
            </Row>
          </div>
          : '' }
        
        <Divider/>

        <Descriptions column={ 1 } bordered size="small" labelStyle={ { fontSize: 12 } }>
          <DescItem label="Camiones solicitados"><b>{ oferta?.cantidad_camiones }</b></DescItem>
          <DescItem label="Viajes en curso"><b>{ oferta?.viajes_en_curso ? oferta?.viajes_en_curso.length : 0 }</b></DescItem>
          <DescItem label="Viajes disponibles"><b>{ oferta?.cantidad_camiones - (oferta?.viajes_en_curso ? oferta?.viajes_en_curso.length : 0) }</b></DescItem>
        </Descriptions>

        <Divider/>

        <Select
          mode="multiple"
          size={ "large" }
          placeholder="Unidades a Seleccionar"
          onChange={ onUTChange }
          style={ { width: '100%' } }
          value={ UTSeleccionados }
        >
          { unidadesTransporte?.map(unidad => {
            return <Option key={ unidad.id } disabled={unidad.con_problemas}>{ 
              unidad.camion.patente  + ' : ' + unidad.chofer.razon_social + (unidad.con_problemas ? ' - Documentación sin aprobar' : '')
            }</Option>;
          }) }
        </Select>
        
        <Divider />

        <Space direction="vertical">
          <Button type="primary" icon={ <LikeOutlined /> } size="large" onClick={ saveUnidades } 
            disabled={ UTSeleccionados.length == 0 || 
              (UTSeleccionados.length > oferta?.cantidad_camiones - (oferta?.viajes_en_curso ? oferta?.viajes_en_curso.length : 0) ) 
            }>
            Agregar Unidades
          </Button>

          { UTSeleccionados.length > oferta?.cantidad_camiones - (oferta?.viajes_en_curso ? oferta?.viajes_en_curso.length : 0)
            ? <Alert message={ "Las unidades seleccionadas superan el disponible" } type="warning" showIcon />
            : '' }
        </Space>

        <Divider />

        <Space direction="vertical">
          <Alert message={ "Recuerde que esta accion generara un viaje nuevo descontandolo de los ofertados" } type="warning" showIcon />
          <Alert message={ "Las Unidades de transporte con viajes activos no estaran listadas" } type="warning" showIcon />
        </Space>
      </Spin>
    </Drawer>
  );
}

export default AgregarViajeOferta;