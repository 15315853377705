import Fetcher from "../helpers/Fetcher";

const UnidadesTransporteApi = {
  get: id => {
    let url = 'uvertraccore/unidad-transportes/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'uvertraccore/unidad-transportes/';
    if(!data.id){
      return Fetcher
        .post(url, data)
        .then(response => { return response.data; })
        .catch(error => { throw error; });
    }
    else{
      return Fetcher
      .put(url + data.id, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('uvertraccore/unidad-transportes/' + id)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getNoAsignadas: () => {
    return Fetcher
      .get('uvertraccore/unidad-transportes/sin-viaje-asignado')
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  getSinViajeAsignado: () => {
    let url = 'uvertraccore/unidad-transportes/sin-viaje-asignado';
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
}

export default UnidadesTransporteApi;