import { useEffect, useState } from "react";
import { Button, Card, Spin, Tooltip } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import Map from "../../../components/Map";
import Parser from "../../../helpers/Parser";

const ViajesEnCursoMap = props => {
  const [markers, setMarkers] = useState([]);
    
  useEffect(() => {
    let markersArr = [];

    props.dashboardInfo?.ofertas?.map(oferta => {
      let viajes_en_curso = oferta?.viajes_en_curso.filter(x => x.ultima_geoposicion_insertada && x.ultima_geoposicion_insertada !== null);
      viajes_en_curso?.map(viaje => {
        markersArr.push({
          id: viaje.id,
          text: viaje.codigo_uvertrac,
          lat: parseFloat(viaje.ultima_geoposicion_insertada.latitud),
          lng: parseFloat(viaje.ultima_geoposicion_insertada.longitud),
          info: [
            { key: 'Estado', value: viaje.estado },
            { key: 'Fecha', value: viaje.publicacion_viaje.fecha_hora_retiro ? Parser.datetime(viaje.publicacion_viaje.fecha_hora_retiro) : '' },
            { key: 'Origen', value: generateOrigen(viaje) },
            { key: 'Destino', value: generateDestino(viaje) },
            { key: 'Patente', value: viaje.unidad_transporte ? viaje.unidad_transporte.camion.patente +  
              (viaje.unidad_transporte.remolque ? ' >>> ' + viaje.unidad_transporte.remolque.patente : '') : '' 
            },
            { key: 'Chofer', value: viaje.unidad_transporte ? viaje.unidad_transporte.chofer.razon_social : '' },
            { key: 'Cuit', value: viaje.unidad_transporte ? viaje.unidad_transporte.chofer.cuit : ''  }
          ]
        });
      });
    });

    setMarkers(markersArr);
  }, [props.dashboardInfo]);

  const generateOrigen = viaje => {
    let localidad = '';
    if(viaje.publicacion_viaje?.origen_direccion_google){
      let origen_google = viaje.publicacion_viaje.origen_direccion_google.split(',');
      if(origen_google.length > 3){
        localidad = origen_google[origen_google.length - 3].trim();
      }
    }
    return localidad + ' >>> ' + viaje.publicacion_viaje?.origen_direccion;
  }

  const generateDestino = viaje => {
    let localidad = '';
    if(viaje.publicacion_viaje?.destino_direccion_google){
      let destino_google = viaje.publicacion_viaje.destino_direccion_google.split(',');
      if(destino_google.length > 3){
        localidad = destino_google[destino_google.length - 3].trim();
      }
    }
    return localidad + ' >>> ' + viaje.publicacion_viaje?.destino_direccion;
  }

  return (
    <Card title="Viajes en curso"
      extra={ <Tooltip title="Actualizar" placement="topRight">
                <Button type="primary" icon={ <ReloadOutlined />} onClick={ () => props.loadDashboard() }/>
              </Tooltip>}
    >
      <Spin spinning={ props.working }>
        <div style={ { height: '600px', width: '100%' } }>
          <Map
            defaultLat={ -31.3992876 }
            defaultLng={ -64.2643838 }
            defaultZoom={ 6 }
            markers={ markers }
            //selectedMarker={ selectedRow }
          />
        </div>
      </Spin>
    </Card>
  );
}

export default ViajesEnCursoMap;