import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Tag, Button, Input, Col, Space, Tooltip } from 'antd';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import TransportistasApi from '../../../api/transportistasApi';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import AppNotification from '../../../components/AppNotification';
import EnableDisableButton from '../../../components/Buttons/EnableDisableButton';
import VerButton from '../../../components/Buttons/VerButton';
import SearchDinamic from '../../../components/Search/Search-Dinamic';

const TransportistaList = props => {
  const history = useHistory();
  const [transportistas, setTransportistas] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadTransportistas();
  }, []);

  const columns = [
    { title: 'Id', dataIndex: 'id', width: '10%', },
    { title: 'Nombre', dataIndex: 'nombre', width: '25%', },
    { title: 'Apellido', dataIndex: 'apellido', width: '25%', }, 
    { title: 'Razon social', dataIndex: 'razon_social', width: '25%', }, 
    { title: 'Email', dataIndex: 'email', width: '25%', }, 
    { title: 'Estado', dataIndex: 'habilitado', width: '10%', align: 'center',
      render: text => <Tag color={ text ? 'geekblue' : 'red' }>{ text ? 'Habilitado' : 'Deshabilitado' }</Tag>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push(history.location.pathname + '/edit/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar el transportista?" onConfirm={ () => handleDelete(record.id) }/>
          <EnableDisableButton habilitado={ record.habilitado } onClick={ () => handleEnableDisable(record.id, !record.habilitado) } />
          <Tooltip title={ 'Crear un usuario para este transportista' } placement="topRight">
            <Button shape="circle" icon={ <UserAddOutlined /> } size="small"
              onClick={ () => history.push('/usuarios/new-from-transportista/' + record.id) }
            />
          </Tooltip>
        </Space>
      }
    }
  ];

  const loadTransportistas = () => {
    setWorking(true);
    TransportistasApi.get()
      .then(response => {
        setWorking(false);
        setTransportistas(response);
      })
      .catch(error => setWorking(false));
  }
  
  const handleDelete = id => {
    setWorking(true);
    TransportistasApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Transportista eliminado correctamente');
        loadTransportistas();
      })
      .catch(error => setWorking(false));
  };
  
  const handleEnableDisable = (id, status) => {
    setWorking(true);
    TransportistasApi.enable_disable(id, status)
      .then(response => {
        setWorking(false);
        loadTransportistas();
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Transportistas"/>
      <Card
        title={<Col span={12}>
          <SearchDinamic 
            module="uvertraccore"
            model="transportista"
            columnsSearch={ ['nombre','apellido','razon_social'] }
            columnsReturn={ columns }
            onChange={ response => setTransportistas(response) }
            onClear={ loadTransportistas }
          />
        </Col>}
        extra={[
          <Button type="primary" icon={<PlusOutlined />}
            onClick={ () => history.push(history.location.pathname + '/new') }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ transportistas }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          //onRow={ record => ({ onClick: () => { setSelectedRow(record.id); }}) }
        />
      </Card>
    </Fragment>
  );
}

export default TransportistaList;
