import React, {useEffect, useState} from 'react';
import { Card, Progress, Row, Col, Tag } from 'antd';
import CountUp from 'react-countup';
import './NumberCard.scss';

const CANTIDAD_MAXIMA_REGISTROS = 5;
let countdown;

const NumberCardLines = props => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    countdown = setInterval(() => increase(percent), 25);

    return () => clearInterval(countdown);
  }, [])

  const increase = count => {
    let auxPercent = percent;
    if(auxPercent < count) {
      auxPercent = auxPercent + 1;
      if (auxPercent > 100) {
        auxPercent = 100;
      }
      setPercent(auxPercent);
    } else {
      clearInterval(countdown);
    }
  }

  return (
    <Card className="number-card-lines" bordered={ false } bodyStyle={ {padding: 0} }>
      <div className="flexBox">
        { props.icon }
        <div className="content">
          <p className="title">{ props.title || 'No Title' }</p>
          { percent ? <Progress percent={ percent } strokeWidth={ 3 } /> : '' }
          <Row gutter={ 24 }>
            <Col lg={ 12 } md={ 12 }>
              <p className="number" style={ { margin: 0 } }>
                <CountUp
                  start={ 0 }
                  end={ props.number }
                  duration={ 2.75 }
                  useEasing
                  useGrouping
                  separator=","
                  { ...props.countUp || {} }
                />
              </p>
            </Col>
            <Col lg={ 12 } md={ 12 }>
              { props.data?.slice(0, CANTIDAD_MAXIMA_REGISTROS).map(item => 
                  <Tag style={ { marginBottom: 5 } } color={ props.color } key={ item }>{ item }</Tag>
               ) }
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
}

export default NumberCardLines;
