import { useEffect, useState } from "react";
import { Card, Table } from "antd";
import Patente from "../../../components/Patente";

const ViajesTable = props => {
  const [viajes, setViajes] = useState([]);
    
  useEffect(() => {
    let ofertas = props.dashboardInfo?.ofertas?.filter(oferta => oferta.viajes_en_curso?.length > 0);
    
    let ofertaViajes = [];
    ofertas?.map(oferta => 
      oferta.viajes_en_curso.map(viaje => 
        ofertaViajes.push({
          id: viaje.id,
          codigo_publicacion: oferta.codigo_uvertrac,
          codigo_viaje: viaje.codigo_uvertrac,
          //titulo: oferta.titulo,
          chofer: viaje.unidad_transporte ? viaje.unidad_transporte.chofer.razon_social : '',
          patente_camion: viaje.unidad_transporte ? <Patente patente={ viaje.unidad_transporte.camion.patente }/> : '',
          tiene_remolque: viaje.unidad_transporte ? (viaje.unidad_transporte.remolque_id > 0 ? 'SI' : 'NO') : ''
        })
      )
    )
    setViajes(ofertaViajes);
  }, [props.dashboardInfo]);

  const columns = [
    { title: 'Código Publicación', dataIndex: 'codigo_publicacion', key: 'codigo_publicacion', align: 'center' },
    { title: 'Código Viaje', dataIndex: 'codigo_viaje', key: 'codigo_viaje', align: 'center' },
    { title: 'Chofer', dataIndex: 'chofer', key: 'chofer' },
    { title: 'Patente Camión', dataIndex: 'patente_camion', key: 'patente_camion', width: 120, align: 'center' },
    { title: 'Tiene Remolque', dataIndex: 'tiene_remolque', key: 'tiene_remolque', align: 'center' },
  ];

  return (
    <Card title="Viajes en curso">
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ props.working }
        columns={ columns } 
        dataSource={ viajes }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
      />
    </Card>
  );
}

export default ViajesTable;