import React, { Fragment, useEffect, useState } from 'react';

import { Layout, Card, Form, Spin } from 'antd';
import AppNotification from '../../../../components/AppNotification';
import { useHistory } from 'react-router';
import PageTitle from '../../../../components/PageTitle';
import RemolquesApi from '../../../../api/remolquesApi';
import StepsForm from '../../../../components/StepsForm';
import moment from 'moment';
import DatosRemolqueStep from './components/DatosRemolqueStep';
import DocumentacionStep from './components/DocumentacionStep';
import RemolqueEditForm from './components/RemolqueEditForm';
import ConfirmacionStep from './components/ConfirmacionStep';

const { Content } = Layout;

const RemolqueForm = props => {
  const history = useHistory();
  const [remolque, setRemolque] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    if(history.location.pathname.indexOf('edit') > -1){
      loadRemolque();
    }
  }, []);

  const steps = [
    { title: 'Datos del Remolque', content: DatosRemolqueStep },
    { title: 'Documentación', content: DocumentacionStep },
    { title: 'Confirmación', content: ConfirmacionStep }
  ];

  const loadRemolque = () => {
    setWorking(true);
    RemolquesApi.get(props.match.params.id)
      .then(response => { 
        setWorking(false);
        setRemolque(response); 
        form.resetFields();
      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onFinish = values => {
    setWorking(true);

    if(!remolque?.id){
      let formData = new FormData();
      for (let key in values) {
        if(values[key]){
          const dashedKey = key.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
          if (values[key] instanceof moment) { formData.set(dashedKey, values[key].format("YYYY-MM-DD")); }
          else if (values[key] instanceof File) { formData.append(dashedKey, values[key]); }
          else { formData.set(dashedKey, values[key]); }
        }
      }
      saveRemolque(formData);
    }
    else{
      let data = {
        id: remolque.id,
        patente: values.patente,
        fecha_de_vencimiento_ruta: values.fecha_de_vencimiento_ruta?.format("YYYY-MM-DD"),
        fecha_de_vencimiento_seguro: values.fecha_de_vencimiento_seguro?.format("YYYY-MM-DD"),
        fecha_de_vencimiento_vtv: values.fecha_de_vencimiento_vtv?.format("YYYY-MM-DD")
      };

      let cedulaVerdeFormData = null;
      if (Array.isArray(values.cedula_verde) && values.cedula_verde.length === 1) {
        if(values.cedula_verde[0] instanceof File){
          cedulaVerdeFormData = new FormData();
          cedulaVerdeFormData.set('id', remolque.id);
          cedulaVerdeFormData.append('cedula_verde', values.cedula_verde[0]);
        }
      }
      let seguroFormData = null;
      if (Array.isArray(values.seguro) && values.seguro.length === 1) {
        if(values.seguro[0] instanceof File){
          seguroFormData = new FormData();
          seguroFormData.set('id', remolque.id);
          seguroFormData.append('seguro', values.seguro[0]);
        }
      }
      let vtvFormData = null;
      if (Array.isArray(values.vtv) && values.vtv.length === 1) {
        if(values.vtv[0] instanceof File){
          vtvFormData = new FormData();
          vtvFormData.set('id', remolque.id);
          vtvFormData.append('vtv', values.vtv[0]);
        }
      }

      (cedulaVerdeFormData ? RemolquesApi.updateCedulaVerde(remolque.id, cedulaVerdeFormData) : Promise.resolve())
      .then(() => (seguroFormData ? RemolquesApi.updateSeguro(remolque.id, seguroFormData) : Promise.resolve()))
      .then(() => (vtvFormData ? RemolquesApi.updateVTV(remolque.id, vtvFormData) : Promise.resolve()))
      .then(() => (saveRemolque(data)))
      .catch(error => { setResult('error'); setWorking(false); });
    }
  };

  const saveRemolque = data => {
    RemolquesApi.save(data)
      .then(response => {
        setWorking(false);
        setResult('success');
        AppNotification.showSuccess('Remolque guardado correctamente');
        history.goBack();
      })
      .catch(error => { 
        setResult('error');
        setWorking(false); 
      });
  }

  return (
    <Content>
      <PageTitle title={ `${remolque?.id ? 'Editar' : 'Nuevo'} Remolque` }
        onBack={ () => history.goBack() }
      />

      <Spin spinning={ working }>
        <Card>
          { !remolque
            ? <StepsForm
              steps={ steps }
              onFinish={ onFinish }
              enablePrevious={ true }
              result={ result }
            />
            : <RemolqueEditForm
              remolque={ remolque }
              onFinish={ onFinish }
            />
          }
        </Card>
      </Spin>
    </Content>
  );
}

export default RemolqueForm;
