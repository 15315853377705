import React, { Fragment, useState } from 'react';
import { Tag, Alert, Table, Space, Card, Tooltip, Button } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import Parser from '../../../../../helpers/Parser';
import VerButton from '../../../../../components/Buttons/VerButton';
import OfertaDetail from './OfertaDetail';
import AceptarOferta from './AceptarOferta';
import RechazarOferta from './RechazarOferta';
import OfertaEstadoTag from '../../../../../components/Tags/OfertaEstadoTag';
import ViajeEnCursoList from './ViajeEnCursoList';
import ViajeRealizadosList from './ViajesRealizadosList';

const OfertasList = props => {
  const [selectedOferta, setSelectedOferta] = useState(undefined);
  const [showOfertaDetail, setShowOfertaDetail] = useState(false);
  const [showAceptarOferta, setShowAceptarOferta] = useState(false);
  const [showRechazarOferta, setShowRechazarOferta] = useState(false);

  const columns = [
    { title: 'Codigo', dataIndex: 'codigo_uvertrac', key: 'codigo_uvertrac', align: 'center' },
    { title: 'Tarifa', dataIndex: 'tarifa_viaje_completo', key: 'tarifa_viaje_completo',
      render: text => Parser.currency(text)
    },
    { title: 'Camiones', dataIndex: 'cantidad_camiones', key: 'cantidad_camiones', align: 'center' },
    { title: 'Estado', dataIndex: 'estado', key: 'estado', align: 'center',
      render: text => <OfertaEstadoTag estado={ text }/>
    },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <VerButton onClick={ () => { setSelectedOferta(record); setShowOfertaDetail(true); } }/>
          <Tooltip title="Aceptar Oferta">
            <Button shape="circle" icon={ <LikeOutlined /> } size="small" disabled={ record.estado != 'PUBLICADA' }
              onClick={ ()=>{ setSelectedOferta(record); setShowAceptarOferta(true); } } />
          </Tooltip>
          <Tooltip title="Rechazar Oferta">
            <Button shape="circle" icon={ <DislikeOutlined /> } size="small" disabled={ record.estado != 'PUBLICADA' }
              onClick={ ()=>{ setSelectedOferta(record); setShowRechazarOferta(true); } } />
          </Tooltip>
        </Space>
      }
    }
  ];
    
  return (
    <Fragment>
      <Card title="Ofertas" size="small">
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ props.working }
          columns={ columns } 
          dataSource={ props.ofertas }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
          expandable={ {
            expandedRowRender: record => 
              <Space direction="vertical" style={{width: '100%'}}>
              { record.viajes_en_curso?.length > 0
                ? <ViajeEnCursoList 
                  viajes={ record.viajes_en_curso }
                  viaje={ props.viaje }
                />
                : '' }
              { record.viajes_finalizados?.length > 0
                ? <ViajeRealizadosList 
                  viajes={ record.viajes_finalizados }
                  viaje={ props.viaje }
                />
                : '' }
              </Space>,
            rowExpandable: record => record.viajes_en_curso?.length > 0 || record.viajes_finalizados?.length > 0
          } }
        />
      </Card>

      <OfertaDetail
        visible ={ showOfertaDetail }
        onClose={ () => setShowOfertaDetail(false) }
        oferta={ selectedOferta }
        viaje={ props.viaje }
      />
      <AceptarOferta
        visible={ showAceptarOferta }
        onClose={ () => setShowAceptarOferta(false) }
        oferta={ selectedOferta }
        viaje={ props.viaje }
        aceptarOferta = { props.aceptarOferta }
        loadViajes={ props.loadViajes }
      />
      <RechazarOferta
        visible={ showRechazarOferta }
        onClose={ () => setShowRechazarOferta(false) }
        oferta={ selectedOferta }
        viaje={ props.viaje }
        rechazarOferta = { props.rechazarOferta }
        loadViajes={ props.loadViajes }
      />
    </Fragment>
  );
}

export default OfertasList;
