import React, { Fragment, useEffect, useState } from 'react';
import { Table, Card, Tag, Button, Input, Col, Space, Tooltip, Modal } from 'antd';
import TransportistasApi from '../../../api/transportistasApi';
import { useHistory } from 'react-router';
import PageTitle from '../../../components/PageTitle';
import Search from '../../../components/Search';
import EditarButton from '../../../components/Buttons/EditarButton';
import EliminarButton from '../../../components/Buttons/EliminarButton';
import AppNotification from '../../../components/AppNotification';
import EnableDisableButton from '../../../components/Buttons/EnableDisableButton';
import VerButton from '../../../components/Buttons/VerButton';
import SearchDinamic from '../../../components/Search/Search-Dinamic';
import SearchOfertas from './SearchOfertas';
import OfertaEstadoTag from '../../../components/Tags/OfertaEstadoTag';
import TransportistaViajesList from './TransportistaViajesList';
import moment from 'moment-timezone';


const Transportista = props => {
  const history = useHistory();
  const [transportistas, setTransportistas] = useState([]);
  const [working, setWorking] = useState(false);
  const [viajes, setViajes] = useState([]);

  useEffect(() => {
    loadTransportistas();

  }, []);

  const columns = [

    { key: 'razon_social',title: 'Razon social',width: '25%',
      render: (text, record) => (
      <Space size="middle">
        <a onClick={()=>showInfo(record)}> <Tag color="cyan"> { record.transportista?.razon_social}</Tag> </a>
      </Space>
    ),
    },
    { key: 'Feca', align: 'center',title: 'Fecha publicacion',width: '20%',
      render: (text, record) => <Tag color="cyan">{ moment(record.created_at).format('DD/MM/YYYY') }</Tag>
    },



    { title: 'Estado de la Oferta', dataIndex: 'estado', key: 'estado', align: 'center',width: '20%',
      render: text => <OfertaEstadoTag estado={ text }/>
    },
    { key: 'viajes_en_curso', align: 'center',title: 'Viajes en curso',width: '20%',
      render: (text, record) => <Tag color="cyan">{ record.viajes_en_curso_filtro?.length }</Tag>
    },
    { key: 'viajes_finalizados', align: 'center',title: 'Viajes finalizados',width: '20%',
            render: (text, record) => <Tag color="cyan">{ record.viajes_finalizados?.length }</Tag>

    }
  ];
  const showInfo =(data)=>{
    Modal.info({
    title: <p>{data.transportista?.razon_social}</p>,
    content: (
      <div>
        <p><Tag color="cyan">TELEFONO :</Tag>  {data.transportista?.telefono}</p>
        <p><Tag color="cyan">CUIT :</Tag>   {data.transportista?.cuit}</p>
        <p><Tag color="cyan">EMAIL :</Tag>   {data.transportista?.email}</p>
      </div>
    ),
    onOk() {},
  });
  }

  const loadTransportistas = () => {
    setWorking(true);
    TransportistasApi.getOfertasRealizadas()
      .then(response => {
        setWorking(false);
        setTransportistas(response);

      })
      .catch(error => setWorking(false));
  }

  return (
    <Fragment>
      <PageTitle title="Transportistas"/>
      <Card
        title={<Col >
          <SearchOfertas
            onChange={ response => setTransportistas(response) }
            resetBusqueda={ () => loadTransportistas()}
            working ={working =>setWorking(working)}
           />
        </Col>}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ transportistas }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
           expandable={ {
            expandedRowRender: record => 
              <TransportistaViajesList
                viajes={ record.viajes_en_curso_filtro }
                viajeFinalizado={record}
                loadViajes={ loadTransportistas }
              />,
            rowExpandable: record => record.viajes_en_curso_filtro?.length > 0
          } }
          //onRow={ record => ({ onClick: () => { setSelectedRow(record.id); }}) }
        />
      </Card>
    </Fragment>
  );
}

export default Transportista;
