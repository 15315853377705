import { useEffect, useState } from "react";
import { Button, Card, Table } from "antd";
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import Parser from "../../../helpers/Parser";
import Patente from "../../../components/Patente";

const CamionesTable = props => {
  const [camiones, setCamiones] = useState([]);
    
  useEffect(() => {
    setCamiones(props.dashboardInfo?.camionesConDocumentacionSinAprobar?.map(camion => {
      return {
        id: camion.id,
        patente: <Patente patente={ camion.patente }/>,
        fecha_ruta_comprobar: Parser.date(camion.fecha_ruta_comprobar),
        fecha_seguro_comprobar: Parser.date(camion.fecha_seguro_comprobar),
        fecha_vtv_comprobar: Parser.date(camion.fecha_vtv_comprobar),
        falta_cedula_verde: camion.falta_cedula_verde == true 
          ? <Button onClick={ () => handleApprove(camion, 'cedula_verde') }><CloseSquareOutlined /> Ver</Button> 
          : <CheckSquareOutlined />,
        falta_ruta: camion.falta_ruta == true 
          ? <Button onClick={ () => handleApprove(camion, 'ruta') }><CloseSquareOutlined /> Ver</Button> 
          : <CheckSquareOutlined />,
        falta_seguro: camion.falta_seguro == true 
          ? <Button onClick={ () => handleApprove(camion, 'seguro') }><CloseSquareOutlined /> Ver</Button> 
          : <CheckSquareOutlined />,
        falta_vtv: camion.falta_vtv == true 
          ? <Button onClick={ () => handleApprove(camion, 'vtv') }><CloseSquareOutlined /> Ver</Button> 
          : <CheckSquareOutlined />
      }
    }));

  }, [props.dashboardInfo]);

  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id', align: 'center' },
    { title: 'Patente', dataIndex: 'patente', key: 'patente', width: 120, align: 'center' },
    { title: 'Fecha ruta a comprobar', dataIndex: 'fecha_ruta_comprobar', key: 'fecha_ruta_comprobar', align: 'center' },
    { title: 'Fecha seguro a comprobar', dataIndex: 'fecha_seguro_comprobar', key: 'fecha_seguro_comprobar', align: 'center' },
    { title: 'Fecha VTV a comprobar', dataIndex: 'fecha_vtv_comprobar', key: 'fecha_vtv_comprobar', align: 'center' },
    { title: 'Falta cedula verde', dataIndex: 'falta_cedula_verde', key: 'falta_cedula_verde', align: 'center' },
    { title: 'Falta ruta', dataIndex: 'falta_ruta', key: 'falta_ruta', align: 'center' },
    { title: 'Falta seguro', dataIndex: 'falta_seguro', key: 'falta_seguro', align: 'center' },
    { title: 'Falta VTV', dataIndex: 'falta_vtv', key: 'falta_vtv', align: 'center' }
  ];

  const handleApprove = (camion, tipo) => {
    let imgUrl;
    // eslint-disable-next-line default-case
    switch(tipo){
      case "cedula_verde": imgUrl = camion.url_imagen_cedula_verde; break;
      case "ruta": imgUrl = camion.url_imagen_ruta; break;
      case "seguro": imgUrl = camion.url_imagen_seguro; break;
      case "vtv": imgUrl = camion.url_imagen_vtv; break;
    }
    props.showPhotoModal(imgUrl, 'camiones', tipo, camion.id, camion);
  }

  return (
    <Card title="Camiones con documentacion sin aprobar">
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ props.working }
        columns={ columns } 
        dataSource={ camiones }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        //onRow={ record => ({ onClick: () => { setSelectedRow(record.id); }}) }
      />
    </Card>
  );
}

export default CamionesTable;