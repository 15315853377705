import React, { Fragment, PureComponent, useEffect, useState } from 'react';
import { Divider, Drawer, Button, Form, Row, Col, Tag, Input, InputNumber, Alert, Descriptions, Card, Spin, Modal, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import OfertasApi from '../../../../../api/ofertasApi';
import AppNotification from '../../../../../components/AppNotification';
import basicStyle from '../../../../../themes/basicStyle';
import UIHelper from '../../../../../helpers/UIHelper';
import Parser from '../../../../../helpers/Parser';
import OfertaEstadoTag from '../../../../../components/Tags/OfertaEstadoTag';
import AuthHelper from '../../../../../helpers/AuthHelper';

const FormItem = Form.Item;
const DescItem = Descriptions.Item;
const { TextArea } = Input;

const RealizarOferta = props => {
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm(); 

  useEffect(() => {
    if(props.visible == true){
      let user = AuthHelper.getDecodedToken();
      let ofertasUsuario = props.viaje?.oferta?.filter(oferta => oferta.transportista_id == user.parent_entity_id);
      if(ofertasUsuario.length > 0){
        showModal(ofertasUsuario);
      }
    }
  }, [props.visible])

  const showModal = ofertas => {
    Modal.confirm({
      width: '40%',
      title:"Publicación ya Ofertada",
      content:
        <div>
          <p>Usted ya ofertó anteriormente en esta publicación</p>
          <ul>
            { ofertas.map(oferta => 
              <li key={ oferta.id }>
                <Space>
                  {`Fecha: ${Parser.datetime(oferta.created_at)} - Monto: ${Parser.currency(oferta.tarifa_viaje_completo)} - Cantidad de camiones: ${oferta.cantidad_camiones}`}
                  <OfertaEstadoTag estado={ oferta.estado }/>
                </Space>
              </li>) }
          </ul>
          <p>¿Desea continuar de todos modos?</p>
        </div>,
      onOk: () => {},
      onCancel: () => props.onClose(),
      okText:"Si, Ofertar de todos modos"
    });
  }

  const getTotal = () => {
    return Boolean(Number(props.viaje?.tarifa_por_tonelada))
        ? <FormItem label="Total por Tn"><Input disabled style={ { fontWeight: 'bold' } } value={ Parser.currency(props.viaje?.tarifa_por_tonelada) } /></FormItem>
        : Boolean(Number(props.viaje?.tarifa_viaje_completo))
          ? <FormItem  label="Tarifa Total"><Input disabled style={ { fontWeight: 'bold' } } value={ Parser.currency(props.viaje?.tarifa_viaje_completo) }/></FormItem>
          : <FormItem label="Costo del viaje"><Input disabled style={ { fontWeight: 'bold' } } value="En espera de oferta"/></FormItem>
  }

  const onFinish = values => {
    setWorking(true);
    let data = { 
      ...values,
      tarifa_viaje_completo:
        Number(props.viaje.tarifa_viaje_completo)
          ? Number(props.viaje.tarifa_viaje_completo)
          : Number(props.viaje.tarifa_por_tonelada),
      publicacion_viaje_id: props.viaje.id
    }
    OfertasApi.save(data)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Oferta guardada correctamente');
        props.loadViajes();
        props.onClose();
      })
      .catch(error => { setWorking(false); });
  };

  return (
    <Fragment>
      <Drawer 
        title="Realizar Oferta"
        placement="right"
        closable={ true }
        visible={ props.visible }
        onClose={ props.onClose }
        width={ '40%' }
      >
        <Spin spinning={ working }>
          <Descriptions column={1} bordered size="small">
            <DescItem label="Código de Viaje"><Tag color="cyan">{ props.viaje?.codigo_uvertrac }</Tag></DescItem>
            <DescItem label="Título del Viaje">{ props.viaje?.titulo }</DescItem>
          </Descriptions>

          <Divider/>

          <Form
            { ...basicStyle.formItemLayout }
            form={ form }
            name="formulario"
            labelAlign="left"
            onFinish={ onFinish }
            initialValues={ {
              cantidad_camiones: 1
            } }
            scrollToFirstError
            className="wrap-label"
          >
            <Row gutter={ [16,0] }>
              <Col span={ 24 }>
                { getTotal() }

                <FormItem name="cantidad_camiones" label="Cantidad de camiones" hasFeedback rules={ UIHelper.defaultRules }>
                  <InputNumber style={ { width: '100%' } } min={1} max={props.viaje?.cantidad_camiones}/>
                </FormItem>
                <FormItem name="condicion_de_pago" label="Condición de pago" hasFeedback rules={ UIHelper.defaultRules }>
                  <Input />
                </FormItem>
                <FormItem name="informacion_adicional_publica" label="Información adicional pública">
                  <TextArea rows={4} />
                </FormItem>
                <FormItem name="informacion_adicional_privada" label="Información adicional privada">
                  <TextArea rows={4} />
                </FormItem>
              </Col>
              
            </Row>

            <Row justify="end">
              <FormItem>
                <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Realizar Oferta</Button>
              </FormItem>
            </Row>
          </Form>

          <Divider/>

          { Boolean(props.viaje?.oferta.length) ? <Alert type="info" message="Esta publicación ya posee ofertas." /> : '' }

        </Spin>
      </Drawer>
    </Fragment>
  );
}

export default RealizarOferta;
