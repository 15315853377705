import React, { Component, useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Tag } from 'antd';
import './DashboardDadoresCargaPage.scss';
import DadoresCargaApi from '../../api/dadoresCargaApi';
import DashboardTotales from './components/DashboardTotales';
import ViajesTable from './components/ViajesTable';
import OfertasTable from './components/OfertasTable';
import ViajesEnCursoMap from './components/ViajesEnCursoMap';

const { Content } = Layout;

const DashboardDadoresCargaPage = props => {
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = () => {
    setWorking(true);
    DadoresCargaApi.getDashboard()
      .then(response => {
        setWorking(false);
        setDashboardInfo(response?.length > 0 ? response[0] : response);
      })
      .catch(error => setWorking(false));
  }

  return (
    <Content style={ { margin: '24px 16px' } } className="dashboard-dadores-carga">
      <Row gutter={ [24,24] }>
        <DashboardTotales
          dashboardInfo={ dashboardInfo }
        />

        <Col span={ 24 }>
          <OfertasTable
            dashboardInfo={ dashboardInfo }
            working={ working }
            loadDashboard={ loadDashboard }
          />
        </Col>
        <Col span={ 24 }>
          <ViajesTable
            dashboardInfo={ dashboardInfo }
            working={ working }
          />
        </Col>
        <Col span={ 24 }>
          <ViajesEnCursoMap
            dashboardInfo={ dashboardInfo }
            loadDashboard={ loadDashboard }
            working={ working }
          />
        </Col>
      </Row>
    </Content>
  );
}

export default DashboardDadoresCargaPage;