import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Card, Col, Row, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const DocumentacionStep = props => {
  const [cedulaFileList, setCedulaFileList] = useState([]);
  const [seguroFileList, setSeguroFileList] = useState([]);
  const [vtvFileList, setVtvFileList] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if(props.index === props.current && props.status === 'wait'){
      /*if (cedulaFileList.length !== 1 || seguroFileList.length !== 1 || vtvFileList.length !== 1) {
          setShowError(true);
          props.onValidate(false, {});
          return;
      }*/

      let data = {};
      if (cedulaFileList.length > 0){ data.cedula_verde = cedulaFileList[0]; }
      if (seguroFileList.length > 0){ data.seguro = seguroFileList[0]; }
      if (vtvFileList.length > 0){ data.vtv = vtvFileList[0]; }

      props.onValidate(true, data); 
    }
  }, [props.current, props.status]);

  return (
    <Row gutter={[16,16]}>
      <Col lg={12} md={24} sm={24} xs={24}>
        <Card title="Cédula Verde" type="inner">
          <Space direction="vertical" style={ { width: '100%' } }>
            <Upload.Dragger
              accept={ '.jpeg,.jpg,.png,.gif,image/jpeg,image/pjpeg,image/png,image/gif,' }
              beforeUpload={ file => { setCedulaFileList([file]); setShowError(false); return false; } }
              onRemove={ file => { setCedulaFileList([]); } }
              fileList={ cedulaFileList }
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Cliquea o arrastra la imagen a esta area para subir</p>
              <p className="ant-upload-hint">Formatos aceptados: .jpeg, .jpg, .png, .gif</p>
            </Upload.Dragger>
          </Space>
        </Card>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Card title="Seguro" type="inner">
          <Space direction="vertical" style={ { width: '100%' } }>
            <Upload.Dragger
              accept={ '.jpeg,.jpg,.png,.gif,image/jpeg,image/pjpeg,image/png,image/gif,' }
              beforeUpload={ file => { setSeguroFileList([file]); setShowError(false); return false; } }
              onRemove={ file => { setSeguroFileList([]); } }
              fileList={ seguroFileList }
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Cliquea o arrastra la imagen a esta area para subir</p>
              <p className="ant-upload-hint">Formatos aceptados: .jpeg, .jpg, .png, .gif</p>
            </Upload.Dragger>
          </Space>
        </Card>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Card title="VTV" type="inner">
          <Space direction="vertical" style={ { width: '100%' } }>
            <Upload.Dragger
              accept={ '.jpeg,.jpg,.png,.gif,image/jpeg,image/pjpeg,image/png,image/gif,' }
              beforeUpload={ file => { setVtvFileList([file]); setShowError(false); return false; } }
              onRemove={ file => { setVtvFileList([]); } }
              fileList={ vtvFileList }
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Cliquea o arrastra la imagen a esta area para subir</p>
              <p className="ant-upload-hint">Formatos aceptados: .jpeg, .jpg, .png, .gif</p>
            </Upload.Dragger>
          </Space>
        </Card>
      </Col>

      { showError
        ? <Col span={24}>
          <Alert type="error"
            message="Debe ingresar al menos un archivo en cada catergoría"
          />
        </Col>
        : '' }
    </Row>
  );
}

export default DocumentacionStep;
