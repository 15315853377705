import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, Table, Button, Space, Col, Select, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DepartamentosApi from '../../../../api/parametricas/departamentosApi';
import AppNotification from '../../../../components/AppNotification';
import EditarButton from '../../../../components/Buttons/EditarButton';
import EliminarButton from '../../../../components/Buttons/EliminarButton';
import PageTitle from '../../../../components/PageTitle';
import ProvinciasApi from '../../../../api/parametricas/provinciasApi';
import UIHelper from '../../../../helpers/UIHelper';
import SearchDinamic from '../../../../components/Search/Search-Dinamic';

const { Option } = Select;

const DepartamentoList = props => {
  const history = useHistory();
  const [provincias, setProvincias] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState(undefined);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadProvincias();
    loadDepartamentos(props.match.params.provId);
    //setSelectedProvincia(props.match.params.provId);
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'nombre', width: '100%', },
    { key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <EditarButton onClick={ () => { history.push('/parametricas/departamentos_de_provincias/edit/' + record.parametrica_provincia_id + '/' + record.id) } }/>
          <EliminarButton popTitle="¿Está seguro de querer eliminar la departamento?" onConfirm={ () => handleDelete(record.id) }/>
        </Space>
      }
    }
  ];

  const loadProvincias = () => ProvinciasApi.get().then(response => setProvincias(response));

  const loadDepartamentos = id => {
    if(id){
      setWorking(true);
      ProvinciasApi.getDepartamentos(id)
        .then(response => {
          setWorking(false);
          setDepartamentos(response.departamentos);
        })
        .catch(error => setWorking(false));
    }
  }

  const handleDelete = id => {
    setWorking(true);
    DepartamentosApi.delete(id)
      .then(response => {
        setWorking(false);
        AppNotification.showSuccess('Departamento eliminado correctamente');
        loadDepartamentos(selectedProvincia);
      })
      .catch(error => setWorking(false));
  };

  return (
    <Fragment>
      <PageTitle title="Departamentos"
        onBack={ () => history.goBack() }
      />
      <Card
        title={ <Col span={12}>
          <Row gutter={[16,16]}>
            <Col span={12}>
              <Select allowClear showSearch filterOption={ UIHelper.defaultSelectFilter } placeholder="Provincia" style={ { width: '100%' } }
                onChange={ value => { setSelectedProvincia(value); loadDepartamentos(value); } }
                //defaultValue={ props.match.params.provId }
                value={ selectedProvincia }
              >
                { provincias.map(prov => (<Option key={ prov.id } value={ prov.id }>{ prov.nombre }</Option>)) }
              </Select>
            </Col>
            <Col span={12}>
              <SearchDinamic
                module="uvertraccore"
                model="departamento"
                columnsSearch={ ["nombre"] }
                columnsReturn={ ["*"] }
                orderBy="nombre"
                onChange={ response => setDepartamentos(response) }
                onClear={ loadDepartamentos }
             />
            </Col>
          </Row>
        </Col> }
        extra={[
          <Button type="primary" icon={<PlusOutlined />} disabled={ !selectedProvincia }
            onClick={ () => history.push('/parametricas/departamentos_de_provincias/new/' + selectedProvincia) }
          >Nuevo</Button>
        ]}
      >
        <Table
          rowKey={ record => record.id }
          size="small"
          loading={ working }
          columns={ columns } 
          dataSource={ departamentos }
          pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        />
      </Card>
    </Fragment>
  );
}

export default DepartamentoList;