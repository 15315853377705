import React, { useState } from 'react';
import { Form, Input } from 'antd';
import UIHelper from '../../helpers/UIHelper';
import UvertracApi from '../../api/uvertracApi';

const FormItem = Form.Item;
const InputGroup = Input.Group;

const CuitFormItem = props => {
  const [status, setStatus] = useState('');

  const validate = (rule, value) => {
    setStatus('validating');

    if (value?.length != 11) {
      setStatus('error');
      return Promise.reject(`El CUIT debe tener 11 digitos.`);
    }

    return UvertracApi.checkCuit(value)
      .then(response => {
        if(response.dadorDeCargaExistente || response.transportistaExistente){
          if(props.onCuitExistente(response)){
            let messageError = !response.dadorDeCargaExistente && response.transportistaExistente
              ? `Ya existe un Transportista con el CUIT ${value}`
              : `Ya existe un Dador de Carga con el CUIT ${value}`;
            if (!props.id){
              return Promise.reject(messageError);
            }
          }
        }

        if (response.afipResponse.errorGetData == true) {
          return Promise.reject(`El CUIT ingresado (${value}) no es valido.`);
        }

        let tipo_iva = 0;
        if (response.afipResponse.Contribuyente.EsRI){
          tipo_iva = 1;
        }
        if (response.afipResponse.Contribuyente.EsMonotributo){
          tipo_iva = 2;
        }
        if (response.afipResponse.Contribuyente.EsExento){
          tipo_iva = 3;
        }
        if (response.afipResponse.Contribuyente.EsConsumidorFinal){
          tipo_iva = 5;
        }

        props.form.setFieldsValue({
          razon_social: `${response.afipResponse.Contribuyente.nombre}`,
          parametrica_tipo_de_iva_id: tipo_iva
        });
        setStatus('success');
        return Promise.resolve();
      })
      .catch(error => {
        setStatus('error');
        return Promise.reject(error);
      });
  }

  return (
    <FormItem { ...props }
      label={ props.label ? props.label : "CUIT" }
      extra={ <small>El CUIT sera validado en AFIP</small> }
      hasFeedback
      validateStatus={ status }
    >
      <InputGroup compact>
        <FormItem name="cuit" noStyle rules={ [ ...UIHelper.defaultRules, { validator: validate }] }
          validateTrigger="onBlur"
        >
          <Input style={ { width: '30%'} }/>
        </FormItem>
        <FormItem name="razon_social" noStyle>
          <Input disabled style={ { width: '70%'} }/>
        </FormItem>
      </InputGroup>
    </FormItem>
  );
}

export default CuitFormItem;