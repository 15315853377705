import React from "react";
import { Tag } from "antd";
import Consts from "../../helpers/Consts";

const OfertaEstadoTag = ({estado}) => {
  if(estado === undefined){ estado = ''; }
  //if(typeof(estado) === 'object'){ estado = estado.Descripcion; }
  
  let color = '';
  switch(estado){
    case Consts.EstadoOferta.PUBLICADA: color = '#2db7f5'; break;
    case Consts.EstadoOferta.ACEPTADA: color = 'green'; break;
    case Consts.EstadoOferta.RECHAZADA: color = '#f50'; break;
    default: estado = 'Parsing Error'; color = '#ddd';
  };
  
  return <Tag color={ color }>{ estado }</Tag>;
};

export default OfertaEstadoTag;