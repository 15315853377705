import React from "react";
import { Tag } from "antd";
import Consts from "../../helpers/Consts";

const ViajeEstadoTag = ({estado}) => {
  if(estado === undefined){ estado = ''; }
  //if(typeof(estado) === 'object'){ estado = estado.Descripcion; }
  
  let color = '';
  switch(estado){
    case Consts.EstadoViaje.PUBLICADA: color = '#2db7f5'; break;
    case Consts.EstadoViaje.ACEPTADA: color = 'green'; break;
    case Consts.EstadoViaje.RECHAZADA: color = '#f50'; break;
    case Consts.EstadoViaje.INICIADO: color = 'green'; break;
    case Consts.EstadoViaje.VIAJANDO: color = '#2db7f5'; break;
    case Consts.EstadoViaje.FINALIZADO: color = 'green'; break;
    case Consts.EstadoViaje.TERMINADO: color = 'green'; break;
    case Consts.EstadoViaje.FACTURADO: color = 'purple'; break;
    default: estado = 'Parsing Error'; color = '#ddd';
  };
  
  return <Tag color={ color }>{ estado }</Tag>;
};

export default ViajeEstadoTag;