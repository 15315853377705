import React, { useEffect, useState } from "react";
import { Button, Card, Col, Space, Table, Tooltip } from "antd";
import { FileDoneOutlined } from '@ant-design/icons';
import ViajeEstadoTag from "../../../../../components/Tags/ViajeEstadoTag";
import Parser from "../../../../../helpers/Parser";
import ViajesList from "./ViajesList";

const ViajesPorTransportista = props => {
  const [transportistas, setTransportistas] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if(props.viajes){
      let viajesFinalizados = []
      props.viajes.map(pub => pub.oferta.map(of => of.viajes_finalizados.map(viaje => viajesFinalizados.push(viaje))));

      let viajesPorTransportista = viajesFinalizados.reduce((acc, obj) => {
        const parent = acc.find(x => x.transportista.id == obj.unidad_transporte.transportista.id); 
        if(parent){
          parent.viajes.push(obj);
        }
        else{
          acc.push({transportista: obj.unidad_transporte.transportista, viajes: [{...obj}]})
        }
        return acc;
      }, []);

      setTransportistas(viajesPorTransportista);
    }
  }, [props.viajes]);

  const columns = [
    { title: 'Codigo', dataIndex: ['transportista', 'codigo_uvertrac'], key: 'codigo_uvertrac', align: 'center' },
    { title: 'Transportista', dataIndex: ['transportista', 'razon_social'], key: 'transportista', align: 'center' },
    { title: 'Email', dataIndex: ['transportista', 'email'], key: 'email', align: 'center' },
    { title: 'Teléfono', dataIndex: ['transportista', 'telefono'], key: 'telefono', align: 'center' },
  ];

  return (
    <Card
      /*title={<Col span={12}>
        <Search 
          module="uvertraccore"
          model="publicacionviaje"
          columnsSearch={ ["codigo_uvertrac", "titulo"] }
          columnsReturn={ ["*"] }
          orderBy="id"
          onchange={ response => props.setViajes(response) }
          onClear={ props.loadViajes }
        />
      </Col>}*/
    >
      <Table
        rowKey={ record => record.id }
        size="small"
        loading={ working }
        columns={ columns } 
        dataSource={ transportistas }
        pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
        expandable={ {
          expandedRowRender: record => 
            <ViajesList 
              viajes={ record.viajes }
              facturarViaje={ viajeId => props.facturarViaje(viajeId) }
              loadViajes={ props.loadViajes }
            />,
          //rowExpandable: record => record.oferta?.length > 0
        } }
      />
    </Card>
  );
}

export default ViajesPorTransportista;