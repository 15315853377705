import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import LoginForm from './LoginForm';
import RecoverPassword from './RecoverPasswordForm';
import './LoginComponent.scss';

const LoginComponent = props => {
  const [recoveryMode, setRecoveryMode] = useState(false);

  const renderComponents = () => {
    return !recoveryMode
      ? <div>
        <LoginForm
          working={ props.working }
          handleLogin={ props.handleLogin }
        />
        <div style={ { textAlign: "right" } }>
          <Button onClick={ () => setRecoveryMode(true) }>
            RECUPERAR CONTRASEÑA
          </Button>
        </div>
      </div>
      : <div>
          <RecoverPassword
            working={ props.working }
            handleRecoverPassword={ props.handleRecoverPassword }
            setRecoveryMode={ setRecoveryMode }
          />
          <Button onClick={ () => setRecoveryMode(false) }>
            Regresar a la pantalla de inicio
          </Button>
        </div>;
  };

  return (
    <div className="login">
      <div className="logoWrapper">
        { props.logo 
          ? <span><img alt="logo" src={ props.logo } className="logoImg" /></span> 
          : '' }
        <span>{ props.title ? props.title : 'Bienvenido!' }</span>
      </div>
      { renderComponents() }
    </div>
  );
}

/*LoginComponent.defaultProps = {
  recoverButtonText: 'Recuperar contraseña',
  recoverInputPlaceholder: 'Usuario',
  recoverInputErrorMessage: 'Nombre de usuario inválido',
  recoverSuccessfulMessage: '¡Operación completada con éxito! Un correo con las instrucciones a seguir se ha enviado a su cuenta de correo.',
};*/

export default LoginComponent;
