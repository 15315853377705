export default class UserHelper {
  static storeUserPermissions = permissions => {
    let permissionsObject = {};
    for(let category in permissions){
      for(let permission in permissions[category]){
        permissionsObject[`${category}.${permission}`] = permissions[category][permission].value;
      }
    }
    sessionStorage.setItem('userPermissions', JSON.stringify( permissionsObject ));
  }
  
  static getUserPermissions = () => {
    return JSON.parse(sessionStorage.getItem('userPermissions'));
  }

  static removeUserPermissions = () => {
    sessionStorage.removeItem('userPermissions');
    return null;
  }
  
  static userHasPermission = permissionToBeChecked => {
    let permissions = this.getUserPermissions();
    let permissionValue = false;
    let permissionFound = false;
    if(permissions == undefined || Object.keys(permissions).length == 0){
      console.error('El Usuario no tiene ningun permiso asignado.');
      return;
    }
    for(let permission in permissions){
      if(permission.toLowerCase() === permissionToBeChecked.toLowerCase()){
        permissionValue = permissions[permission];
        permissionFound = true;
      }
    }
    if(permissionFound == false){
      console.error(`El permiso ${permissionToBeChecked} no aparece en la lista de permisos. Por favor chequearlo de nuevo.`);
    }
    return permissionValue;
  }

  static userHasSomePermission = (permissionList = []) => {
    let permissionValue = false;
    if(permissionList.length == 0){
      console.error('[userHasSomePermission]: la lista de permisos a checkear esta vacia. ');
      permissionValue = false;
    }
    if(permissionList.length == 1){
      console.log('[userHasSomePermission]: se recomienda usar userHasPermission para un solo permiso. ');
    }
    for(let permission of permissionList){
      if(this.userHasPermission(permission) == true){
        permissionValue = true;
      }
    }
    return permissionValue;
  }
  
  static userHasAllPermissions = (permissionList = []) => {
    let permissionValue = true;
    if(permissionList.length == 0){
      console.error('[userHasAllPermission]: la lista de permisos a checkear esta vacia. ');
      permissionValue = false;
    }
    if(permissionList.length == 1){
      console.log('[userHasAllPermission]: se recomienda usar userHasPermission para un solo permiso. ');
    }
    for(let permission of permissionList){
      if(this.userHasPermission(permission) == false){
        permissionValue = false;
      }
    }
    return permissionValue;
  }
}