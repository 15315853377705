import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import ViajePublicadoList from './components/list/ViajePublicadoList';

const { Content } = Layout;

const ViajesPublicadosDadoresCargaPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ ViajePublicadoList } />
      </Switch>
    </Content>
  );
}

export default ViajesPublicadosDadoresCargaPage;