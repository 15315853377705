import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import UIHelper from '../../../helpers/UIHelper';

const FormItem = Form.Item;

const RecoverPasswordForm = props => {
  const [form] = Form.useForm();

  const handleRecover = () => {
    form.validateFields()
      .then(values => {
        props.handleRecoverPassword(values.userName);
        props.setRecoveryMode(false);
      });
  }

  /*const recoverErrorMessageVisibility = this.props.recoverErrorMessage
    && !this.state.isLoading
    && this.state.isFormSent ? 'block' : 'none';*/

  return(
    !props.working && props.recoverSuccess 
      ? <div>
        <div style={ { textAlign: 'center' } }>
          <CheckCircleOutlined theme="twoTone" twoToneColor="#52c41a" style={ { fontSize: '24px' } } />
        </div>
        <p className="recoverSuccessMessage">
          { props.recoverSuccessfulMessage }
        </p>
      </div>
      : <Form 
        name="formulario"
        onFinish={ handleRecover }
        form={ form }
      >
        <FormItem name="userName" rules={ UIHelper.defaultRules }>
          <Input placeholder={ 'Usuario' }
            prefix={ <UserOutlined style ={ { fontSize: 13 } } /> }
          />
        </FormItem>
        <FormItem>
          <Button
            type = "primary"
            htmlType = "submit"
            loading = { props.working }
          >
            { 'Recuperar contraseña' }
          </Button>
        </FormItem>
        { props.recoverErrorMessage
          ? <p className="recoverErrorMessage">
            { props.recoverErrorMessage }
          </p>
          : '' }
      </Form>
  );
}

export default RecoverPasswordForm;
