import React from 'react';
import { Timeline, Tooltip } from 'antd';
import { DollarOutlined, FrownOutlined, SettingOutlined, FileExclamationOutlined, FireOutlined, MessageOutlined } from '@ant-design/icons';
import Parser from '../../helpers/Parser';
import Consts from '../../helpers/Consts';

const TimeItem = Timeline.Item;

const getIcon = evento => {
  // eslint-disable-next-line default-case
  switch(evento){
    case Consts.TipoEventos.EXTRACCION_DINERO: return <DollarOutlined style={ { fontSize: '16px', color: 'green' } }/>;
    case Consts.TipoEventos.PINCHO_RUEDA: return <FrownOutlined style={ { fontSize: '16px', color: 'orange' } }/>;
    case Consts.TipoEventos.PROBLEMA_TECNICO: return <SettingOutlined style={ { fontSize: '16px', color: 'orange' } }/>;
    case Consts.TipoEventos.PROBLEMA_PAPELES: return <FileExclamationOutlined style={ { fontSize: '16px', color: 'orange' } }/>;
    case Consts.TipoEventos.ACCIDENTE: return <FireOutlined style={ { fontSize: '16px', color: 'red' } }/>;
    case Consts.TipoEventos.OTRO: return <MessageOutlined style={ { fontSize: '16px', color: 'blue' } }/>;
  }
};

const getTooltip = evento => {
  // eslint-disable-next-line default-case
  switch(evento){
    case Consts.TipoEventos.EXTRACCION_DINERO: return 'Extranccion de dinero';
    case Consts.TipoEventos.PINCHO_RUEDA: return 'Pincho rueda';
    case Consts.TipoEventos.PROBLEMA_TECNICO: return 'Problema tecnico';
    case Consts.TipoEventos.PROBLEMA_PAPELES: return 'Problema de papeles';
    case Consts.TipoEventos.ACCIDENTE: return 'Accidente!';
    case Consts.TipoEventos.OTRO: return 'Otro';
  }
};

const EventosTimeline = props => {
  return (
    <Timeline>
      { props.eventos?.map(evento =>
        <TimeItem
          key={ evento.id }
          dot={ 
            <Tooltip title={ getTooltip(evento.evento) }>
              { getIcon(evento.evento) }
            </Tooltip> }
        >
          <p style={ { margin: 0 } }><b>{ evento.mensaje }</b></p>
          <p style={ { margin: 0, fontSize: 10 } }>{ Parser.datetime(evento.fecha_hora_enviado) }</p>
          <p style={ { margin: 0, fontSize: 10 } }>{ evento.usuario ? evento.usuario.email : '' }</p>
        </TimeItem>
      ) }
    </Timeline>
  );
}

export default EventosTimeline;
