import Fetcher from "../helpers/Fetcher";

const OfertasApi = {
  get: id => {
    let url = 'uvertraccore/ofertas/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  asignarUnidadesTransporte: (id, utIds) => {
    let url = 'uvertraccore/ofertas/asignar-unidades-transporte/' + id;
    return Fetcher
      .post(url, { ids_unidades_transportes: utIds})
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  save: data => {
    let url = 'uvertraccore/ofertas/';
    return Fetcher
      .post(url, data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  aceptar: ofertaId => {
    let url = 'uvertraccore/ofertas/aceptar/' + ofertaId;
    return Fetcher
      .put(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  rechazar: ofertaId => {
    let url = 'uvertraccore/ofertas/rechazar/' + ofertaId;
    return Fetcher
      .put(url)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },
  searchOfertas: (fecha_desde,fecha_hasta,estadoOferta,transportistaRazonSocial,estadoViaje )=> {
    let url = 'uvertraccore/ofertas/search';
    let data ={
      fecha_desde:fecha_desde, 
      fecha_hasta:fecha_hasta,
      estado:estadoOferta,
      transportista_razon_social_id:transportistaRazonSocial,
      viajes_en_curso:estadoViaje
    }
    return Fetcher
      .getWithParams(url,data)
      .then(response => { return response.data; })
      .catch(error => { throw error; });
  },


}

export default OfertasApi;