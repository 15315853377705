import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import ViajeRealizadoList from './components/list/ViajeRealizadoList';

const { Content } = Layout;

const ViajesRealizadosTransportistasPage = props => {
  return (
    <Content style={ { margin: '24px 16px' } }>
      <Switch>
        <Route exact path={ `${props.match.path}/` } component={ ViajeRealizadoList } />
      </Switch>
    </Content>
  );
}

export default ViajesRealizadosTransportistasPage;